import { SVGProps, Ref, forwardRef } from "react";

const BrandsCadillacIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="a"
    x={0}
    y={0}
    aria-hidden="true"
    viewBox="0 0 100 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Cadillac Icon"
    {...props}
  >
    <style>{"\r\n    .st0{fill:#78819b}\r\n  "}</style>
    <path
      d="M8.1 15.3h83.8c-.1.1-.1.2-.1.2-1.8 2.5-3.6 5-5.4 7.6-.1.1-.1.2-.2.4-.8 4.3-1.6 8.5-2.4 12.8-.1.6-.3 1.1-.4 1.7-.4 1.2-1.3 1.9-2.3 2.4-1.1.5-2.2.8-3.4 1-3.6.8-7.2 1.6-10.9 2.3-2.7.6-5.4 1.1-8.1 1.6l-8.4 1.5c-.5.1-.9 0-1.4-.1-3.9-.7-7.7-1.4-11.6-2.1l-14.4-3c-1.2-.3-2.5-.5-3.6-1-.3-.1-.6-.3-.9-.4-1.1-.6-1.7-1.5-2-2.7l-.9-4.5c-.6-3-1.1-6-1.7-9.1-.1-.4-.2-.7-.4-1-1.7-2.4-3.5-4.9-5.2-7.3 0-.2-.1-.3-.1-.3zm4.5 1.4c.1.1.1.2.1.2 1.4 2 2.8 3.9 4.2 5.9.1.1.2.3.2.5.2 1.1.4 2.2.5 3.2l1.2 6.9c.2 1.3.5 2.5.7 3.8.2 1.1.9 1.8 2 2.2.3.1.7.2 1 .3 1.9.4 3.9.9 5.8 1.3 3.1.7 6.3 1.3 9.4 1.9 3.8.7 7.5 1.4 11.3 2.1.4.1.8.2 1.2.1 1.1-.2 2.3-.3 3.4-.6 3.1-.6 6.3-1.1 9.4-1.8 4.3-.9 8.7-1.8 13-2.7.9-.2 1.7-.4 2.6-.7 1-.3 1.7-1 1.9-2.1.1-.2.1-.4.2-.6.3-1.6.6-3.1.8-4.7.5-2.8.9-5.5 1.4-8.3.1-.5.3-1 .6-1.5 1.3-1.8 2.6-3.6 3.8-5.5 0-.1.1-.1.1-.2.1.3-74.8.3-74.8.3z"
      className="st0"
    />
    <path
      d="M80.8 29.5H63v-4.8h18.6l-.8 4.8zm-61.4.9h17.8v4.8H20.5c-.2 0-.3 0-.3-.3-.2-1.5-.5-3-.8-4.4v-.1zM37.9 36h11.6v8.1c-.4-.1-.7-.1-1.1-.2-3.4-.6-6.9-1.3-10.3-1.9-.1 0-.2-.1-.2-.2V36zM18.5 25.5l-.4-2.4h31.4v2.4h-31zm32 11.3v-2.4H80l-.3 1.6c0 .2-.1.4-.1.6 0 .1-.1.1-.2.1H50.8l-.3.1zm11.7-17.7v4.8H50.5v-4.8h11.7zM50.5 37.5h28.8c-.1.4-.4.6-.7.8-.4.2-.8.4-1.2.5-2.6.6-5.2 1.2-7.8 1.7-4.1.8-8.3 1.7-12.4 2.4-2.1.4-4.3.8-6.4 1.1h-.3v-6.5zm.8 5.6h.1c1.6-.3 3.2-.6 4.7-.8 2-.4 4-.8 6.1-1.2 2.3-.5 4.6-.9 6.8-1.4.7-.2 1.5-.4 2.1-.8.2-.1.4-.3.6-.5v-.1H51.3v4.8zm-1.8-24v3.2h-.8v-2.4H23.8l1.4 2.4h-7.1c-.1 0-.2 0-.3-.1l-2.1-3s0-.1-.1-.1h33.9zM26.1 27l.3 2.5h-7.2l-.6-3.3h30.9v3.3h-.8V27H26.1zm25.2 4.2v2.4h-.8v-3.2h30.2l-.5 3.3h-7l.3-2.4-22.2-.1zM63 22.3v-1.7h20.3c0 .1-.1.1-.1.2-.3.5-.7.9-1 1.4-.1.1-.2.2-.3.2H63.3c-.1-.1-.2-.1-.3-.1zM20.7 37.5h16.4v1.7H24.9c-.4 0-.9-.1-1.3-.3-.6-.2-1.2-.3-1.8-.5-.3-.1-.6-.3-.8-.6-.2-.1-.2-.2-.3-.3zm28.8-3.9H37.9v-1.7h11.6v1.7zm12.7-7.3V28H50.5v-1.7h11.7z"
      className="st0"
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsCadillacIcon);
export default ForwardRef;
