import { SVGProps, Ref, forwardRef } from "react";

const PuzzlePieceIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Puzzle Piece Icon"
    {...props}
  >
    <path d="M20.5 10.7h-1.4V6.8c0-.5-.2-1-.6-1.4-.4-.4-.8-.6-1.4-.6h-3.8V3.5c0-.6-.3-1.2-.7-1.7-.4-.4-1.1-.7-1.7-.7-.6 0-1.2.3-1.7.7-.4.4-.7 1.1-.7 1.7v1.4H4.7c-.5 0-1 .2-1.4.6-.3.3-.5.8-.5 1.3v3.6h1.4c1.4 0 2.6 1.1 2.6 2.6s-1.1 2.6-2.6 2.6H2.8v3.6c0 .5.2 1 .6 1.4.4.4.8.6 1.4.6h3.6v-1.4c0-1.4 1.1-2.6 2.6-2.6s2.6 1.1 2.6 2.6v1.4h3.6c.5 0 1-.2 1.4-.6.4-.4.6-.8.6-1.4v-3.8h1.4c.6 0 1.2-.3 1.7-.7.4-.4.7-1.1.7-1.7 0-.6-.3-1.2-.7-1.7-.5-.4-1.2-.6-1.8-.6z" />
  </svg>
);

const ForwardRef = forwardRef(PuzzlePieceIcon);
export default ForwardRef;
