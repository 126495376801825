import { SVGProps } from "react";

const AuthenticationIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      // style="enable-background:new 0 0 24 24"
      xmlSpace="preserve"
      aria-label="Authentication Icon"
      {...props}
    >
      <path className="st2" style={{ fill: "none" }} d="M0 0h24v24H0z" />
      <path
        className="stshockcolor"
        d="M11 22c-3.316 0-6-2.684-6-6V9a7 7 0 1 1 14 0v5c0 2.211-1.789 4-4 4h-4a2 2 0 0 1-2-2V9a3 3 0 1 1 6 0v5h-2V9a1 1 0 1 0-2 0v7h4a2 2 0 0 0 2-2V9A5 5 0 1 0 7 9v7c0 2.211 1.789 4 4 4h7a2 2 0 0 1-2 2h-5z"
        style={{ fill: "#29b6f6" }}
        data-st="fill:#29B6F6;;"
      />
      <path
        className="st0"
        style={{ opacity: 0.4, fill: "#ec407a" }}
        d="M2 6h20v12H2z"
      />
    </svg>
  );
};

export default AuthenticationIcon;
