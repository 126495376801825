import { SVGProps, Ref, forwardRef } from "react";

const PersonHeadsetIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 34 34"
    fill="currentColor"
    width="1em"
    height="1em"
    aria-label="Person Headset Icon"
    ref={ref}
    {...props}
  >
    <path d="M26.5 20.9c.5-1.2.8-2.5.8-3.9 0-1-.2-2-.4-2.9-.9.2-1.9.3-2.9.3-2.1 0-4.1-.5-5.9-1.4-1.8-.9-3.4-2.3-4.6-4-1.3 3.1-3.7 5.5-6.7 6.9-.1.4-.1.7-.1 1.1 0 1.4.3 2.7.8 3.9.5 1.2 1.3 2.4 2.2 3.3 1.9 1.9 4.6 3 7.3 3 1.5 0 2.9-.3 4.2-.9.8 1.5 1.2 2.3 1.1 2.3-2.3.8-4.1 1.2-5.4 1.2-3.4 0-6.7-1.3-9.1-3.8-1.5-1.5-2.6-3.3-3.2-5.2H2.8v-6.4h1.5c.5-2.3 1.5-4.4 3.1-6.1 1.6-1.7 3.6-3 5.8-3.6 2.3-.7 4.7-.7 6.9-.2 2.3.6 4.3 1.7 6 3.4 1.8 1.8 3 4 3.5 6.5h1.6v6.4h-.1l-5 4.6-7.5-.9v-2.4h6.8l1.1-1.2zm-13.4-4.2c.4 0 .8.2 1.1.5.3.3.5.7.5 1.1 0 .4-.2.8-.5 1.1-.3.3-.7.5-1.1.5-.9 0-1.6-.7-1.6-1.6s.7-1.6 1.6-1.6zm7.8 0c.9 0 1.6.7 1.6 1.6s-.7 1.6-1.6 1.6-1.6-.7-1.6-1.6c0-.4.2-.8.5-1.1.2-.4.6-.5 1.1-.5z" />
  </svg>
);

const ForwardRef = forwardRef(PersonHeadsetIcon);
export default ForwardRef;
