import Link from "next/link";
import Button from "@common/components/Button";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

const PreviewModeBanner = () => {
  const { t } = useTranslation(["common"]);
  const { asPath } = useRouter();

  return (
    <div className="flex justify-center items-center gap-x-6 text-text-dark-100 bg-primary-deep-300 p-2">
      <p className="text-center">{t("common:preview_mode_message")}</p>
      <div className="text-primary-deep-300">
        <Link
          href={`/api/preview/disable/?redirectLink=${asPath}`}
          legacyBehavior
        >
          <Button fill="filled" size="small" buttonStyle="white">
            {t("common:exit_preview")}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default PreviewModeBanner;
