import { SVGProps, Ref, forwardRef } from "react";

const BrandsAcuraIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 100 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Acura Icon"
    {...props}
  >
    <path
      d="M50 54.1c-16.9 0-24.6-9.8-24.6-23.1 0-15.4 8.8-23.1 24.6-23.1 16.6 0 24.6 8.2 24.6 23.1S65.5 54.1 50 54.1zm-11.8-5.2L40.9 30l6.8-19.6c-10 .7-18.4 5.7-18.4 20.7 0 8.6 3.2 14.6 8.9 17.8zm11.1-38.7v15.9h1.6V10.2h-1.6zm3.2.1 6.8 19.6 2.7 19c5.6-3.2 8.8-9.1 8.8-18 .1-15.4-8.6-20-18.3-20.6zm8.2 39.3-9.3-20h-2.7l-9.3 20c3 1.4 6.6 2.1 10.6 2.1s7.8-.8 10.7-2.1z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        fill: "#78819b",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsAcuraIcon);
export default ForwardRef;
