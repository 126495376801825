import { SVGProps, Ref, forwardRef } from "react";

const AppDribbbleIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="App Dribbble Icon"
    {...props}
  >
    <path d="M12 4c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8Zm2.2 7.4s-.2-.6-.3-.8c1.7-.8 2.8-1.7 3.2-2.2.7.9 1.1 2 1.2 3.2-.8-.2-2.6-.5-4.1-.2Zm-1.1-2.3c-.8-1.4-1.7-2.6-2.1-3.3 1.9-.5 3.6.1 4.9 1.3-.4.5-1.2 1.3-2.8 2Zm-4-2.6c.4.5 1.2 1.7 2 3.2-2.2.5-4.2.6-5.1.6.4-1.6 1.6-3 3.1-3.8Zm2.9 4.8c.1.2.2.4.3.5-2.8.9-4.6 3.2-5.2 4.1-.8-1.1-1.3-2.4-1.3-3.7.9-.1 3.5-.2 6.2-.9Zm1 2.2c.7 2 1.1 3.6 1.2 4.4-2.1.7-4.5.3-5.8-.8.4-.7 1.7-2.5 4.6-3.6Zm2.9 3.4c-.2-.8-.5-2.2-1.1-3.9 1.1-.2 2.7 0 3.3.2-.3 1.5-1.1 2.8-2.2 3.7Z" />
  </svg>
);

const ForwardRef = forwardRef(AppDribbbleIcon);
export default ForwardRef;
