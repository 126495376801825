import { SVGProps, Ref, forwardRef } from "react";

const NoDamageIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="No Damage Icon"
    {...props}
  >
    <path
      d="M18.7,5.3C17,3.6,14.6,2.5,12,2.5c-2.6,0-5,1.1-6.7,2.8C3.6,7,2.5,9.4,2.5,12c0,2.6,1.1,5,2.8,6.7c1.7,1.7,4.1,2.8,6.7,2.8
	c2.6,0,5-1.1,6.7-2.8c1.7-1.7,2.8-4.1,2.8-6.7C21.5,9.4,20.4,7,18.7,5.3z M6.7,17.3c-1.3-1.4-2.2-3.2-2.2-5.3c0-1.7,0.6-3.2,1.5-4.5
	l2.8,2.8l0.2,0.5L5.7,12l3.4,1.2l-1.5,3.3l3.3-1.5l1.2,3.4l1.2-3.4l0.5,0.2l2.8,2.8c-1.2,0.9-2.8,1.5-4.5,1.5
	C9.9,19.4,8.1,18.6,6.7,17.3z M17.9,16.5l-2.8-2.8l-0.2-0.5l3.4-1.2l-3.4-1.2l1.5-3.3l-3.3,1.5L12,5.7l-1.2,3.4l-0.5-0.2L7.5,6.1
	c1.2-0.9,2.8-1.5,4.5-1.5c2.1,0,3.9,0.8,5.3,2.2c1.3,1.4,2.2,3.2,2.2,5.3C19.4,13.7,18.9,15.2,17.9,16.5z"
    />
  </svg>
);
const ForwardRef = forwardRef(NoDamageIcon);
export default ForwardRef;
