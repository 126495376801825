import { SVGProps, Ref, forwardRef } from "react";

const HeatedSeatsIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Heated Seats Icon"
    {...props}
  >
    <path d="m6.1 7.6 1.2.6.5-4.9-4.5 2.9 1.3.7c-1.6 2.4-1.5 4.8.1 7.2 1.3 2 1.3 3.9 0 5.8l1.4.7c1.6-2.4 1.6-4.9 0-7.4-1.3-1.8-1.3-3.7 0-5.6zm6.4 0 1.2.6.5-4.9-4.4 2.9 1.3.7c-1.6 2.4-1.5 4.8.1 7.2 1.3 2 1.3 3.9 0 5.8l1.4.7c1.6-2.4 1.6-4.9 0-7.4-1.3-1.8-1.3-3.7-.1-5.6zm6.5 0 1.2.6.5-4.9-4.5 2.8 1.3.7c-1.6 2.4-1.5 4.8.1 7.2 1.3 2 1.3 3.9 0 5.8l1.4.7c1.6-2.4 1.6-4.9 0-7.4-1.2-1.7-1.2-3.6 0-5.5z" />
  </svg>
);

const ForwardRef = forwardRef(HeatedSeatsIcon);
export default ForwardRef;
