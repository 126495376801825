import { SVGProps, Ref, forwardRef } from "react";

const MapExpandIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Map Expand Icon"
    {...props}
  >
    <path d="m15 3 2.3 2.3-2.89 2.87 1.42 1.42L18.7 6.7 21 9V3h-6ZM3 9l2.3-2.3 2.87 2.89 1.42-1.42L6.7 5.3 9 3H3v6Zm6 12-2.3-2.3 2.89-2.87-1.42-1.42L5.3 17.3 3 15v6h6Zm12-6-2.3 2.3-2.87-2.89-1.42 1.42 2.89 2.87L15 21h6v-6Z" />
  </svg>
);

const ForwardRef = forwardRef(MapExpandIcon);
export default ForwardRef;
