import { SVGProps, Ref, forwardRef } from "react";

const AppTikTokIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="App Facebook Icon"
    {...props}
  >
    <path
      d="M17.1,5.1c-0.8-0.9-1.2-2-1.2-3.1h-3.4v13.8c0,0.7-0.3,1.5-0.9,2c-0.5,0.5-1.3,0.8-2,0.8c-1.6,0-2.9-1.3-2.9-2.9
	    c0-1.9,1.8-3.4,3.8-2.8V9.4c-3.8-0.5-7.2,2.5-7.2,6.3c0,3.7,3.1,6.3,6.3,6.3c3.5,0,6.3-2.8,6.3-6.3v-7c1.4,1,3.1,1.5,4.8,1.5V6.8
	    C20.7,6.8,18.6,6.9,17.1,5.1z"
    />
  </svg>
);

const ForwardRef = forwardRef(AppTikTokIcon);
export default ForwardRef;
