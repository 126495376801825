import { SVGProps, Ref, forwardRef } from "react";

const LaneDepartureIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Lane Departure Icon"
    {...props}
  >
    <circle cx={7} cy={15.8} r={4.7} />
    <path d="M17 3.3c-.3 0-.5 0-.8.1V2.3h-2.4v2.3c-.9.9-1.5 2.1-1.5 3.4s.6 2.5 1.5 3.4v10.3h2.4v-9.1c.3 0 .5.1.8.1 2.6 0 4.7-2.1 4.7-4.7S19.6 3.3 17 3.3z" />
  </svg>
);

const ForwardRef = forwardRef(LaneDepartureIcon);
export default ForwardRef;
