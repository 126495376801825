import { SVGProps, Ref, forwardRef } from "react";

const AppLinkedinIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="App Linkedin Icon"
    {...props}
  >
    <path d="M19.3 4H4.7c-.4 0-.7.3-.7.7v14.7c0 .3.3.6.7.6h14.7c.4 0 .7-.3.7-.7V4.7c-.1-.4-.4-.7-.8-.7ZM8.7 17.6H6.4V10h2.4v7.6h-.1ZM7.6 9c-.8 0-1.4-.7-1.4-1.4 0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4-.1.7-.7 1.4-1.4 1.4Zm10 8.6h-2.4v-3.7c0-.9 0-2-1.2-2s-1.4 1-1.4 2v3.8h-2.4V10h2.3v1c.3-.6 1.1-1.2 2.2-1.2 2.4 0 2.8 1.6 2.8 3.6v4.2h.1Z" />
  </svg>
);

const ForwardRef = forwardRef(AppLinkedinIcon);
export default ForwardRef;
