import { SVGProps, Ref, forwardRef } from "react";

const BrandsKiaIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 100 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Kia Icon"
    {...props}
  >
    <path
      d="M56.2 38c0 .1 0 .2.1.2s.1 0 .2-.1l24-15.6c.4-.3.8-.4 1.4-.4h5.3c.8 0 1.4.5 1.4 1.4v10.1c0 1.2-.3 1.9-1.4 2.6L80.7 40h-.2c-.1 0-.1-.1-.1-.3V28c0-.1 0-.2-.1-.2s-.1 0-.2.1L62.5 39.2c-.5.3-.9.4-1.3.4H49.6c-.8 0-1.4-.5-1.4-1.4V23.8c0-.1-.1-.2-.1-.2-.1 0-.1 0-.2.1l-11.6 6.9c-.1.1-.1.1-.1.2s0 .1.1.2l8.3 8.3c.1.1.2.2.2.3 0 .1-.1.1-.3.1H37c-.6 0-1-.1-1.4-.4l-5-5-.1-.1s-.1 0-.2.1l-8.4 5c-.5.3-.9.4-1.4.4h-7.7c-.8 0-1.4-.5-1.4-1.4v-9.9c0-1.2.3-1.9 1.4-2.6l6.4-3.9c.1 0 .1-.1.2-.1s.1.1.1.3v13.2c0 .1 0 .2.1.2s.1 0 .2-.1l21.7-13.1c.5-.3.8-.4 1.4-.4h11.7c.8 0 1.4.5 1.4 1.4l.2 14.7z"
      style={{
        fill: "#78819b",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsKiaIcon);
export default ForwardRef;
