import { SVGProps, Ref, forwardRef } from "react";

const BrandsMitsubishiIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 100 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Mitsubishi Icon"
    {...props}
  >
    <path
      d="m50 7.4 9.4 15.9L50 39.1l-9.4-15.8L50 7.4zm0 31.7h18.3l9.1 15.5H59.1L50 39.1zm0 0H31.7l-9.1 15.5h18.3L50 39.1z"
      style={{
        fill: "#78819b",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsMitsubishiIcon);
export default ForwardRef;
