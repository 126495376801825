import { SVGProps, Ref, forwardRef } from "react";

const CarIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 32 32"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Car Icon"
    {...props}
  >
    <path
      d="M0 0h32v32H0z"
      style={{
        fill: "none",
      }}
    />
    <path d="M28 19.6v7c0 .7-.6 1.3-1.3 1.3h-1.3c-.7 0-1.3-.6-1.3-1.3v-1.3H8v1.3c0 .8-.6 1.4-1.3 1.4H5.3c-.7 0-1.3-.6-1.3-1.3v-7c0-.1 24-.1 24-.1z" />
    <path d="M28 16v3.6H4V16l2.8-8c.3-.8 1-1.3 1.9-1.3h14.7c.9 0 1.6.6 1.9 1.3l2.7 8z" />
    <path
      d="M8.7 17.3c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm14.6 0c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="m6.7 14.7 2-6h14.6l2 6z"
      style={{
        opacity: 0.8,
        fill: "#fff",
        // enableBackground: "new",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(CarIcon);
export default ForwardRef;
