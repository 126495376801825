import { SVGProps, Ref, forwardRef } from "react";

const BankIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M3 16.3821V9.38208H5V16.3821H3ZM9 16.3821V9.38208H11V16.3821H9ZM0 20.3821V18.3821H20V20.3821H0ZM15 16.3821V9.38208H17V16.3821H15ZM0 7.38208V5.38208L10 0.38208L20 5.38208V7.38208H0ZM4.45 5.38208H15.55L10 2.63208L4.45 5.38208Z"
      fill="#545D78"
    />
  </svg>
);

const ForwardRef = forwardRef(BankIcon);
export default ForwardRef;
