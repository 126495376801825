import { SVGProps, Ref, forwardRef } from "react";

const ComponentEnhancedElectricalIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Component Enhanced Electrical Icon"
    {...props}
  >
    <title id="Component Enhanced Electrical Icon">
      Component Enhanced Electrical Icon
    </title>
    <path
      fillRule="evenodd"
      d="M6.667 5.333c0 .494-.269.925-.667 1.155v4.179h4v4H6v2.845a1.333 1.333 0 1 1-1.334 0V14h.001v-.667h4V12h-4V6.488a1.333 1.333 0 1 1 2-1.155Zm10.844-.666a1.333 1.333 0 1 1 0 1.333H16v13.333h-5.512a1.333 1.333 0 1 1 0-1.333h4.178V5.333h.001v-.666h2.844Zm-6.178 10.178V9.333H8.667V6.488a1.333 1.333 0 1 1 1.333 0V8h2.667v6.845a1.333 1.333 0 1 1-1.334 0ZM20 18.667c0-.494-.268-.924-.666-1.155v-7.024a1.333 1.333 0 1 0-1.334 0v7.023a1.333 1.333 0 1 0 2 1.156Z"
      clipRule="evenodd"
    />
  </svg>
);

const ForwardRef = forwardRef(ComponentEnhancedElectricalIcon);
export default ForwardRef;
