import { SVGProps, Ref, forwardRef } from "react";

const GraphIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Graph Icon"
    {...props}
  >
    <path d="M10 20h4V4h-4v16Zm-6 0h4v-8H4v8ZM16 9v11h4V9h-4Z" />
  </svg>
);

const ForwardRef = forwardRef(GraphIcon);
export default ForwardRef;
