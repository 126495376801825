import { SVGProps, Ref, forwardRef } from "react";

const PauseIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Pause Icon"
    {...props}
  >
    <path d="M6 19h4V5H6v14Zm8-14v14h4V5h-4Z" />
  </svg>
);

const ForwardRef = forwardRef(PauseIcon);
export default ForwardRef;
