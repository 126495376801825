import { SVGProps, Ref, forwardRef } from "react";

const CircleFullIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Circle Full Icon"
    {...props}
  >
    <title id="Circle Full Icon">Circle Full Icon</title>
    <path d="M12 22a9.733 9.733 0 0 1-3.9-.788 10.114 10.114 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.76 9.76 0 0 1 2 12c0-1.383.262-2.683.788-3.9a10.121 10.121 0 0 1 2.137-3.175A10.07 10.07 0 0 1 8.1 2.788 9.742 9.742 0 0 1 12 2c1.382 0 2.682.263 3.9.788a10.084 10.084 0 0 1 3.175 2.137c.899.9 1.611 1.958 2.138 3.175A9.679 9.679 0 0 1 22 12a9.806 9.806 0 0 1-.788 3.9 10.032 10.032 0 0 1-2.137 3.175 10.16 10.16 0 0 1-3.175 2.138A9.689 9.689 0 0 1 12 22Z" />
  </svg>
);

const ForwardRef = forwardRef(CircleFullIcon);
export default ForwardRef;
