import { SVGProps, Ref, forwardRef } from "react";

const ClockIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Clock Icon"
    {...props}
  >
    <path d="M18.7 5.3A9.476 9.476 0 1 0 5.3 18.702 9.476 9.476 0 0 0 18.7 5.3zM12 19.2c-4 0-7.2-3.2-7.2-7.2S8 4.8 12 4.8 19.2 8 19.2 12 16 19.2 12 19.2z" />
    <path d="M12.7 6.5h-1.4v5.8l4.1 4 1-.9-3.7-3.7z" />
  </svg>
);

const ForwardRef = forwardRef(ClockIcon);
export default ForwardRef;
