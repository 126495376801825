import { SVGProps, Ref, forwardRef } from "react";

const ChevronDownIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Chevron Down Icon"
    {...props}
  >
    <path d="M7.41 7.84 12 12.42l4.59-4.58L18 9.25l-6 6-6-6 1.41-1.41Z" />
  </svg>
);

const ForwardRef = forwardRef(ChevronDownIcon);
export default ForwardRef;
