import { SVGProps, Ref, forwardRef } from "react";

const AppAppleIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="App Apple Icon"
    {...props}
  >
    <path d="M14.8 5c-.7.8-1.8 1.4-2.8 1.3-.1-1.1.4-2.2 1-3 .7-.8 1.9-1.4 2.8-1.5.1 1.3-.3 2.4-1 3.2m1 1.6c-1.6-.1-2.9.9-3.7.9-.7 0-1.9-.9-3.1-.9-1.6.1-3.1 1-4 2.4-1.7 2.9-.4 7.2 1.2 9.6.8 1.2 1.8 2.5 3 2.4 1.2 0 1.7-.8 3.1-.8s1.9.8 3.1.8c1.3 0 2.1-1.2 2.9-2.4.9-1.3 1.3-2.6 1.3-2.7 0 0-2.5-1-2.6-3.9 0-2.4 2-3.6 2.1-3.6-.9-1.6-2.7-1.8-3.3-1.8" />
  </svg>
);

const ForwardRef = forwardRef(AppAppleIcon);
export default ForwardRef;
