import { SVGProps, Ref, forwardRef } from "react";

const LowKMsIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Low KMs Icon"
    {...props}
  >
    <g>
      <path
        d="M12.4,12.7c-0.4,0-0.7,0.1-1,0.4l-2.3-1.3l-0.5,1l2.3,1.3c0,0,0,0.1,0,0.1c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5
		C13.9,13.4,13.3,12.7,12.4,12.7z"
      />
      <path
        d="M20.9,11.7c-0.7-1.8-1.9-3.3-3.5-4.4C15.9,6.2,14,5.6,12,5.6c-1.4,0-2.7,0.3-3.9,0.8c-1.8,0.8-3.2,2.1-4.2,3.7
		c-1,1.6-1.5,3.5-1.5,5.5h2.5c0-2,0.7-3.9,2-5.3C7.4,9.6,8.2,9,9.1,8.7c0.9-0.4,1.8-0.6,2.9-0.6c1,0,1.9,0.2,2.8,0.6
		c1.3,0.6,2.4,1.5,3.2,2.7c0.8,1.2,1.2,2.6,1.2,4.1h2.5C21.7,14.2,21.4,12.9,20.9,11.7z"
      />
    </g>
  </svg>
);
const ForwardRef = forwardRef(LowKMsIcon);
export default ForwardRef;
