import { SVGProps, Ref, forwardRef } from "react";

const FavouriteOffIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 26 26"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Icon Of A Heart"
    {...props}
  >
    <path d="M22.7 6c-1.1-1-2.5-1.5-3.8-1.5-1.4 0-2.8.5-3.9 1.5l-2 2-2-2C9.9 5 8.5 4.5 7.1 4.5S4.4 5 3.3 6C2.2 7 1.7 8.4 1.7 9.8s.5 2.7 1.6 3.7l9.7 9.3 9.7-9.3c1.1-1 1.6-2.4 1.6-3.7 0-1.4-.5-2.8-1.6-3.8zm-1.3 6.1L13 20.2l-8.4-8.1c-.7-.7-1-1.5-1-2.4 0-.9.3-1.7 1-2.4s1.6-1 2.5-1c.9 0 1.8.3 2.5 1l3.3 3.2 3.3-3.2c.7-.7 1.6-1 2.5-1 .9 0 1.8.3 2.5 1s1 1.5 1 2.4c.2.9-.1 1.8-.8 2.4z" />
  </svg>
);

const ForwardRef = forwardRef(FavouriteOffIcon);
export default ForwardRef;
