import { SVGProps, Ref, forwardRef } from "react";

const BrandsChevroletIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 100 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Chevrolet Icon"
    {...props}
  >
    <path
      d="M62.6 23.9v-4.3H37.4v4.3H21.6l-6.7 13.9h22.5v4.5h25.2v-4.5h15.6l7-13.9H62.6zm12.6 9.6H58.9v4.3H41.4v-4.3H21.6l2.6-5.5h17.1v-4.1h17.5V28H78l-2.8 5.5z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        fill: "#78819b",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsChevroletIcon);
export default ForwardRef;
