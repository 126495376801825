import { SVGProps, Ref, forwardRef } from "react";

const FilterListIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Filter List Icon"
    {...props}
  >
    <path d="M10 18h4v-2h-4v2ZM3 6v2h18V6H3Zm3 7h12v-2H6v2Z" />
  </svg>
);

const ForwardRef = forwardRef(FilterListIcon);
export default ForwardRef;
