import { SVGProps, Ref, forwardRef } from "react";

const ReceiptIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Receipt Icon"
    {...props}
  >
    <path d="M19.5 3.5 18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2v20l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5ZM19 19.09H5V4.91h14v14.18ZM6 15h12v2H6v-2Zm0-4h12v2H6v-2Zm0-4h12v2H6V7Z" />
  </svg>
);

const ForwardRef = forwardRef(ReceiptIcon);
export default ForwardRef;
