import { SVGProps, Ref, forwardRef } from "react";

const GaugeVariantOneIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 65 66"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Gauge Variant One Icon"
    {...props}
  >
    <title id="Gauge Variant One Icon">Gauge Variant One Icon</title>
    <path d="M32.499 10.52c-14.625 0-26.542 11.917-26.542 26.542 0 7.313 2.98 13.813 7.583 18.417l5.146-5.146c-3.25-3.52-5.416-8.125-5.416-13.27 0-10.563 8.666-19.23 19.229-19.23 10.562 0 19.229 8.667 19.229 19.23 0 5.145-2.167 9.75-5.417 13.27l5.146 5.146c4.604-4.875 7.583-11.375 7.583-18.417 0-14.625-11.916-26.541-26.541-26.541Z" />
    <path d="m20.878 47.798 8.292-4.788c.715.472 1.43.944 2.214 1.155 3.14.84 6.456-1.074 7.298-4.214.84-3.139-1.074-6.456-4.214-7.297-3.139-.841-6.456 1.074-7.297 4.213-.21.785-.229 1.902-.108 2.495l-8.292 4.788 2.107 3.648Z" />
  </svg>
);

const ForwardRef = forwardRef(GaugeVariantOneIcon);
export default ForwardRef;
