import { SVGProps, Ref, forwardRef } from "react";

const BrandsToyotaIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 100 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Toyota Icon"
    {...props}
  >
    <path
      d="M50 9.4c-18.7 0-33.9 9.7-33.9 21.6S31.3 52.6 50 52.6 83.9 42.9 83.9 31 68.7 9.4 50 9.4zM24.9 22.5c.9 3.9 7.7 7.1 16.9 8.4v.6c0 9.3 2.7 17 6.3 19.1-14.9-.7-26.6-8.9-26.6-19-.1-3.4 1.2-6.4 3.4-9.1zm20.3 8.7c1.6.1 3.2.2 4.8.2s3.3-.1 4.8-.2c-.2 6.8-2.3 12.2-4.8 12.2s-4.6-5.4-4.8-12.2zm13-.4c9.4-1.3 16.3-4.6 17-8.6 2.4 2.8 3.7 6 3.7 9.4C78.9 41.7 67 50 52 50.6c3.6-2.1 6.3-9.9 6.3-19.1-.1-.3-.1-.5-.1-.7zM52.9 13c9.4.5 16.6 3.2 16.6 6.5 0 2.7-4.8 5-11.7 6-.7-5.8-2.5-10.4-4.9-12.5zm1.7 12.9c-1.5.1-3 .2-4.6.2s-3.1-.1-4.6-.2c.6-5.4 2.5-9.4 4.6-9.4s4 3.9 4.6 9.4zm-12.4-.4c-6.9-1-11.7-3.3-11.7-6 0-1.1.8-2.2 2.3-3.1 1.7-.9 3.5-1.6 5.5-2.2 2.5-.6 5.5-1.1 8.8-1.3-2.4 2.2-4.2 6.8-4.9 12.6z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        fill: "#78819b",
      }}
    />
    <path
      d="M50 9.4c-18.7 0-33.9 9.7-33.9 21.6S31.3 52.6 50 52.6 83.9 42.9 83.9 31 68.7 9.4 50 9.4zM24.9 22.5c.9 3.9 7.7 7.1 16.9 8.4v.6c0 9.3 2.7 17 6.3 19.1-14.9-.7-26.6-8.9-26.6-19-.1-3.4 1.2-6.4 3.4-9.1zm20.3 8.7c1.6.1 3.2.2 4.8.2s3.3-.1 4.8-.2c-.2 6.8-2.3 12.2-4.8 12.2s-4.6-5.4-4.8-12.2zm13-.4c9.4-1.3 16.3-4.6 17-8.6 2.4 2.8 3.7 6 3.7 9.4C78.9 41.7 67 50 52 50.6c3.6-2.1 6.3-9.9 6.3-19.1-.1-.3-.1-.5-.1-.7zM52.9 13c9.4.5 16.6 3.2 16.6 6.5 0 2.7-4.8 5-11.7 6-.7-5.8-2.5-10.4-4.9-12.5zm1.7 12.9c-1.5.1-3 .2-4.6.2s-3.1-.1-4.6-.2c.6-5.4 2.5-9.4 4.6-9.4s4 3.9 4.6 9.4zm-12.4-.4c-6.9-1-11.7-3.3-11.7-6 0-1.1.8-2.2 2.3-3.1 1.7-.9 3.5-1.6 5.5-2.2 2.5-.6 5.5-1.1 8.8-1.3-2.4 2.2-4.2 6.8-4.9 12.6z"
      style={{
        fill: "#78819b",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsToyotaIcon);
export default ForwardRef;
