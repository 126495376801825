import { SVGProps, Ref, forwardRef } from "react";

const FormatAlignCentreIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Format Align Centre Icon"
    {...props}
  >
    <path d="M7 15v2h10v-2H7Zm-4 6h18v-2H3v2Zm0-8h18v-2H3v2Zm4-6v2h10V7H7ZM3 3v2h18V3H3Z" />
  </svg>
);

const ForwardRef = forwardRef(FormatAlignCentreIcon);
export default ForwardRef;
