import { SVGProps, Ref, forwardRef } from "react";

const AppDropboxIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="App Dropbox Icon"
    {...props}
  >
    <path d="M8.7 4 12 6.7l-4.7 3L4 7.1 8.7 4ZM4 12.3l3.3-2.6 4.7 2.9-3.3 2.7-4.7-3Zm8 .3 4.7-2.9 3.3 2.6-4.7 3-3.3-2.7Zm8-5.5-3.3 2.6-4.7-3L15.3 4 20 7.1Zm-4.8 9.5 1.5-1v1L12 19.4l-4.7-2.8v-1l1.5 1 3.2-2.7 3.2 2.7Z" />
  </svg>
);

const ForwardRef = forwardRef(AppDropboxIcon);
export default ForwardRef;
