import { SVGProps, Ref, forwardRef } from "react";

const BrandsDodgeIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 140 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Dodge Icon"
    {...props}
  >
    <path
      d="M363.7 364c-2 0-8.7.1-8.7 3.2 0 2.1 3.1 2.8 5.7 3.1 1.8.1 3.4.1 3.4.1h3.2c2.8-.1 6.3-.8 6.3-3.2 0-3.2-9.1-3.2-9.5-3.2h-.4zm-18 0h-9s-.4 0-.7.1c-.3.1-.3.6-.3.6v4.9c0 .7.8.7.8.7h9.4s3.1 0 5.2-.7c1.4-.4 2.1-1.4 2.1-2.5 0-1.3-1.3-2.1-2.8-2.7-2.1-.4-4.7-.4-4.7-.4zm39.7 0h-9s-.4 0-.7.1-.3.6-.3.6v4.9c0 .7.8.7.8.7h9.4s3.1 0 5.2-.7c1.4-.4 2.1-1.4 2.1-2.5 0-1.3-1.3-2.1-2.9-2.7-2-.4-4.6-.4-4.6-.4zm26.7 0h-10.4s-.8 0-1.8.1c-2.1.1-5.2.8-5.2 3.1 0 1 .6 1.8 1.5 2.2 2.1 1 5.9.8 5.9.8h9.2c.8 0 .8-.4.8-.8V367c0-.8-.8-.7-.8-.7h-8.1c-.4 0-.4.3-.4.3v.7c0 .3.3.3.3.3h4.8c.3 0 .3.3.3.3v.6c0 .3-.3.3-.3.3h-5.5c-.8 0-3.8 0-3.8-1.8s3.5-1.8 3.5-1.8h9.7c.4 0 .3-.4.3-.4v-.6c.3-.2 0-.2 0-.2zm3.2 0c-.7 0-1 .1-1 .8 0 .6-.1 3.9-.1 4.6s.4.8 1 .8H430c.3 0 .3-.3.3-.3v-.7c0-.3-.4-.3-.4-.3h-11.2c-.4 0-.4-.3-.4-.3v-.6c0-.3.3-.3.4-.3h10.6c.3 0 .3-.3.3-.3v-.7c0-.3-.4-.3-.4-.3h-10.5c-.3 0-.4-.4-.4-.4v-.4c0-.3.4-.4.4-.4h11.2c.3 0 .4-.3.4-.4v-.6c0-.3-.4-.3-.4-.3l-14.6.1zm-51.1 1.3c1 0 5.5.1 5.5 2 0 2-4.2 1.8-5.5 1.8-1.3 0-5.2-.1-5.2-1.8.2-2 4.4-2 5.2-2zm-18.5.1s1.3 0 2.2.4c.7.3 1.3.8 1.3 1.4s-.3 1-1 1.3c-1 .4-2.5.6-2.5.6H340s-.3 0-.3-.3v-3.1s0-.3.3-.3h5.7zm39.8 0s1.3 0 2.2.4c.7.3 1.3.8 1.3 1.4s-.3 1-1 1.3c-1 .4-2.5.6-2.5.6h-5.7s-.3 0-.3-.3v-3.1s0-.3.3-.3h5.7z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        fill: "#78819b",
      }}
      transform="matrix(1.25 0 0 -1.25 -419.74 490.95)"
    />
    <path
      d="M429.3 363.2h.4c.7 0 1.3.6 1.3 1.1v.6c0 .7-.3 1.1-.8 1.3.1.1.1.4.1.6v.7c0 .1 0 .3-.1.6.4.1.7.3.8.6l7.3-8.5h-6.6c.1 0-2.4 3-2.4 3zm11.9-3-13.4 15.6h6.6l13.4-15.6zm-18.9 11.1-3.9 4.5h6.6l3.9-4.5z"
      style={{
        fill: "#78819b",
      }}
      transform="matrix(1.25 0 0 -1.25 -419.74 490.95)"
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsDodgeIcon);
export default ForwardRef;
