import { SVGProps, Ref, forwardRef } from "react";

const AToBIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="A To B Icon"
    {...props}
  >
    <path
      d="M7.4,6.4h8.3c0.1,0,0.1,0,0.2,0c0.4,1.1,1.4,1.9,2.6,1.9c3.7,0,3.7-5.6,0-5.6c-1.2,0-2.2,0.7-2.6,1.9c-0.1,0-0.1,0-0.2,0
	H7.4c-2.2,0.1-3.8,2-3.7,4.2c-0.1,2.2,1.5,4,3.7,4.2h9.3c1.1,0.1,2,1.2,1.9,2.3c0.1,1.1-0.7,2.2-1.9,2.3H8.3c-0.1,0-0.1,0-0.2,0
	c-0.4-1.1-1.4-1.9-2.6-1.9c-3.7,0-3.7,5.6,0,5.6c1.2,0,2.2-0.7,2.6-1.9c0.1,0,0.1,0,0.2,0h8.3c2.2-0.1,3.8-2,3.7-4.2
	c0.1-2.2-1.5-4-3.7-4.2H7.4c-1.1-0.1-2-1.2-1.9-2.3C5.4,7.6,6.2,6.6,7.4,6.4z M18.5,4.6c0.5,0,0.9,0.4,0.9,0.9
	c0,0.5-0.4,0.9-0.9,0.9S17.6,6,17.6,5.5S18,4.6,18.5,4.6z M5.5,19.4c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9
	S6,19.4,5.5,19.4z"
    />
  </svg>
);

const ForwardRef = forwardRef(AToBIcon);
export default ForwardRef;
