import { SVGProps, Ref, forwardRef } from "react";

const BrandsBmwIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="a"
    aria-hidden="true"
    viewBox="0 0 100 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Bmw Icon"
    {...props}
  >
    <defs>
      <style>{"\r\n      .b{fill:#78819b}\r\n    "}</style>
    </defs>
    <path
      d="M50 61.93a30.762 30.762 0 0 1-17.29-5.28c-1.64-1.11-3.18-2.38-4.58-3.78s-2.67-2.94-3.78-4.58A30.798 30.798 0 0 1 19.07 31a30.798 30.798 0 0 1 5.28-17.29c1.11-1.64 2.38-3.18 3.78-4.58s2.94-2.67 4.58-3.78A30.762 30.762 0 0 1 50 .07a30.762 30.762 0 0 1 17.29 5.28c1.64 1.11 3.18 2.38 4.58 3.78s2.67 2.94 3.78 4.58A30.798 30.798 0 0 1 80.93 31a30.798 30.798 0 0 1-5.28 17.29c-1.11 1.64-2.38 3.18-3.78 4.58s-2.94 2.67-4.58 3.78A30.798 30.798 0 0 1 50 61.93Zm0-59.96c-3.92 0-7.72.77-11.3 2.28a29.18 29.18 0 0 0-9.23 6.23 29.18 29.18 0 0 0-6.23 9.23c-1.51 3.58-2.28 7.38-2.28 11.3s.77 7.72 2.28 11.3a29.18 29.18 0 0 0 6.23 9.23 29.29 29.29 0 0 0 4.3 3.55c1.55 1.05 3.21 1.95 4.93 2.68 3.58 1.51 7.38 2.28 11.3 2.28s7.72-.77 11.3-2.28a29.18 29.18 0 0 0 9.23-6.23 29.29 29.29 0 0 0 3.55-4.3c1.05-1.55 1.95-3.21 2.68-4.93 1.51-3.58 2.28-7.38 2.28-11.3s-.77-7.72-2.28-11.3a29.18 29.18 0 0 0-6.23-9.23 29.29 29.29 0 0 0-4.3-3.55 29.035 29.035 0 0 0-4.93-2.68A28.916 28.916 0 0 0 50 1.97Z"
      className="b"
    />
    <path
      d="M31.67 31c0-10.13 8.21-18.33 18.33-18.33 10.12 0 18.33 8.21 18.33 18.33 0 10.12-8.21 18.33-18.33 18.33-10.13 0-18.33-8.21-18.33-18.33Zm6.1-12.23A17.238 17.238 0 0 0 32.7 31c0 4.78 1.94 9.1 5.07 12.23C40.9 46.36 45.22 48.3 50 48.3s9.1-1.94 12.23-5.07C65.36 40.1 67.3 35.78 67.3 31s-1.94-9.1-5.07-12.23A17.238 17.238 0 0 0 50 13.7c-4.78 0-9.1 1.94-12.23 5.07Z"
      className="b"
    />
    <path
      d="M31.68 31h18.33V12.67c-10.13 0-18.33 8.21-18.33 18.33Z"
      className="b"
    />
    <path
      d="M68.33 30.99H50v18.34c10.12 0 18.33-8.22 18.33-18.34Zm2.91-16.94-4.09 2.7 1.93-3.76c-.5-.59-1.26-1.22-1.26-1.22l-3.73 1.87 2.74-4.01c-.45-.38-.98-.7-1.48-1.08l-3.83 5.97s.73.43 1.61 1.25l3.96-2.05-2.08 3.9c.47.49.88 1.09 1.32 1.62l6.03-3.73s-.37-.58-1.13-1.47ZM50.42 9.98l1.84-4.06.17 5.15c.74.03 1.83.31 1.83.31l-.33-7.29c-1.39-.2-2.49-.26-2.49-.26l-1.77 4.41-1.73-4.37s-1.14.11-2.53.31l-.33 7.35s1.09-.32 1.83-.35l.16-5.23 1.84 4.03h1.51Zm-15.56 2.39c.18-.68-.03-1.39-.54-1.85-.75-.68-1.86-.7-3.04.48 0 0-2.19 2.19-3.67 4.34l5.32 4.85 4.01-4.49c.87-.96.91-2.33.11-3.05-.6-.54-1.46-.62-2.18-.27Zm-5.13 2.61c1.88-2.33 2.64-2.97 2.64-2.97.49-.43.86-.46 1.16-.19.3.27.26.8-.08 1.17l-2.66 2.95-1.05-.96Zm5.99-.2-2.8 3.1-1.05-.96 2.8-3.1c.34-.38.85-.47 1.14-.2s.25.79-.09 1.16Z"
      className="b"
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsBmwIcon);
export default ForwardRef;
