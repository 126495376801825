import { SVGProps, Ref, forwardRef } from "react";

const CircleThreeQuartersIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Circle Three Quarters Icon"
    {...props}
  >
    <title id="Circle Three Quarters Icon">Circle Three Quarters Icon</title>
    <path d="M12 2h-1v9H2v1a10 10 0 0 0 19.806 1.95A10 10 0 0 0 12 2Z" />
  </svg>
);

const ForwardRef = forwardRef(CircleThreeQuartersIcon);
export default ForwardRef;
