import { SVGProps, Ref, forwardRef } from "react";

const ComponentBrakesIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Component Brakes Icon"
    {...props}
  >
    <title id="Component Brakes Icon">Component Brakes Icon</title>
    <path
      fillRule="evenodd"
      d="M4 11.04A7.652 7.652 0 0 1 11.04 4v3.55a4.122 4.122 0 0 0-3.49 3.49H4Zm8.503-5.117A6.4 6.4 0 1 1 5.92 12.32H4.64a7.68 7.68 0 1 0 7.862-7.678v1.28Zm3.657 6.397a3.84 3.84 0 1 1-7.68 0 3.84 3.84 0 0 1 7.68 0Zm-2.56 0a1.28 1.28 0 1 1-2.56 0 1.28 1.28 0 0 1 2.56 0Zm-1.28-1.92a.64.64 0 1 0 0-1.28.64.64 0 0 0 0 1.28Zm.64 4.48a.64.64 0 1 1-1.28 0 .64.64 0 0 1 1.28 0Zm1.28-2.56a.64.64 0 1 0 1.28 0 .64.64 0 0 0-1.28 0Zm-4.48.64a.64.64 0 1 1 0-1.28.64.64 0 0 1 0 1.28Z"
      clipRule="evenodd"
    />
  </svg>
);

const ForwardRef = forwardRef(ComponentBrakesIcon);
export default ForwardRef;
