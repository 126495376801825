import { SVGProps, Ref, forwardRef } from "react";

const CircleHalfIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Circle Half Icon"
    {...props}
  >
    <title id="Circle Half Icon">Circle Half Icon</title>
    <path d="M12 2a10 10 0 1 1 0 20V2Z" />
  </svg>
);

const ForwardRef = forwardRef(CircleHalfIcon);
export default ForwardRef;
