import { SVGProps, Ref, forwardRef } from "react";

const PieChartIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Pie Chart Icon"
    {...props}
  >
    <path d="M2.4 12c0-2.5 1-5 2.8-6.8S9.5 2.4 12 2.4V12h9.6c0 2.5-1 5-2.8 6.8-1.8 1.8-4.2 2.8-6.8 2.8-2.5 0-5-1-6.8-2.8C3.4 17 2.4 14.5 2.4 12z" />
    <path d="M14.4 2.7c1.7.4 3.2 1.3 4.4 2.5 1.2 1.2 2.1 2.7 2.5 4.4h-6.9V2.7z" />
  </svg>
);

const ForwardRef = forwardRef(PieChartIcon);
export default ForwardRef;
