import { SVGProps } from "react";

const VlpSwoopIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 262 48.2"
      aria-label="Vlp Swoop Icon"
      {...props}
    >
      <path d="M0 23.4s37 22.5 137 16S262 0 262 0v48.2H0V23.4z" fill="#fff" />
    </svg>
  );
};

export default VlpSwoopIcon;
