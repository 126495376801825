import { SVGProps, Ref, forwardRef } from "react";

const DownloadIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Download Icon"
    {...props}
  >
    <path d="M19 9h-4V3H9v6H5l7 7 7-7ZM5 18v2h14v-2H5Z" />
  </svg>
);

const ForwardRef = forwardRef(DownloadIcon);
export default ForwardRef;
