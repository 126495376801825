import { SVGProps, Ref, forwardRef } from "react";

const GaugeIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Gauge Icon"
    {...props}
  >
    <path d="M12 3.7c-5.4 0-9.8 4.4-9.8 9.8 0 2.7 1.1 5.1 2.8 6.8l1.9-1.9c-1.2-1.3-2-3-2-4.9 0-3.9 3.2-7.1 7.1-7.1 3.9 0 7.1 3.2 7.1 7.1 0 1.9-.8 3.6-2 4.9l1.9 1.9c1.7-1.8 2.8-4.2 2.8-6.8 0-5.4-4.4-9.8-9.8-9.8z" />
    <path d="m15.4 9.1-2.5 2.5c-.3-.1-.6-.2-.9-.2-1.2 0-2.2 1-2.2 2.2 0 1.2 1 2.2 2.2 2.2s2.2-1 2.2-2.2c0-.3-.1-.7-.2-.9l2.5-2.5-1.1-1.1z" />
  </svg>
);

const ForwardRef = forwardRef(GaugeIcon);
export default ForwardRef;
