import { SVGProps, Ref, forwardRef } from "react";

const ComponentSteeringIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Component Steering Icon"
    {...props}
  >
    <title id="Component Steering Icon">Component Steering Icon</title>
    <path
      fillRule="evenodd"
      d="M20 12c0 4.463-3.621 8-8 8s-8-3.537-8-8 3.537-8 8-8 8 3.537 8 8ZM7.705 16.295A5.984 5.984 0 0 0 12 18.063c1.6 0 3.116-.673 4.295-1.768.084-.085.147-.169.21-.253.063-.084.127-.168.21-.253l-2.406-1.336a3.357 3.357 0 0 1-2.309.915 3.357 3.357 0 0 1-2.309-.916L7.284 15.79c.084.084.148.168.21.252.064.084.127.168.211.253Zm1.02-3.504L6.358 14.19c-.253-.674-.421-1.432-.421-2.106 0-1.684.674-3.2 1.768-4.379.927-.926 2.106-1.516 3.284-1.684v2.765a3.37 3.37 0 0 0-2.264 4.005Zm4.286-4.005V6.02c1.263.168 2.442.758 3.284 1.684C17.39 8.885 18.063 10.4 18.063 12c0 .758-.168 1.432-.42 2.105l-2.361-1.34a3.375 3.375 0 0 0-2.271-3.98Z"
      clipRule="evenodd"
    />
  </svg>
);

const ForwardRef = forwardRef(ComponentSteeringIcon);
export default ForwardRef;
