import { SVGProps, Ref, forwardRef } from "react";

const LocationIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Location Icon"
    {...props}
  >
    <path
      d="M17.1,0.8c-0.1-0.2-0.3-0.2-0.5-0.2l0,0h0c-0.1,0-0.2,0-0.3,0.1L0.4,10.8C0.1,11-0.1,11.3,0,11.6C0.1,11.9,0.4,12,0.7,12
 l7.6-0.2h0c0.3,0,0.5,0.2,0.6,0.4l2.5,6.7v0c0,0.3,0.3,0.6,0.6,0.6c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4l4.6-17.4v0
 C17.2,1.2,17.2,1,17.1,0.8z"
    />
  </svg>
);

const ForwardRef = forwardRef(LocationIcon);
export default ForwardRef;
