import { SVGProps, Ref, forwardRef } from "react";

const HeadsUpIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Heads Up Icon"
    {...props}
  >
    <path
      d="M4.6 17.3c4.1 4.1 10.8 4.1 14.9 0-4.2-4.2-10.8-4.2-14.9 0zm9.4 0c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .8 2 2zM2.5 3.7h19l-2.1 6.7H4.6z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(HeadsUpIcon);
export default ForwardRef;
