import { SVGProps, Ref, forwardRef } from "react";

const GoogleMapsIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 34 34"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    {...props}
  >
    <path d="M25.8 8.6c1.4 3 1.1 6.6-.5 9.5-1.3 2.4-3.2 4.4-4.8 6.6-.7 1-1.4 2-1.9 3.2-.2.4-.3.8-.4 1.2-.1.4-.3.8-.4 1.2-.2.3-.3.7-.8.7s-.7-.6-.8-1c-.3-1-.7-2-1.2-2.9-.6-1.1-1.3-2.1-2.1-3.1L25.8 8.6zM13 12l-4.6 5.5c.9 1.8 2.1 3.3 3.3 4.9.3.4.6.7.9 1.1l5.8-6.9h-.1c-2 .7-4.3-.3-5.1-2.4-.1-.2-.2-.5-.2-.8-.1-.5-.1-.9 0-1.4zM9.4 6.7C7.1 9.6 6.7 13.6 8.1 17l5.6-6.6-.1-.1-4.2-3.6zm10.7-3.2-4.5 5.3h.1c1.9-.6 4 .2 4.9 1.9.2.4.4.8.4 1.2.1.5.1.9 0 1.4L25.5 8c-1.1-2.1-3.1-3.7-5.4-4.5zM14 9.8l5.5-6.5h-.1c-.7-.2-1.6-.3-2.4-.3-2.8 0-5.4 1.2-7.2 3.2L14 9.8z" />
  </svg>
);

const ForwardRef = forwardRef(GoogleMapsIcon);
export default ForwardRef;
