import { SVGProps, Ref, forwardRef } from "react";

const PriceTagsIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    {...props}
  >
    <path d="M21.7 3.5h-6.5c-.4 0-1.1.3-1.4.6l-8.1 8.1c-.2.2-.2.4-.2.6 0 .2.1.4.2.6l7 7c.2.2.4.2.6.2.2 0 .4-.1.6-.2l8.1-8.1c.3-.3.6-.9.6-1.4V4.3c0-.2-.1-.4-.2-.6-.3-.1-.5-.2-.7-.2zM17.6 10c-.2 0-.4 0-.6-.1-.2-.1-.4-.2-.5-.4-.2-.2-.3-.3-.4-.5-.1-.2-.1-.4-.1-.6 0-.2 0-.4.1-.6.1-.2.2-.4.4-.5.1-.2.3-.3.5-.4.2-.1.4-.1.6-.1.4 0 .8.2 1.1.5.3.3.5.7.5 1.1 0 .4-.2.8-.5 1.1-.2.3-.6.5-1.1.5z" />
    <path d="m3.3 12.7 9.2-9.2h-1.3c-.4 0-1.1.3-1.4.6l-8 8.1c-.2.2-.2.4-.2.6 0 .2.1.4.2.6l7 7c.2.2.4.2.6.2.2 0 .4-.1.6-.2l.5-.5-7.2-7.2z" />
  </svg>
);

const ForwardRef = forwardRef(PriceTagsIcon);
export default ForwardRef;
