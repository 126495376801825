import { SVGProps, Ref, forwardRef } from "react";

const IdIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 26 26"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Id Icon"
    {...props}
  >
    <title id="Id Icon">Id Icon</title>
    <path d="M10.525 13c1.247 0 2.167-.92 2.167-2.167 0-1.247-.92-2.166-2.167-2.166-1.247 0-2.167.92-2.167 2.166 0 1.247.92 2.167 2.167 2.167Z" />
    <path d="M21.667 4.333H4.334c-1.195 0-2.167.911-2.167 2.032v13.27c0 1.12.972 2.032 2.167 2.032h17.333c1.195 0 2.167-.911 2.167-2.032V6.366c0-1.12-.972-2.032-2.167-2.032Zm0 15.167-17.333-.012V6.5l17.333.012V19.5Z" />
    <path d="M15.167 9.75H19.5v2.167h-4.333V9.75Zm1.083 4.333h3.25v2.167h-3.25v-2.167Zm-1.7 2.748c0-1.489-1.817-3.018-4.025-3.018-2.21 0-4.025 1.53-4.025 3.018v.502h8.05v-.502Z" />
  </svg>
);

const ForwardRef = forwardRef(IdIcon);
export default ForwardRef;
