import { SVGProps, Ref, forwardRef } from "react";

const ComponentCoolingSystemIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Component Cooling System Icon"
    {...props}
  >
    <title id="Component Cooling System Icon">
      Component Cooling System Icon
    </title>
    <path
      fillRule="evenodd"
      d="M9.6 8c0-2.16 1.036-4 2.4-4v5.018a6.135 6.135 0 0 1 1.132-1.543c1.527-1.528 3.56-2.097 4.525-1.132l-3.549 3.549A6.135 6.135 0 0 1 16 9.6c2.16 0 4 1.035 4 2.4h-5.02c.533.27 1.065.652 1.545 1.131 1.527 1.528 2.096 3.56 1.131 4.526l-3.548-3.548A6.15 6.15 0 0 1 14.4 16c0 2.16-1.035 4-2.4 4v-5.02a6.134 6.134 0 0 1-1.131 1.546c-1.528 1.527-3.56 2.096-4.526 1.13l3.55-3.548A6.135 6.135 0 0 1 8 14.4c-2.16 0-4-1.035-4-2.4h5.019a6.135 6.135 0 0 1-1.545-1.132c-1.527-1.527-2.096-3.56-1.131-4.525l3.55 3.55A6.135 6.135 0 0 1 9.601 8Zm2.4 5.333a1.333 1.333 0 1 0 0-2.666 1.333 1.333 0 0 0 0 2.666Z"
      clipRule="evenodd"
    />
  </svg>
);

const ForwardRef = forwardRef(ComponentCoolingSystemIcon);
export default ForwardRef;
