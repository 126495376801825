import { SVGProps, Ref, forwardRef } from "react";

const AroundIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Around Icon"
    {...props}
  >
    <g>
      <path d="M21.5,16.5V5c0-1.6-4.3-2.9-9.5-2.9S2.5,3.4,2.5,5v11.6c2.3-1.7,7.2-2.1,9.5-2.1S19.2,14.8,21.5,16.5z" />
      <path d="M7.5,21V16c-2.9,0.5-4.9,1.4-4.9,2.5S4.5,20.5,7.5,21L7.5,21z" />
      <path d="M16.5,21c2.9-0.5,4.9-1.4,4.9-2.5c0-1.1-2-2-4.9-2.5V21L16.5,21z" />
    </g>
  </svg>
);

const ForwardRef = forwardRef(AroundIcon);
export default ForwardRef;
