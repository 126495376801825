import { SVGProps, Ref, forwardRef } from "react";

const VehicleSuvIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 61 25"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Vehicle Suv Icon"
    {...props}
  >
    <path
      d="M58.6 19.9h.1c.8-.1 1.1 0 1.3-1.1.2-1.2.1-3.1.2-3.9 0-.5 0-1-.6-1.1-.3-.1-.6-.1-.6-.3-.1-1.8-.4-3.3-.8-4.6-.7-2.2-1.4-4.1-2.7-6.7C54.8.8 55.1.9 53.3.9 46.8.9 40 .8 33.5 1c-4.5 0-8.8.1-10.6.6-2.4 1.3-4.6 4.5-6.2 5.8-4.3.5-9 .8-13.3 1.8-1.5.4-2 .4-2.3 2-.2.9-.2 2-.3 2.5H.5c-.2 0-.3.3-.3.5 0 1-.1 3 .1 4.2.1 1 .7 1.3 1.6 1.3h1.6c1.2 0 1-.1 1.1-1.3.2-3.3 3-5.9 6.4-5.9 3.4 0 6.1 2.6 6.4 5.9.1 1.3-.2 1.3 1.1 1.3h25.2c1.5 0 1.2-.1 1.4-1.5.4-3.2 3.1-5.7 6.3-5.7s6.1 2.6 6.4 5.8c.1.7-.1 1.4.6 1.4l.2.2zm-47.8-6c2.8 0 5.1 2.3 5.1 5.1s-2.3 5.1-5.1 5.1-5.1-2.3-5.1-5.1 2.3-5.1 5.1-5.1zm40.4 0c2.8 0 5.1 2.3 5.1 5.1s-2.3 5.1-5.1 5.1-5.1-2.3-5.1-5.1 2.3-5.1 5.1-5.1zM21.7 8.3c0-.1.2-.5.3-1 0-.8-1.4-.9-2.3-.8 1.3-1.8 2.4-2.7 3.7-3.7.8-.3 5.6-.4 7.2-.5h2c.4 0 .4.2.3.4-.2 1-.5 3-.7 4.4-.1.7-.3 1.1-.9 1-2.9.3-9.7.5-9.6.2zm22.6-1c-.1-1.2-.7-3-1.1-4.2-.1-.4-.3-.9-.8-.9-2.6.1-4.9 0-7.4.1-.2 0-.4.1-.5.2-.3.7-.4 1.8-.7 4.8-.1.8 0 .9.9.9 2 0 5.9 0 8.7-.1.7.1 1 .2.9-.8zm3.6.1c-.4-.9-1.1-2.6-1.8-4-.4-.8-.4-1.1.6-1.1h7.1c.1 0 .5.2.6.3.4.7.8 1.5 1.9 4.5.3.8.2 1.1-.4 1.1h-6.6c-.8 0-1.2-.3-1.4-.8z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(VehicleSuvIcon);
export default ForwardRef;
