import { SVGProps, Ref, forwardRef } from "react";

const PinLocationIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Pin Location Icon"
    {...props}
  >
    <path d="M16.2 17.1c-.5 0-.9.3-1 .8 0 .5.3.9.8 1 1.1.1 2.2.2 3.3.5.1 0 .2.1.2.2s-.1.2-.2.2c-2.4.5-4.9.7-7.4.6-2.5 0-4.9-.2-7.4-.6-.1 0-.2-.1-.2-.2s.1-.2.2-.2c1.1-.2 2.2-.4 3.3-.5.5-.1.8-.5.8-1s-.5-.8-.9-.8c-6.4.5-6.4 1.9-6.4 2.4 0 2.4 7.3 2.6 10.6 2.6s10.6-.3 10.6-2.6c.1-.5.1-1.8-6.3-2.4z" />
    <path d="M12 19.2c.2 0 6.9-5.4 6.9-10.5 0-3.8-3.1-6.9-6.9-6.9s-6.9 3-6.9 6.9c0 5.9 6.7 10.5 6.9 10.5zm0-14c1.9 0 3.4 1.6 3.4 3.4S13.8 12 12 12s-3.4-1.6-3.4-3.4 1.5-3.4 3.4-3.4z" />
  </svg>
);

const ForwardRef = forwardRef(PinLocationIcon);
export default ForwardRef;
