import { SVGProps, Ref, forwardRef } from "react";

const PhotoIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Photo Icon"
    {...props}
  >
    <path d="M21 3H3C2 3 1 4 1 5v14c0 1.1.9 2 2 2h18c1 0 2-1 2-2V5c0-1-1-2-2-2ZM5 17l3.5-4.5 2.5 3.01L14.5 11l4.5 6H5Z" />
  </svg>
);

const ForwardRef = forwardRef(PhotoIcon);
export default ForwardRef;
