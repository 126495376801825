import { SVGProps, Ref, forwardRef } from "react";

const BrandsGmcIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 100 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Gmc Icon"
    {...props}
  >
    <path
      d="M7.5 26.9v8.3c0 3.1 1.9 5 5.1 5h16.1c3.4 0 5.5-1.7 5.5-4.8v-6.6H21.1v4.1h8.6v1.7c0 1.1-.6 1.4-1.4 1.4H13.6c-1.1 0-1.4-.6-1.4-1.4v-7.1c0-.9.5-1.3 1.1-1.3h21v-4.3H12.6c-3.3-.1-5.1 2-5.1 5zm45.8 13.3 7.4-13.9v13.9h4.6V21.8h-7.1l-7 13.2-7.1-13.2H37v18.4h4.7V26.3L49 40.2zm39.2 0v-4.6H74.1c-1.1 0-1.4-.6-1.4-1.4v-6.4c0-.9.5-1.3 1.1-1.3h18.7v-4.6H73.1c-3.3 0-5.1 2-5.1 5v8.3c0 3.1 1.9 5 5.1 5h19.4z"
      style={{
        fill: "#78819b",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsGmcIcon);
export default ForwardRef;
