import { SVGProps, Ref, forwardRef } from "react";

const AdaptiveCruiseControlIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Cooled Icon"
    {...props}
  >
    <path d="M12,7.5c3.4,0,6.3-2.2,7.4-5.2H4.6C5.6,5.2,8.6,7.5,12,7.5z" />
    <rect x="11.4" y="9.6" width="1.3" height="5.2" />
    <rect
      x="7.8"
      y="10.6"
      transform="matrix(0.8039 -0.5948 0.5948 0.8039 -6.1728 7.6063)"
      width="1.3"
      height="5.2"
    />
    <rect
      x="13"
      y="12.7"
      transform="matrix(0.5948 -0.8039 0.8039 0.5948 -4.3564 17.9612)"
      width="5.2"
      height="1.3"
    />
    <path d="M12,16.5c-3.4,0-6.3,2.2-7.4,5.2h14.8C18.4,18.7,15.4,16.5,12,16.5z" />
  </svg>
);

const ForwardRef = forwardRef(AdaptiveCruiseControlIcon);
export default ForwardRef;
