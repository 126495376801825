import { SVGProps, Ref, forwardRef } from "react";

const LogsIcon2 = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 50 50"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Logs Icon"
    {...props}
  >
    <path
      style={{ fill: "#546E7A" }}
      d="M47.9,26.6L30.4,44.2h-26v-4.4h17.7v-3.9l17.6-17.6c0.9-0.9,2.3-0.9,3.1,0l5.2,5.2
			C48.8,24.3,48.8,25.7,47.9,26.6L47.9,26.6z M4.4,22.1v4.4h22.1v-4.4H4.4z M11,13.2v4.4h22.1v-4.4H11z M4.4,4.4v4.4h39.8V4.4H4.4z
			 M8.8,30.9v4.4h8.8v-4.4H8.8L8.8,30.9z"
    />
    <path
      style={{ opacity: "0.4", fill: "#FFFFFF" }}
      d="M46.1,28.4L43.6,31l-8.3-8.3l2.5-2.5L46.1,28.4z M22.1,44.2h8.3l-8.3-8.3V44.2z"
    />
  </svg>
);

const ForwardRef = forwardRef(LogsIcon2);
export default ForwardRef;
