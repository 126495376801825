import { SVGProps, Ref, forwardRef } from "react";

const ShieldIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    x="0px"
    y="0px"
    viewBox="0 0 34 34"
    xmlSpace="preserve"
    ref={ref}
    {...props}
  >
    <g>
      <path
        className="st0"
        style={{
          fill: "#D5F8FF",
        }}
        d="M17,4.5C13.8,7.4,9.7,9.1,5.4,9.3c-0.3,4.2,0.6,8.3,2.7,12c2.1,3.6,5.2,6.6,8.9,8.4c3.8-1.9,6.9-4.8,8.9-8.4
		c2.1-3.6,3-7.8,2.7-12C24.3,9.1,20.2,7.4,17,4.5L17,4.5z"
      />
      <path
        className="st1"
        style={{
          fill: "#00CFEB",
        }}
        d="M17,4c-3.4,3-7.6,4.7-12.1,5c-0.3,4.4,0.6,8.7,2.8,12.5c2.2,3.8,5.4,6.9,9.3,8.8c3.9-1.9,7.2-5,9.3-8.8
		c2.2-3.8,3.1-8.2,2.8-12.5C24.6,8.7,20.4,6.9,17,4z M17,5.5c3.2,2.5,7,4.1,11,4.6c0.1,2.3-0.2,4.6-0.9,6.8H17V5.5z M7,17.1h10v11.8
		C12.3,26.4,8.7,22.2,7,17.1z"
      />
      <path
        className="st2"
        style={{
          fill: "#293EDC",
        }}
        d="M17,31l-0.5-0.3c-4-2-7.2-5-9.4-8.9C4.9,18,3.9,13.7,4.3,9.2l0.1-1l1-0.1c4-0.3,7.9-1.9,10.9-4.5L17,3l0.8,0.7
		c3,2.7,6.9,4.3,10.9,4.5l1,0.1l0.1,1c0.3,4.4-0.6,8.8-2.8,12.6c-2.2,3.8-5.4,6.9-9.4,8.9L17,31L17,31z M6.5,10.4
		C6.4,14,7.3,17.6,9.1,20.7c1.9,3.3,4.6,5.9,7.9,7.7c3.3-1.8,6.1-4.4,7.9-7.7c1.8-3.2,2.7-6.7,2.6-10.3C23.7,9.9,20,8.4,17,6
		C14,8.4,10.3,9.9,6.5,10.4L6.5,10.4z"
      />
    </g>
  </svg>
);

const ForwardRef = forwardRef(ShieldIcon);
export default ForwardRef;
