import { SVGProps, Ref, forwardRef } from "react";

const ParkAssistIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Park Assist Icon"
    {...props}
  >
    <path d="M20.3 3.8v-.1H3.7v16.6h2V5.8h12.6v14.5h2V3.8z" />
    <circle cx={12} cy={12} r={4} />
  </svg>
);

const ForwardRef = forwardRef(ParkAssistIcon);
export default ForwardRef;
