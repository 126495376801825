import { SVGProps, Ref, forwardRef } from "react";

const NotesIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Notes Icon"
    {...props}
  >
    <path
      d="M7,13.7h6.7V12H7V13.7z M7,11.2H17V9.5H7V11.2z M7,8.6H17V7H7V8.6z M3.6,20.4V5.3c0-0.5,0.2-0.9,0.5-1.2
	c0.3-0.3,0.7-0.5,1.2-0.5h13.4c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2v10.1c0,0.5-0.2,0.9-0.5,1.2c-0.3,0.3-0.7,0.5-1.2,0.5
	H7L3.6,20.4z"
    />
  </svg>
);
const ForwardRef = forwardRef(NotesIcon);
export default ForwardRef;
