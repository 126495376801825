import { SVGProps, Ref, forwardRef } from "react";

const AppYoutubeIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="App Youtube Icon"
    {...props}
  >
    <path d="M19.8 8.8c-.2-1.3-.8-2.2-2.2-2.4C15.4 6 12 6 12 6s-3.4 0-5.6.4C5 6.6 4.3 7.5 4.2 8.8 4 10.1 4 12 4 12s0 1.9.2 3.2c.2 1.3.8 2.2 2.2 2.4 2.2.4 5.6.4 5.6.4s3.4 0 5.6-.4c1.4-.3 2-1.1 2.2-2.4.2-1.3.2-3.2.2-3.2s0-1.9-.2-3.2ZM10 15V9l5 3-5 3Z" />
  </svg>
);

const ForwardRef = forwardRef(AppYoutubeIcon);
export default ForwardRef;
