import { SVGProps, Ref, forwardRef } from "react";

const LogoutIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 20 21"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Logout Icon"
    {...props}
  >
    <title id="Logout Icon">Logout Icon</title>
    <path d="M2.222 20.266a2.139 2.139 0 0 1-1.569-.653A2.144 2.144 0 0 1 0 18.043V2.488C0 1.877.218 1.354.653.919a2.145 2.145 0 0 1 1.57-.653H10v2.222H2.222v15.555H10v2.223H2.222Zm12.222-4.445-1.527-1.61 2.833-2.834H6.667V9.155h9.083L12.917 6.32l1.527-1.61L20 10.265l-5.556 5.555Z" />
  </svg>
);

const ForwardRef = forwardRef(LogoutIcon);
export default ForwardRef;
