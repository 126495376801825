import { SVGProps, Ref, forwardRef } from "react";

const FormatItalicIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Format Italic Icon"
    {...props}
  >
    <path d="M10 4v3h2.21l-3.42 8H6v3h8v-3h-2.21l3.42-8H18V4h-8Z" />
  </svg>
);

const ForwardRef = forwardRef(FormatItalicIcon);
export default ForwardRef;
