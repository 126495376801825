import { SVGProps, Ref, forwardRef } from "react";

const CircleIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Circle Icon"
    {...props}
  >
    <path d="M8.99984 0.666656C4.39984 0.666656 0.666504 4.39999 0.666504 8.99999C0.666504 13.6 4.39984 17.3333 8.99984 17.3333C13.5998 17.3333 17.3332 13.6 17.3332 8.99999C17.3332 4.39999 13.5998 0.666656 8.99984 0.666656ZM8.99984 15.6667C5.3165 15.6667 2.33317 12.6833 2.33317 8.99999C2.33317 5.31666 5.3165 2.33332 8.99984 2.33332C12.6832 2.33332 15.6665 5.31666 15.6665 8.99999C15.6665 12.6833 12.6832 15.6667 8.99984 15.6667Z" />
  </svg>
);

const ForwardRef = forwardRef(CircleIcon);
export default ForwardRef;
