import { SVGProps, Ref, forwardRef } from "react";

const BrandsPontiacIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="a"
    aria-hidden="true"
    viewBox="0 0 100 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Pontiac Icon"
    {...props}
  >
    <defs>
      <style>{"\r\n      .b{fill:#78819b}\r\n    "}</style>
    </defs>
    <path
      d="M66.39.29c-.38-.31-.89-.37-1.34-.18L50.01 7 34.95.11c-.45-.19-.96-.13-1.33.18-.37.31-.52.81-.4 1.28L48.8 61.06c.15.56.64.94 1.22.94s1.07-.39 1.21-.94L66.79 1.57c.12-.47-.04-.97-.4-1.28ZM50.01 60.74 34.42 1.25 50 8.38l15.57-7.13L50 60.74Z"
      className="b"
    />
    <path
      d="M49.49 9.52 36.31 3.49 50 55.78 63.68 3.5 50.52 9.52c-.32.15-.71.15-1.04 0Zm1.54 6.87 7.57 1.02-7.57 1.03-1.02 4.76-1.03-4.76-7.56-1.03 7.56-1.02 1.03-4.92 1.02 4.92Z"
      className="b"
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsPontiacIcon);
export default ForwardRef;
