import { SVGProps, Ref, forwardRef } from "react";

const AppPaypalIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="App Paypal Icon"
    {...props}
  >
    <path d="M16.6 5.2c.9 1.1.6 3.1.6 3.2-.7 3.3-2.9 4.5-5.8 4.5H9.9c-.3 0-.6.3-.7.6l-.7 4.7H5.4c-.2 0-.4-.2-.4-.5L7.1 4.6c.1-.3.3-.6.7-.6h5c1.7 0 3 .4 3.8 1.2Zm1.5 3.6c.5.7.6 1.7.3 2.8-.5 3-2.5 4-5 4 0 0-.9-.1-1 .5v.2l-.5 3.2c0 .3-.3.5-.6.5h-2l.9-6.1h1.2c3.7 0 5.9-1.8 6.7-5.1Z" />
  </svg>
);

const ForwardRef = forwardRef(AppPaypalIcon);
export default ForwardRef;
