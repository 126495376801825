import { SVGProps, Ref, forwardRef } from "react";

const FilterIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Filter Icon"
    {...props}
  >
    <path
      d="M10.5,20.5l3.2-1.2c0.1,0,0.2-0.1,0.2-0.3v-5.8c0-0.4,0.1-0.9,0.4-1.2l5.7-8c0.1-0.2,0-0.5-0.2-0.5H4.3C4,3.5,3.9,3.7,4,3.9
	l5.7,8c0.3,0.4,0.4,0.8,0.4,1.2v7C10.1,20.4,10.3,20.6,10.5,20.5z"
    />
  </svg>
);

const ForwardRef = forwardRef(FilterIcon);
export default ForwardRef;
