import { SVGProps, Ref, forwardRef } from "react";

const BrandsFiatIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 100 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Fiat Icon"
    {...props}
  >
    <path
      d="M36.7 9.9h6.1v42.2h-6.1V9.9zm26 0V15h4.7v37.1h5.9V15h4.6V9.9H62.7zM22.2 52.1H28V33h5v-5h-5V15h6.5V9.9H22.2v42.2zm41.3-2.8v2.8H58v-9.6c0-3.2-.1-6.4-.3-9.5h-6.4v19.1H46V9.9h12.4c.7 1.5 1.4 3 1.9 4.5.5 1.6 1 3.2 1.3 4.9.4 1.7.6 3.4.9 5.2.2 1.8.4 3.6.5 5.4.1 1.8.2 3.7.3 5.5.1 1.8.1 3.7.1 5.6 0 1.9 0 3.7.1 5.6v2.7zm-6.2-21.4c-.5-4.8-1.3-9.3-2.7-12.9h-3.4v12.9h6.1z"
      style={{
        fill: "#78819b",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsFiatIcon);
export default ForwardRef;
