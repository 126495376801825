import { SVGProps, Ref, forwardRef } from "react";

const AppMicrosoftIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="App Microsoft Icon"
    {...props}
  >
    <path d="M4 4h8v8H4V4Zm0 9h8v8H4v-8Zm9-9h8v8h-8V4Zm0 9h8v8h-8v-8Z" />
  </svg>
);

const ForwardRef = forwardRef(AppMicrosoftIcon);
export default ForwardRef;
