import { SVGProps, Ref, forwardRef } from "react";

const CogIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 20 20"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Cog Icon"
    {...props}
  >
    <path d="m.344 13.271 2 3.46a1 1 0 0 0 1.366.365l1.396-.806c.58.457 1.221.832 1.895 1.112V19a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-1.598a8.093 8.093 0 0 0 1.895-1.112l1.396.806c.477.275 1.091.11 1.366-.365l2-3.46a1.004 1.004 0 0 0-.365-1.366l-1.372-.793a7.684 7.684 0 0 0-.002-2.224l1.372-.793c.476-.275.641-.89.365-1.366l-2-3.46a1 1 0 0 0-1.366-.365l-1.396.806A8.036 8.036 0 0 0 13 2.598V1a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v1.598A8.094 8.094 0 0 0 5.105 3.71L3.71 2.904a1 1 0 0 0-1.366.365l-2 3.46a1.004 1.004 0 0 0 .365 1.366l1.372.793a7.683 7.683 0 0 0 0 2.224l-1.372.793c-.476.275-.641.89-.365 1.366ZM10 6c2.206 0 4 1.794 4 4s-1.794 4-4 4-4-1.794-4-4 1.794-4 4-4Z" />
  </svg>
);

const ForwardRef = forwardRef(CogIcon);
export default ForwardRef;
