import { SVGProps, Ref, forwardRef } from "react";

const LinkChainIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    x={0}
    y={0}
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Link Chain Icon"
    {...props}
  >
    <style>
      {
        "\r\n    .st0{fill:none;stroke:currentColor;stroke-width:2;stroke-linecap:round;stroke-linejoin:round}\r\n  "
      }
    </style>
    <path
      d="M13.5 10.5c-.8-.8-1.9-1.3-3.1-1.3-1.2 0-2.3.5-3.1 1.3l-3.1 3.1c-.7.8-1.2 1.9-1.2 3 0 1.2.5 2.3 1.3 3.1.8.8 1.9 1.3 3.1 1.3 1.2 0 2.3-.5 3.1-1.3l1.5-1.5"
      className="st0"
    />
    <path
      d="M10.5 13.5c.8.8 1.9 1.3 3.1 1.3 1.2 0 2.3-.5 3.1-1.3l3.1-3.1c.7-.8 1.2-1.9 1.2-3s-.5-2.3-1.3-3.1c-.8-.8-1.9-1.3-3.1-1.3-1.2 0-2.3.5-3.1 1.3L12 5.8"
      className="st0"
    />
  </svg>
);

const ForwardRef = forwardRef(LinkChainIcon);
export default ForwardRef;
