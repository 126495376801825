import { SVGProps, Ref, forwardRef } from "react";

const ComponentTurbochargerIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Component Turbocharger Icon"
    {...props}
  >
    <title id="Component Turbocharger Icon">Component Turbocharger Icon</title>
    <path
      fillRule="evenodd"
      d="M6.111 7.222a1.111 1.111 0 1 0 0-2.222 1.111 1.111 0 0 0 0 2.222Zm1.111 10a1.111 1.111 0 1 1-2.222 0 1.111 1.111 0 0 1 2.222 0Zm11.112 0a1.111 1.111 0 1 1-2.223 0 1.111 1.111 0 0 1 2.223 0Zm-7.499-6.944c0-.75.36-1.39.833-1.39v1.743c.094-.185.227-.37.393-.536.53-.53 1.236-.727 1.571-.392L12.4 10.935a2.13 2.13 0 0 1 .657-.102c.75 0 1.389.36 1.389.834H12.7c.185.094.37.226.537.393.53.53.728 1.236.393 1.57L12.4 12.4c.063.197.1.421.1.656 0 .75-.36 1.389-.833 1.389v-1.743a2.13 2.13 0 0 1-.393.536c-.53.53-1.236.728-1.571.393l1.232-1.233a2.13 2.13 0 0 1-.658.102c-.75 0-1.388-.36-1.388-.833h1.742a2.127 2.127 0 0 1-.536-.393c-.53-.53-.728-1.236-.393-1.572l1.234 1.234a2.13 2.13 0 0 1-.102-.658Zm.831 1.851a.463.463 0 1 0 0-.926.463.463 0 0 0 0 .926ZM18.334 5h-1.111v.556H11.11v.025a6.112 6.112 0 1 0 5.678 2.752h.434v.556h1.11V5Zm-6.667 10a3.333 3.333 0 1 0 0-6.666 3.333 3.333 0 0 0 0 6.666Z"
      clipRule="evenodd"
    />
  </svg>
);

const ForwardRef = forwardRef(ComponentTurbochargerIcon);
export default ForwardRef;
