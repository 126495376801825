import { SVGProps, Ref, forwardRef } from "react";

const BluetoothIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Cooled Icon"
    {...props}
  >
    <path
      d="M11,22v-7.6L6.4,19L5,17.6l5.6-5.6L5,6.4L6.4,5L11,9.6V2h1l5.7,5.7L13.4,12l4.3,4.3L12,22H11z M13,9.6l1.9-1.9L13,5.8V9.6z
	 M13,18.1l1.9-1.9L13,14.4V18.1z M5,13.5c-0.4,0-0.8-0.1-1.1-0.4S3.5,12.4,3.5,12c0-0.4,0.1-0.8,0.4-1.1s0.6-0.4,1.1-0.4
	c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.4,0.6,0.4,1.1c0,0.4-0.1,0.8-0.4,1.1C5.8,13.4,5.4,13.5,5,13.5z M19,13.5c-0.4,0-0.8-0.1-1.1-0.4
	s-0.4-0.6-0.4-1.1c0-0.4,0.1-0.8,0.4-1.1s0.6-0.4,1.1-0.4c0.4,0,0.8,0.1,1.1,0.4s0.4,0.6,0.4,1.1c0,0.4-0.1,0.8-0.4,1.1
	S19.4,13.5,19,13.5z"
    />
  </svg>
);

const ForwardRef = forwardRef(BluetoothIcon);
export default ForwardRef;
