import { SVGProps, Ref, forwardRef } from "react";

const ListIconIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="List Icon"
    {...props}
  >
    <circle cx={4.6} cy={6.5} r={1.9} />
    <circle cx={4.6} cy={12} r={1.9} />
    <circle cx={4.6} cy={17.5} r={1.9} />
    <path d="M8.4 5.5h12.9v1.9H8.4zm0 5.6h12.9V13H8.4zm0 5.5h12.9v1.9H8.4z" />
  </svg>
);

const ForwardRef = forwardRef(ListIconIcon);
export default ForwardRef;
