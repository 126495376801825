import { SVGProps, Ref, forwardRef } from "react";

const BrandsVolvoIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 140 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Volvo Icon"
    {...props}
  >
    <path
      d="m2.4 27.4 4.8 8.7h5.6l4.7-8.7h2.4v-1.6h-7.2v1.6h2.1l-3.4 6.3-3.5-6.3h2.5v-1.6H0v1.6zm61.4 0v-1.6h9.6v1.6H71v7.2h2.2c2 0 3.4-1.3 3.4-3.2h1.6v4.8H63.7v-1.6h2.4v-7.2h-2.3zm-23.2-1.8c-4.8 0-7.3 2.4-7.3 5.4s2.5 5.5 7.4 5.5S48 34 48 31c0-3.1-2.6-5.4-7.4-5.4zm0 9.2c-1.8 0-2.5-2-2.5-3.9 0-2 1-3.7 2.6-3.7s2.5 1.8 2.5 3.8c-.1 1.9-.9 3.8-2.6 3.8zm53.8-7.4 4.8 8.7h5.6l4.7-8.7h2.4v-1.6h-7.1v1.6h2l-3.4 6.3-3.5-6.3h2.5v-1.6H92v1.6zm38.2-1.8c-4.8 0-7.4 2.4-7.4 5.4s2.5 5.5 7.4 5.5 7.4-2.4 7.4-5.5-2.6-5.4-7.4-5.4zm0 9.2c-1.8 0-2.5-2-2.5-3.9 0-2 1-3.7 2.6-3.7s2.5 1.8 2.5 3.8c-.1 1.9-.8 3.8-2.6 3.8z"
      style={{
        fill: "#78819b",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsVolvoIcon);
export default ForwardRef;
