import { SVGProps, Ref, forwardRef } from "react";

const FormatColorTextIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Format Color Text Icon"
    {...props}
  >
    <path d="M0 20h24v4H0v-4ZM11 3 5.5 17h2.25l1.12-3h6.25l1.12 3h2.25L13 3h-2Zm-1.38 9L12 5.67 14.38 12H9.62Z" />
  </svg>
);

const ForwardRef = forwardRef(FormatColorTextIcon);
export default ForwardRef;
