import { SVGProps, Ref, forwardRef } from "react";

const ComponentSealsAndGasketsIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Component Seals And Gaskets Icon"
    {...props}
  >
    <title id="Component Seals And Gaskets Icon">
      Component Seals And Gaskets Icon
    </title>
    <path
      fillRule="evenodd"
      d="M8.127 4.683a2.333 2.333 0 0 0-3.3 0l-.144.144a2.333 2.333 0 0 0 0 3.3l.144.143a2.326 2.326 0 0 0 1.685.683v6.178a2.326 2.326 0 0 0-1.685.683l-.144.143a2.333 2.333 0 0 0 0 3.3l.144.144a2.333 2.333 0 0 0 3.3 0l.143-.144c.447-.447.675-1.03.683-1.615h6.178a2.33 2.33 0 0 0 .683 1.615l.144.144a2.333 2.333 0 0 0 3.3 0l.143-.144a2.333 2.333 0 0 0 0-3.3l-.143-.143a2.326 2.326 0 0 0-1.616-.683V8.953a2.326 2.326 0 0 0 1.616-.683l.143-.143a2.333 2.333 0 0 0 0-3.3l-.143-.144a2.333 2.333 0 0 0-3.3 0l-.144.144a2.327 2.327 0 0 0-.683 1.685H8.953a2.326 2.326 0 0 0-.683-1.685l-.143-.144Zm-1.615 3.22a1.391 1.391 0 1 0 0-2.782 1.391 1.391 0 0 0 0 2.782Zm1.392 9.74a1.391 1.391 0 1 1-2.783 0 1.391 1.391 0 0 1 2.783 0Zm4.173-2.088a3.478 3.478 0 1 0 0-6.956 3.478 3.478 0 0 0 0 6.956Zm6.957-9.043a1.391 1.391 0 1 1-2.783 0 1.391 1.391 0 0 1 2.783 0Zm-1.392 12.522a1.391 1.391 0 1 0 0-2.783 1.391 1.391 0 0 0 0 2.783Z"
      clipRule="evenodd"
    />
  </svg>
);

const ForwardRef = forwardRef(ComponentSealsAndGasketsIcon);
export default ForwardRef;
