import { SVGProps, Ref, forwardRef } from "react";

const GaugeVariantTwoIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 65 66"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Gauge Variant Two Icon"
    {...props}
  >
    <title id="Gauge Variant Two Icon">Gauge Variant Two Icon</title>
    <path d="M32.499 10.52c-14.625 0-26.542 11.917-26.542 26.542 0 7.313 2.98 13.813 7.583 18.417l5.146-5.146c-3.25-3.52-5.416-8.125-5.416-13.27 0-10.563 8.666-19.23 19.229-19.23 10.562 0 19.229 8.667 19.229 19.23 0 5.145-2.167 9.75-5.417 13.27l5.146 5.146c4.604-4.875 7.583-11.375 7.583-18.417 0-14.625-11.916-26.541-26.541-26.541Z" />
    <path d="m21.972 27.453 5.833 7.594c-.374.77-.748 1.54-.854 2.346-.423 3.223 1.91 6.26 5.133 6.683 3.222.422 6.26-1.91 6.682-5.133.423-3.222-1.91-6.26-5.133-6.683-.805-.105-1.914.023-2.487.22l-5.833-7.593-3.34 2.566Z" />
  </svg>
);

const ForwardRef = forwardRef(GaugeVariantTwoIcon);
export default ForwardRef;
