import { SVGProps, Ref, forwardRef } from "react";

const FormatAlignJustifyIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Format Align Justify Icon"
    {...props}
  >
    <path d="M3 21h18v-2H3v2Zm0-4h18v-2H3v2Zm0-4h18v-2H3v2Zm0-4h18V7H3v2Zm0-6v2h18V3H3Z" />
  </svg>
);

const ForwardRef = forwardRef(FormatAlignJustifyIcon);
export default ForwardRef;
