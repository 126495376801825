import { SVGProps, Ref, forwardRef } from "react";

const BrandsLincolnIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 100 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Lincoln Icon"
    {...props}
  >
    <path
      d="M45.11 54.34c-1.08-7.59-1.36-17.12-1.39-22.08 1.57.16 3.18.3 4.57.37.03 9.17.37 16.61.69 21.71h2.03c.32-5.1.65-12.54.69-21.71 1.39-.07 3-.21 4.57-.37-.03 4.96-.31 14.48-1.39 22.08H45.1Zm0-46.69h3.87c-.32 5.1-.65 12.54-.69 21.71-1.4.07-3.01.21-4.57.37.03-4.95.3-14.48 1.39-22.08Zm9.78 0c1.08 7.59 1.36 17.12 1.39 22.08-1.57-.16-3.18-.3-4.57-.37-.04-9.17-.37-16.61-.69-21.71h3.87Zm3.56 22.31c-.03-7.8-.48-16.6-1.71-24.71 0 0-2.57-.3-5.9-.35-.23-3.18-.42-4.91-.42-4.91h-.83s-.19 1.74-.42 4.91c-3.34.05-5.9.35-5.9.35-1.24 8.1-1.68 16.91-1.71 24.71-2.74.32-4.93.63-4.93.63v.8s2.19.31 4.93.63c.04 7.8.48 16.6 1.71 24.7 0 0 2.57.31 5.9.36.23 3.17.42 4.91.42 4.91h.83s.18-1.74.42-4.91c3.33-.05 5.9-.36 5.9-.36 1.24-8.1 1.68-16.9 1.71-24.7 2.75-.32 4.93-.63 4.93-.63v-.8s-2.19-.31-4.93-.63Z"
      style={{
        fill: "#78819b",
        fillRule: "evenodd",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsLincolnIcon);
export default ForwardRef;
