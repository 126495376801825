import { SVGProps, Ref, forwardRef } from "react";

const BrandsVolkswagenIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="a"
    x={0}
    y={0}
    aria-hidden="true"
    viewBox="0 0 100 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Volkswagen Icon"
    {...props}
  >
    <style>
      {"\r\n    .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#78819b}\r\n  "}
    </style>
    <path
      d="M50 5.4C35.9 5.4 24.4 16.9 24.4 31S35.9 56.6 50 56.6 75.6 45.1 75.6 31 64.1 5.4 50 5.4zm0 48.9c-12.9 0-23.3-10.4-23.3-23.3S37.1 7.7 50 7.7 73.3 18.1 73.3 31 62.9 54.3 50 54.3z"
      className="st0"
    />
    <path
      d="M44.9 13.6c1.6-.5 3.3-.7 5.1-.7s3.5.3 5.1.7L50 24.9l-5.1-11.3z"
      className="st0"
    />
    <path
      d="M59.7 15.7c1.9 1.2 3.6 2.8 4.9 4.6l-8.4 18.5-3.2-7.2h-6.1l-3.2 7.2-8.3-18.5c1.3-1.8 3-3.4 4.9-4.6L47 30.5h6.1l6.6-14.8zM32.5 26.3c-.4 1.5-.6 3.1-.6 4.7 0 7.1 4.1 13.3 10 16.2l-9.4-20.9z"
      className="st0"
    />
    <path
      d="M45 48.4c1.6.5 3.3.7 5 .7s3.4-.2 5-.7l-5-11.1-5 11.1zm13.1-1.2c6-3 10-9.1 10-16.2 0-1.6-.2-3.2-.6-4.7l-9.4 20.9z"
      className="st0"
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsVolkswagenIcon);
export default ForwardRef;
