import { SVGProps, Ref, forwardRef } from "react";

const VehicleCoupeIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 61 21"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Vehicle Coupe Icon"
    {...props}
  >
    <path
      d="M55.7 17.6c.8-.1 1.7-.1 2.5-.2.6-.1 1.1-.2 1.4-.4.7-.6.6-2.5.6-3.5 0-.2-.6-.2-.6-.5-.1-1.5-.2-2.8-.4-4.3-.1-.6-.6-1.2-1.5-1.4-2.6-.8-6.4-1.3-7.6-1.6-.9-.3-1.3-.6-1.8-.8-1.7-1.1-3.9-2.2-5.1-2.7-1.1-.5-2-1.1-3.5-1.1-4.1 0-8.7-.3-12.5.9-3.6 1-6.9 3-10.3 4.6-4.2.4-8.8 1.2-13 2.2-1.4.3-2 .9-2.4 1.7-.5.9-.7 1.7-.9 2.2-.3 0-.3.1-.4.3-.1.2-.1.9-.1 1.2 0 .4 0 1 .1 1.4.1.8.2 1.3.6 1.6.2.2.6.3 1 .3h1.1c1.3-.2 1.2.1 1.1-1.3v-.6c0-3.2 2.6-5.8 5.9-5.8 3.3 0 5.9 2.6 5.9 5.8 0 .5 0 1.1-.1 1.6-.2 1.2-.4 1 .9 1.1h25.1c1.3-.2 1.1.1 1-1.4 0-.4-.1-.8-.1-1.2 0-3.2 2.6-5.8 5.9-5.8 3.3 0 5.9 2.6 5.9 5.8v.9c.1 1.2.3 1.1 1.3 1zm-19-10.7c.3-2.5.5-3.5.6-4.2.1-.3.1-.5.5-.5h.9c.6 0 3.8 2 5 3.3.7.7 1 1.8-.6 1.8l-5.6.3c-.9 0-.9.1-.8-.7zM23.2 8.1c-.1 0 .2-.5.2-1 0-.8-1.4-.8-2.3-.8 1.7-1.3 4.3-2.6 6.6-3.4.8-.3 3-.6 4.5-.6l3.2-.1c.3 0 .3.1.3.4-.2 1-.5 2.9-.7 4.2-.1.6-.3.8-.9.8l-10.9.5zm25.4 3.1c2.5 0 4.5 2 4.5 4.4s-2 4.4-4.5 4.4-4.5-2-4.5-4.4 2-4.4 4.5-4.4zm-38.6 0c2.5 0 4.5 2 4.5 4.4S12.5 20 10 20s-4.5-2-4.5-4.4 2-4.4 4.5-4.4z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(VehicleCoupeIcon);
export default ForwardRef;
