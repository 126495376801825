import { SVGProps, Ref, forwardRef } from "react";

const DeductionsIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    x={0}
    y={0}
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    aria-label="Deductions Icon"
    ref={ref}
    {...props}
  >
    <title id="Deductions Icon">Deductions Icon</title>
    <path d="M23 .5A2.5 2.5 0 0 1 25.5 3v15c0 1.387-1.113 2.5-2.5 2.5H5.5l-5 5V3A2.5 2.5 0 0 1 3 .5h20ZM8 9.25v2.5h10v-2.5H8Z" />
  </svg>
);

const ForwardRef = forwardRef(DeductionsIcon);
export default ForwardRef;
