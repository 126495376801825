import { SVGProps, Ref, forwardRef } from "react";

const Camera2Icon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    // style={{
    //   enableBackground: "new 0 0 24 24",
    // }}
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Camera2 Icon"
    {...props}
  >
    <path
      d="M22 6v13H2V7h2V6h2v1h1V6h2v1h2V6h11z"
      className="st0 stshockcolor"
      data-st="fill:#424242;;"
      style={{
        fill: "#424242",
      }}
    />
    <path
      d="M9.706 18c.77.616 1.733 1 2.794 1H2v-1h7.706zm2.794 1H22v-1h-6.706c-.77.616-1.733 1-2.794 1zM22 6v4h-5v4.5c0-2.481-2.019-4.5-4.5-4.5S8 12.019 8 14.5V10H2V7h9V6h11z"
      className="st1"
      style={{
        opacity: 0.4,
        fill: "#fff",
      }}
    />
    <path
      d="M12.5 19C10.019 19 8 16.981 8 14.5s2.019-4.5 4.5-4.5 4.5 2.019 4.5 4.5-2.019 4.5-4.5 4.5zm0-7c-1.378 0-2.5 1.121-2.5 2.5s1.122 2.5 2.5 2.5c1.379 0 2.5-1.121 2.5-2.5S13.879 12 12.5 12z"
      className="st2"
      style={{
        opacity: 0.6,
        fill: "#fff",
      }}
    />
    <path
      d="M15 14.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0zM17 9h3V7h-3v2z"
      className="st3"
      style={{
        fill: "#b2ebf2",
      }}
    />
    <path
      d="M14.268 12.732a2.5 2.5 0 1 1-3.536 3.536l3.536-3.536zM17 9h3V7l-3 2z"
      className="st2"
      style={{
        opacity: 0.6,
        fill: "#fff",
      }}
    />
    <path
      d="M6 7H4V6h2v1zm3-1H7v1h2V6z"
      className="st4"
      style={{
        opacity: 0.2,
        fill: "#fff",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(Camera2Icon);
export default ForwardRef;
