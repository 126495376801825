import { SVGProps, Ref, forwardRef } from "react";

const DocumentReportIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Document Report Icon"
    {...props}
  >
    <path
      d="M6.9 1.8c-.7 0-1.3.3-1.8.7-.5.5-.7 1.2-.7 1.9v15.3c0 .7.3 1.3.7 1.8.5.5 1.1.7 1.8.7h10.2c.7 0 1.3-.3 1.8-.7.5-.5.7-1.1.7-1.8v-11c0-.7-.3-1.3-.7-1.8l-4.3-4.3c-.5-.5-1.1-.7-1.8-.7H6.9zm2.6 12.7c0-.3-.1-.7-.4-.9-.2-.2-.6-.4-.9-.4-.3 0-.7.1-.9.4-.2.2-.4.6-.4.9v3.8c0 .3.1.7.4.9.2.2.6.4.9.4.3 0 .7-.1.9-.4.2-.2.4-.6.4-.9v-3.8zm2.5-3.8c.3 0 .7.1.9.4.2.2.4.6.4.9v6.4c0 .3-.1.7-.4.9-.2.2-.6.4-.9.4-.3 0-.7-.1-.9-.4-.2-.2-.4-.6-.4-.9V12c0-.3.1-.7.4-.9.2-.2.6-.4.9-.4zm5.1-1.2c0-.3-.1-.7-.4-.9-.2-.2-.6-.4-.9-.4-.3 0-.7.1-.9.4-.2.2-.4.6-.4.9v8.9c0 .3.1.7.4.9.2.2.6.4.9.4.3 0 .7-.1.9-.4.2-.2.4-.6.4-.9V9.5z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(DocumentReportIcon);
export default ForwardRef;
