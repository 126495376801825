import { SVGProps, Ref, forwardRef } from "react";

const ThirdRowIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Third Row Icon"
    {...props}
  >
    <path d="M4 3.5c1.1 3.3 4.3 5.7 8 5.7s6.8-2.4 8-5.7H4zm0 5.7c1.1 3.3 4.3 5.7 8 5.7s6.8-2.4 8-5.7H4z" />
    <path d="M4 14.8c1.1 3.3 4.3 5.7 8 5.7s6.8-2.4 8-5.7H4z" />
  </svg>
);

const ForwardRef = forwardRef(ThirdRowIcon);
export default ForwardRef;
