import { SVGProps, Ref, forwardRef } from "react";

const BrandsHondaIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 100 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Honda Icon"
    {...props}
  >
    <path
      d="M74.5 22.7c-.2-2.8-.6-6-2.7-8.1-1.9-1.9-4.7-2.5-7.3-2.8-4.8-.6-9.6-.8-14.5-.8s-9.7.2-14.6.8c-2.6.3-5.4.9-7.3 2.8-2.1 2.1-2.5 5.2-2.7 8.1-.2 3.3 0 6.6.2 9.9.2 3.2.6 6.5 1.4 9.7.4 1.5.9 2.9 1.7 4.2.8 1.2 2 2.1 3.3 2.7 2.8 1.3 6 1.4 9 1.6 3 .2 6 .3 8.9.3 3 0 6-.1 8.9-.3 3-.2 6.2-.3 9-1.6 1.3-.6 2.5-1.5 3.3-2.7.9-1.3 1.3-2.7 1.7-4.2.8-3.2 1.2-6.4 1.4-9.7.4-3.3.5-6.6.3-9.9zm-13.8-8.4c-1 3.8-2.3 8.9-3.3 12.7-.9 3.1-1.9 7-5.6 7.4-.6.1-1.1.1-1.7.1-.6 0-1.2-.1-1.7-.1-3.9-.4-4.9-4.4-5.7-7.4-1.1-3.8-2.3-8.9-3.3-12.7V14c2.8-.2 6.3-.3 10.7-.3s7.9.1 10.7.3c-.1.1-.1.2-.1.3zm-9.8 33.3h-1.8c-2.3 0-4.3 0-6.2-.1.4-1.2 1.3-4.3 1.3-4.3.9-2.9 1-3.1 3.2-3.6.8-.1 1.6-.2 2.5-.3.9 0 1.7.1 2.5.3 2.3.5 2.4.6 3.2 3.6 0 0 1 3.1 1.3 4.3-1.7.1-3.7.1-6 .1zM29 26.8c0-7.4.5-10.7 6-12.1.2.4 0 1.1.1 1.5 0 3 .2 6.1.3 9.2.4 7.3 1 14.5 1.9 21.7v.1c-3.2-.6-5.2-1.8-6.2-4.3-1.6-3.8-2.1-13.1-2.1-16.1zm33.7 20.3c.9-7.3 1.5-14.6 1.9-21.8.1-3 .3-6.1.3-9.2 0-.3-.1-1 .1-1.5 5.5 1.4 6 4.7 6 12.1 0 3-.5 12.3-2 16-1.1 2.7-3.1 3.9-6.3 4.4zm-31.6-2.3zm37.8 0z"
      style={{
        fill: "#78819b",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsHondaIcon);
export default ForwardRef;
