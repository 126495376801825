import { SVGProps, Ref, forwardRef } from "react";

const ChevronRightIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Chevron Right Icon"
    {...props}
  >
    <path d="m8.59 16.34 4.58-4.59-4.58-4.59L10 5.75l6 6-6 6-1.41-1.41Z" />
  </svg>
);

const ForwardRef = forwardRef(ChevronRightIcon);
export default ForwardRef;
