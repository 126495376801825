import { SVGProps, Ref, forwardRef } from "react";

const SortRightIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Sort Right Icon"
    {...props}
  >
    <path d="m10 17 5-5-5-5v10Z" />
  </svg>
);

const ForwardRef = forwardRef(SortRightIcon);
export default ForwardRef;
