import { SVGProps, Ref, forwardRef } from "react";

const PowerSeatIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Power Seat Icon"
    {...props}
  >
    <path d="m5.1 9.3 4.1-6h.1v.1L8.2 7.6v.1h3.5s.1 0 .1.1v.1l-4.1 6h-.1v-.1l1.2-4.1v-.1H5.2c-.1-.1-.1-.2-.1-.3 0 .1 0 0 0 0z" />
    <path d="M18.7 2.9c-2 0-3.7 1.5-3.9 3.5l-1.1 8.8H2.9V17c0 2.3 1.8 4.1 4.1 4.1h8c2.1 0 3.9-1.6 4.2-3.7l1.9-14.5h-2.4z" />
  </svg>
);

const ForwardRef = forwardRef(PowerSeatIcon);
export default ForwardRef;
