import { SVGProps, Ref, forwardRef } from "react";

const AppRedditIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="App Reddit Icon"
    {...props}
  >
    <path d="M20 10.6c0-1.1-.9-1.9-1.9-1.9-.5 0-.9.2-1.2.4-1.2-.7-2.7-1.2-4.3-1.3l.8-2.6 2.3.5c.1.8.8 1.5 1.6 1.5.9 0 1.6-.7 1.6-1.6 0-.9-.7-1.6-1.6-1.6-.6 0-1.2.4-1.4.9l-2.7-.7c-.2 0-.4.1-.5.3l-1 3.3c-1.7 0-3.3.5-4.6 1.2-.3-.2-.7-.4-1.2-.4-1 0-1.9.9-1.9 2 0 .7.3 1.2.8 1.6v.5c0 1.3.8 2.6 2.2 3.5 1.3.9 3.1 1.4 5 1.4s3.7-.5 5-1.4c1.4-.9 2.2-2.1 2.2-3.5v-.4c.4-.5.8-1.1.8-1.7ZM8.5 11.7c0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1-.6 0-1.1-.5-1.1-1.1Zm6.1 3.2c-.6.6-1.4.8-2.6.8-1.2 0-2.1-.3-2.6-.8-.2-.2-.2-.4 0-.6.2-.2.4-.2.6 0 .4.4 1 .6 2 .6s1.6-.2 2-.6c.2-.2.4-.2.6 0 .2.2.2.5 0 .6Zm-.2-2.1c-.6 0-1.1-.5-1.1-1.1 0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1Z" />
  </svg>
);

const ForwardRef = forwardRef(AppRedditIcon);
export default ForwardRef;
