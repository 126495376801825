import { SVGProps, Ref, forwardRef } from "react";

const TrendingDownIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Trending Down Icon"
    {...props}
  >
    <path d="m16 18 2.29-2.29-4.88-4.88-4 4L2 7.41 3.41 6l6 6 4-4 6.3 6.29L22 12v6h-6Z" />
  </svg>
);

const ForwardRef = forwardRef(TrendingDownIcon);
export default ForwardRef;
