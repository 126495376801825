import { SVGProps, Ref, forwardRef } from "react";

const TransUnionIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 34 34"
    width="1em"
    height="1em"
    aria-label="TransUnion Icon"
    ref={ref}
    {...props}
  >
    <title id="TransUnion Icon">TransUnion Icon</title>
    <g>
      <path
        d="M16.9,4.2c-4.8,0-9,2.6-11.2,6.5c-0.8,1.5-1,2.9-0.3,4c0.7,1.3,2.4,1.5,3.3,1.5h1.6l-0.6,1.8c-0.4,1.1-0.2,2.3,0.4,3.2
		c0.6,0.8,1.5,1.3,2.6,1.3c0.7,0,1.6-0.1,2.3-0.4c-0.2-0.3-0.4-0.6-0.5-1c-0.1-0.3-0.2-0.6-0.2-1c-0.4,0.2-1,0.3-1.5,0.3
		c-0.3,0-0.7-0.1-1-0.4c-0.2-0.3-0.3-0.7-0.1-1.3l0.8-2.5h2.6l0.7-2.1h-2.5l1.1-3.5h-2.2l-1.1,3.5H8.7c-0.7,0-1.2-0.2-1.4-0.6
		c-0.2-0.4,0-1.3,0.6-2.3v-0.1c2-2.9,5.2-4.8,9-4.8c6,0,10.8,4.8,10.8,10.8c0,6-4.9,10.8-10.8,10.8c-5.3,0-9.7-3.8-10.7-8.8
		l-2.1,0.4c1.2,6,6.4,10.5,12.8,10.5c7.1,0,13-5.8,13-12.9C29.9,9.9,24.1,4.1,16.9,4.2L16.9,4.2z"
      />
      <path
        d="M25.2,20.6c-0.5,0-1.2-0.1-1.6-0.4c-0.1-0.1-0.4-0.3-0.4-0.7c0-0.1,0-0.4,0.1-0.6l1.6-4.9h-2.2l-1.4,4.3
		c-0.4,1.1-1.6,2-2.6,2c-0.3,0-0.7-0.1-0.8-0.4c-0.1-0.2-0.3-0.5-0.1-1l1.6-4.9h-2.2l-1.4,4.3c-0.4,1-0.2,2.1,0.4,2.9
		c0.6,0.7,1.4,1.2,2.5,1.2c1,0,2.1-0.4,3-1.2c0.3,0.4,0.7,0.7,1.2,1c0.7,0.2,1.2,0.2,1.7,0.2c0.2,0,0.5-0.1,0.6-0.1
		c0.1-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.1-0.2,0.1-0.3
		C25.9,20.6,25.6,20.6,25.2,20.6L25.2,20.6z"
      />
    </g>
  </svg>
);

const ForwardRef = forwardRef(TransUnionIcon);
export default ForwardRef;
