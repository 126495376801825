import { SVGProps, Ref, forwardRef } from "react";

const BrandsMercedesBenzIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 100 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Mercedes Benz Icon"
    {...props}
  >
    <path
      d="M68.2 40.8 53.3 29.1l-2.6-18.7c3.4.1 6.7 1.1 9.7 2.8 3.1 1.8 5.7 4.4 7.6 7.6 1.8 3.1 2.8 6.7 2.8 10.3-.1 3.3-1 6.7-2.6 9.7m-7.9 8.1c-3.1 1.8-6.7 2.8-10.3 2.8-3.6 0-7.2-1-10.3-2.8-3-1.7-5.4-4.1-7.2-7L50 34.8l17.6 7.1c-1.8 2.9-4.3 5.3-7.3 7M29.4 31c0-3.6 1-7.2 2.8-10.3 1.8-3.1 4.4-5.8 7.6-7.6 3-1.7 6.3-2.6 9.7-2.8L46.9 29 31.8 40.8c-1.6-3-2.4-6.4-2.4-9.8m40.5-11.5c-2-3.5-4.9-6.4-8.4-8.4s-7.5-3-11.5-3-8 1.1-11.5 3.1-6.4 4.9-8.4 8.4-3 7.4-3 11.4 1.1 8 3.1 11.5 4.9 6.4 8.4 8.4C42 52.9 46 54 50 54s8-1.1 11.5-3.1 6.4-4.9 8.4-8.4S73 35.1 73 31s-1.1-8-3.1-11.5"
      style={{
        fill: "#78819b",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsMercedesBenzIcon);
export default ForwardRef;
