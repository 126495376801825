import { SVGProps, Ref, forwardRef } from "react";

const VehicleHatchbackIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 61 21"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Vehicle Hatchback Icon"
    {...props}
  >
    <path
      d="M58.2 18c.7-.1 1.2-.3 1.5-.5.7-.6.5-2.7.5-3.7 0-.3-.7-.3-.7-.5-.1-1.6-.8-4.5-1-6.2-.1-.6-.3-1.4-1.4-2-1.3-.8-4.8-2.8-6.2-3.4-.9-.4-.7-.5-1.6-.6-4.8-.7-15.3-1.5-20 0-4 1.2-7.3 3-10.8 5.1-6.2 1-11.2 1.9-14.2 2.7-1.5.4-2.1 1.1-2.6 1.9-.5.9-.7 1.5-.9 2-.5 0-.7.5-.7 1.2 0 1-.1 1.8.2 2.8.2.7.9 1.2 1.7 1.2h1.5c.7-.1 1-.1.9-1.1v-1c0-3.4 2.8-6.2 6.3-6.2s6.3 2.8 6.3 6.2v1.4c-.1 1.5-.6 1.4.9 1.4h25.3c1.1-.1 1.4 0 1.2-1.4 0-.4-.1-.9-.1-1.4 0-3.4 2.8-6.2 6.3-6.2s6.3 2.8 6.3 6.2v.9c0 1.2-.3 1.5 1.3 1.2zM38 6.7c.4-3.1.4-4 .7-4.8 0-.1.3-.4.5-.4 2.3.1 4.8.1 7 .6.8.2 2.4 2 2.8 2.6.2.2 1.3 1.5 1.1 1.8-.1.2-.3.3-.6.3-3.4.1-7.3.5-10.7.5-.8 0-.9.3-.8-.6zM24.9 7.8c0-.1.2-.6.2-1.1.1-.8-1.8-.9-2.7-.8 1.8-1.3 4.9-2.8 7.3-3.6 2-.7 4.7-.7 6.9-.7.4 0 .4.1.3.5-.2 1-.5 3.1-.7 4.5-.1.7-.3.8-1 .9-2.7-.1-10.3.4-10.3.3zm25.8 3.4c2.7 0 4.8 2.1 4.8 4.7s-2.2 4.7-4.8 4.7c-2.7 0-4.8-2.1-4.8-4.7s2.1-4.7 4.8-4.7zm-40 0c2.7 0 4.8 2.1 4.8 4.7s-2.2 4.7-4.8 4.7c-2.7 0-4.8-2.1-4.8-4.7s2.2-4.7 4.8-4.7z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(VehicleHatchbackIcon);
export default ForwardRef;
