import { SVGProps, Ref, forwardRef } from "react";

const BlindSpotIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Blind Spot Icon"
    {...props}
  >
    <path d="M20.6 20.1v-.2c0-4.7-3.8-8.6-8.6-8.6s-8.6 3.8-8.6 8.6v.2h17.2zM12 6.8c1.3 0 2.5.6 3.4 1.5l.9-.9c-1.1-1.2-2.7-1.9-4.3-1.9s-3.2.7-4.3 1.9l.9.9c.9-1 2.1-1.5 3.4-1.5zm0 2.8c.6 0 1 .3 1.4.7l.9-.9c-.6-.7-1.4-1-2.3-1-.9 0-1.7.4-2.3 1l.9.9c.4-.4.8-.7 1.4-.7z" />
    <path d="m17.3 6.4.9-.9c-1.6-1.7-3.9-2.6-6.2-2.6s-4.6.9-6.2 2.5l.9.9C8.1 5 10 4.1 12 4.1s3.9.9 5.3 2.3z" />
  </svg>
);

const ForwardRef = forwardRef(BlindSpotIcon);
export default ForwardRef;
