import { SVGProps, Ref, forwardRef } from "react";

const LoadingIconHalfIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 48 48"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Loading Icon Half Icon"
    {...props}
  >
    <g clipPath="url(#clip0_6246_135)">
      <path
        d="M24 0C10.7451 0 0 10.7451 0 24C0 26.0205 0.250971 27.9822 0.721146 29.857C0.745965 29.9558 0.771439 30.0544 0.797497 30.1528C1.26283 31.9123 1.92316 33.5922 2.75364 35.1687C2.85172 35.3549 2.95212 35.5396 3.0549 35.7229C3.13527 35.8662 3.21703 36.0086 3.30023 36.15C3.62185 36.6968 3.9643 37.2298 4.32688 37.7476C4.39064 37.8387 4.4549 37.9294 4.51988 38.0195C8.8779 44.0643 15.9789 48 24 48C37.2548 48 48 37.2548 48 24C48 10.7451 37.2548 0 24 0ZM38.9231 38.923C34.9369 42.9091 29.6372 45.1044 24 45.1044V2.89563C29.6372 2.89563 34.9369 5.09088 38.9231 9.07696C42.9091 13.063 45.1044 18.3628 45.1044 24C45.1044 29.6372 42.9091 34.937 38.9231 38.923Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6246_135">
        <rect width="48" height="48" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(LoadingIconHalfIcon);
export default ForwardRef;
