import { SVGProps, Ref, forwardRef } from "react";

const CooledIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Cooled Icon"
    {...props}
  >
    <path d="M12 22.1s.1 0 0 0c2.9 0 5.1-2.3 5.1-5s-2.3-5-5-5H12v10zM2 12s0 .1 0 0c0 2.9 2.3 5.1 5 5.1s5-2.3 5-5V12H2zM12 1.9s-.1 0 0 0c-2.9 0-5.1 2.3-5.1 5s2.3 5 5 5h.1v-10zM22 12s0-.1 0 0c0-2.9-2.3-5.1-5-5.1s-5 2.3-5 5v.1h10z" />
  </svg>
);

const ForwardRef = forwardRef(CooledIcon);
export default ForwardRef;
