import { SVGProps, Ref, forwardRef } from "react";

const LoadingIconOverlapIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 48 48"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-label="Loading Icon Burst Icon"
    {...props}
  >
    <g clipPath="url(#clip0_6246_127)">
      <path
        d="M9.69629 38.3557C12.9424 44.2397 18.1405 48.0523 23.9997 48.0523C29.859 48.0523 35.057 44.2397 38.3031 38.3557C34.3085 40.5595 29.3595 41.8638 23.9997 41.8638C18.6399 41.8638 13.6908 40.5595 9.69629 38.3557Z"
        fill="currentColor"
      />
      <path
        d="M38.3031 9.74886C35.057 3.86488 29.8589 0.0522766 23.9997 0.0522766C18.1405 0.0522766 12.9424 3.86488 9.69629 9.74886C13.6909 7.54512 18.6399 6.24074 23.9997 6.24074C29.3595 6.24074 34.3086 7.54512 38.3031 9.74886Z"
        fill="currentColor"
      />
      <path
        d="M9.69658 9.74887C3.81256 12.995 0 18.1931 0 24.0523C0 29.9115 3.81256 35.1096 9.69658 38.3557C7.4928 34.3611 6.18847 29.4121 6.18847 24.0523C6.18847 18.6925 7.49282 13.7434 9.69658 9.74887Z"
        fill="currentColor"
      />
      <path
        d="M48.0003 24.0523C48.0003 18.1931 44.1877 12.995 38.3037 9.74887C40.5075 13.7434 41.8118 18.6925 41.8118 24.0523C41.8118 29.4121 40.5075 34.3612 38.3037 38.3557C44.1877 35.1096 48.0003 29.9115 48.0003 24.0523Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6246_127">
        <rect width="48" height="48" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(LoadingIconOverlapIcon);
export default ForwardRef;
