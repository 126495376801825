import { SVGProps, Ref, forwardRef } from "react";

const LoadingIconWheelIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 48 48"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Loading Icon Wheel Icon"
    {...props}
  >
    <g clipPath="url(#clip0_6246_132)">
      <path
        d="M24 0C10.7452 0 0 10.7451 0 24C0 37.2549 10.7452 48 24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2549 0 24 0ZM24 5.79125C28.8637 5.79125 33.4364 7.68529 36.8756 11.1244C37.3278 11.5767 37.7526 12.0491 38.1507 12.5387L26.8957 19.0367L24 20.7085L21.1045 19.0368L9.84938 12.5387C10.2474 12.0491 10.6722 11.5767 11.1245 11.1244C14.5637 7.68529 19.1363 5.79125 24 5.79125ZM5.79125 24C5.79125 21.7635 6.19275 19.589 6.95979 17.5575L18.2088 24.0521L21.1044 25.7239V41.9799C17.341 41.382 13.8711 39.6222 11.1245 36.8755C7.68529 33.4364 5.79125 28.8637 5.79125 24ZM36.8755 36.8755C34.1289 39.6221 30.659 41.3819 26.8957 41.9798V25.7238L29.7912 24.0521L41.0402 17.5575C41.8073 19.589 42.2087 21.7635 42.2087 24C42.2087 28.8637 40.3147 33.4364 36.8755 36.8755Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6246_132">
        <rect width="48" height="48" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(LoadingIconWheelIcon);
export default ForwardRef;
