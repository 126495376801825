import { SVGProps, Ref, forwardRef } from "react";

const StackIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    x={0}
    y={0}
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Stack Icon"
    {...props}
  >
    <path d="M12 8.8c4.7 0 8.5-1.2 8.5-2.6S16.7 3.5 12 3.5 3.5 4.7 3.5 6.1 7.3 8.8 12 8.8z" />
    <path d="M12 10.2c-4.2 0-7.7-.9-8.5-2.2v2.5c.8 1.2 4.3 2.2 8.5 2.2s7.7-.9 8.5-2.2V8c-.8 1.2-4.3 2.2-8.5 2.2zm0 7.9c-4.2 0-7.7-.9-8.5-2.2v2.4c.8 1.2 4.3 2.2 8.5 2.2s7.7-.9 8.5-2.2v-2.4c-.8 1.2-4.3 2.2-8.5 2.2z" />
    <path d="M12 14.1c-4.2 0-7.7-.9-8.5-2.2v2.4c.8 1.2 4.3 2.2 8.5 2.2s7.7-.9 8.5-2.2V12c-.8 1.2-4.3 2.1-8.5 2.1z" />
  </svg>
);

const ForwardRef = forwardRef(StackIcon);
export default ForwardRef;
