import { SVGProps, Ref, forwardRef } from "react";

const FlagOffIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Flag Off Icon"
    {...props}
  >
    <path d="m14 6-1-2H5v17h2v-7h5l1 2h7V6h-6Zm4 8h-4l-1-2H7V6h5l1 2h5v6Z" />
  </svg>
);

const ForwardRef = forwardRef(FlagOffIcon);
export default ForwardRef;
