import { SVGProps, Ref, forwardRef } from "react";

const BrandsChryslerIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="a"
    x={0}
    y={0}
    aria-hidden="true"
    viewBox="0 0 140 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Chrysler Icon"
    {...props}
  >
    <style>{"\r\n    .st0{fill:#78819b}\r\n  "}</style>
    <path
      d="M65.9 37.2c.2-.1.3-.2.3-.3 0-.2-.1-.3-.5-.4-.2-.1-.5-.1-.9-.1h-1.1V38h.8v-1.2h.8c.2 0 .3.1.3.2s-.1.2-.3.2h-.6l.9.8h1l-.9-.6c0-.1 0-.1.2-.2zm3.1-.7-.7.6-.8-.7h-.9l1.1.9v.7h.8v-.7l1-.9-.5.1zm7 1.1h-1.7v-1.2h-.8V38H76v-.3zm-4.5-.6c-.3 0-.5-.1-.6-.1v-.1c0-.1.1-.1.6-.1s.8.1.9.1h.1l.1-.3c-.1 0-.6-.1-1.2-.1-.5 0-1.2.1-1.2.5s.4.4 1 .5c.4.1.7.1.8.1v.1c0 .1-.2.2-.6.2-.5 0-1-.1-1-.1H70v.2c.1 0 .5.1 1.2.1.8 0 1.5-.1 1.5-.5-.1-.2-.3-.4-1.2-.5zm-9.6-.5v.6h-1.3v-.6h-.8V38h.8v-.6h1.3v.6h.8v-1.5h-.8zm18.9 1.4v-1.2h.8c.2 0 .3.1.3.2s-.1.2-.3.2H81l.9.8h1l-.9-.6c.2 0 .2 0 .4-.1s.3-.2.3-.3c0-.2-.1-.3-.5-.4-.2-.1-.5-.1-1-.1H80V38l.8-.1zm-22-1.5c-.1 0-.5-.1-.9-.1-1.1 0-1.6.3-1.6.8s.5.8 1.6.8c.4 0 .9-.1.9-.1l.1-.3h-.1c-.1 0-.4.1-.8.1-.6 0-1-.1-1-.5s.4-.5 1-.5c.3 0 .7 0 .8.1h.1l-.1-.3zM45.3 18.5c.5 0 1-.1 1.8-.1s1.4 0 1.9.1c1 .2 1.4.5 1.4.9 0 .5-.5.9-1.5 1-.7.1-1.1.1-1.8.1h-1L50 24h4.3l-3.9-2.8c.8 0 1-.1 1.8-.3 1-.3 1.5-.9 1.5-1.5 0-.8-.5-1.5-2.4-1.9-1.1-.2-2.4-.3-4.2-.4-2.4 0-5 .1-5.2.1V24h3.4v-5.5zM60.9 24h3.4v-2.8l4.5-3.9h-2.3l-3.2 2.8-3.3-2.8h-3.9l4.8 4V24zm15.7-.9c-2.5 0-4.5-.6-4.8-.7 0 .1-.3 1.4-.3 1.4.4.1 2.3.5 5.3.5 3.3 0 6.4-.6 6.4-2.3 0-1.2-1.3-1.8-4.9-2.3-1.5-.2-3.1-.3-3.1-.9 0-.4.4-.6 2.6-.6s3.9.5 4.2.5l.3-1.3c-.4-.1-2.5-.4-5.3-.4-2.3 0-5.3.3-5.3 2.1 0 1.6 1.8 1.9 4.3 2.2 2.5.3 3.7.4 3.7 1-.2.5-.9.8-3.1.8zm-49.2-1.8h5.9V24h3.4v-6.7h-3.4V20h-5.9v-2.7H24V24h3.4v-2.7zm-12.6 3c1.8 0 3.8-.3 4.1-.3l.3-1.4c-.4 0-2 .3-3.7.3-2.6 0-4.4-.6-4.4-2.2s1.7-2.2 4.5-2.2c1.6 0 3.2.2 3.6.3l-.2-1.5c-.5-.1-2.2-.3-4-.3-4.8 0-7.2 1.4-7.2 3.7s2.3 3.6 7 3.6zm99.3-1.4h-7.9v-1.7h7.6V20h-7.6v-1.6h7.8v-1.1h-11.2V24h11.3zM99 22.8h-7.7v-5.5h-3.4V24H99z"
      className="st0"
    />
    <path
      d="M75.6 44.6c.7.3 1 .4 2.9.4 2.2 0 6.5-.6 6.6-.6 18.7-2.9 46.9-7.2 48.1-7.6 1.1-.4 2.5-1.1 4.5-2.3 2-1.2 2.3-1.9 2.4-2-.1-.1-.3-.1-.4-.2L87.9 32c-.9 0-2.3 0-2.7.1-.3.1-1.1.4-1.8.7-1.4.6-2.5 1-3.1 1.1-2.1.4-8.2.3-10.1.3h-.4c-1.8 0-8 .1-10.1-.3-.6-.1-1.7-.5-3.1-1.1-.7-.3-1.5-.6-1.8-.7-.4-.1-1.8-.1-2.6 0l-51.8.3c-.1 0-.3.1-.4.2.1.2.4.9 2.4 2 1.9 1.2 3.4 1.9 4.5 2.3 1.2.3 29.4 4.7 48.1 7.6 0 0 4.4.6 6.6.6 1.8 0 2.2-.2 2.9-.4.1 0 .2-.1.3-.1.9-.3 3.4-.6 5.1-.6h.6c1.7 0 4 .2 4.9.6 0-.1.1 0 .2 0zm18.6-7.3 1.3-1.1 1.8-.2.2-.2h-5.9s.2-.1-.1-.2c-.2-.1-3-.2-3-.2h8.2s.7-.5 1.4-.7c.7-.2 1.7-.2 1.7-.2L132 34s-4.4.5-6.9.7c-2.5.2-24.4 1.1-24.4 1.1s-1-.1-2 .3-5.2 3.5-8.3 4.8c-3.1 1.3-10.3 1.5-10.3 1.5s5-.8 7.8-1.7c2.8-.8 4.2-2 4.5-2.1.3-.1.4-.2 1.1-.3.7 0 .8-.3.8-.3h-4.8l-.2-.2c-.2-.2-1.5-.4-1.5-.4l6.4-.1zm-44.7 3.4c-3.1-1.3-7.4-4.4-8.3-4.8-1-.4-2-.3-2-.3s-21.9-.9-24.4-1.1-6.8-.6-6.8-.6l32.2.5s1 0 1.7.2 1.4.7 1.4.7h8.2s-2.8.1-3 .2c-.2.1-.1.2-.1.2h-5.9l.2.2 1.8.2 1.3 1.1h6.4s-1.2.2-1.5.4l-.2.2h-4.8s.2.3.8.3c.7 0 .8.2 1.1.3s1.7 1.3 4.5 2.1 7.8 1.7 7.8 1.7-7.2-.1-10.4-1.5zm20.7-1h-9.9c-3.8 0-4.7-.5-6.3-1.9-2.1-1.8-1.9-2.6-1.9-2.6h35.5s.3.8-1.9 2.6c-1.6 1.3-2.4 1.9-6.3 1.9h-9.2zM122 18.5c.5 0 1-.1 1.8-.1s1.4 0 1.9.1c1 .2 1.4.5 1.4.9 0 .5-.5.9-1.5 1-.7.1-1.1.1-1.8.1h-1l3.9 3.5h4.3l-3.9-2.8c.8 0 1-.1 1.8-.3 1-.3 1.5-.9 1.6-1.5 0-.8-.5-1.5-2.4-1.9-1.1-.2-2.4-.3-4.2-.4-2.4 0-5 .1-5.2.1V24h3.4v-5.5h-.1z"
      className="st0"
    />
    <path
      d="M79.2 37.7h-1.7v-.4h1.7V37h-1.7v-.3h1.7v-.3h-2.5V38h2.6v-.3h-.1z"
      className="st0"
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsChryslerIcon);
export default ForwardRef;
