import { SVGProps, Ref, forwardRef } from "react";

const TowHitchIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Tow Hitch Icon"
    {...props}
  >
    <path d="M11.5 12.1v2h1.2V12c4.2-.4 7.5-3.9 7.5-8.2v-.1H3.8v.1c0 4.5 3.4 8.1 7.7 8.3zm6.7 2.1H3.8v6h2v-4h12.4v4h2v-6h-.1z" />
  </svg>
);

const ForwardRef = forwardRef(TowHitchIcon);
export default ForwardRef;
