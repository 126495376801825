import { SVGProps, Ref, forwardRef } from "react";

const VehicleElectricIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 120 54"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Vehicle Electric Icon"
    {...props}
  >
    <title id="Vehicle Electric Icon">Vehicle Electric Icon</title>
    <path d="M93.521 33.317c-4.38 0-7.787 3.35-7.787 7.658 0 4.069 3.65 7.419 7.787 7.419 4.38 0 7.787-3.35 7.787-7.658 0-4.068-3.407-7.419-7.787-7.419ZM28.795 48.392c4.3 0 7.787-3.428 7.787-7.658s-3.487-7.658-7.787-7.658-7.787 3.429-7.787 7.658c0 4.23 3.486 7.658 7.787 7.658Z" />
    <path
      fillRule="evenodd"
      d="M108.242 34.564c.276.132.61.29.61.674 0 .351.006.757.012 1.194.034 2.42.082 5.835-.742 6.667-.486.327-1.216.655-2.433.982-2.041.55-2.028.178-1.971-1.502.011-.323.024-.695.024-1.118v-1.965c0-5.24-4.623-9.822-10.22-9.822s-10.22 4.582-10.22 9.822v2.948c.216 2.917-.146 2.976-1.432 3.185-.157.026-.33.054-.515.09H40.23c-1.037-.14-1.5-.16-1.67-.392-.23-.31.07-1.004.21-2.883v-2.948c0-4.937-4.078-9.822-9.675-9.822S18.33 34.256 18.33 39.496v2.292c0 1.903-.228 1.964-1.346 2.263l-.114.03h-2.433c-1.217 0-2.434-.982-2.677-2.62-.243-1.965-.243-3.603-.243-5.895 0-1.31 0-2.62.973-2.62.243-.983.73-2.293 1.46-4.258.73-1.638 1.703-3.275 4.137-3.93 4.867-1.966 13.14-3.93 23.604-6.223 5.597-4.586 11.194-8.516 17.52-11.136 7.544-3.275 24.578-1.31 32.365 0 1.002.193 1.246.385 1.575.645.23.181.5.395 1.102.665 2.19 1.31 8.03 5.895 9.977 7.533 1.703 1.637 2.19 3.275 2.19 4.585.121 1.801.426 4.258.73 6.714.304 2.457.608 4.913.73 6.714 0 .136.167.216.363.309ZM70.836 11.935c.123-.908.23-1.692.298-2.244.244-.655.244-.982-.243-.982-.76.073-1.569.13-2.404.188-2.909.205-6.143.432-8.79 1.45-3.893 1.965-9.003 4.912-11.924 7.86 1.46 0 4.38 0 4.38 1.965-.243.983-.486 1.965-.486 2.293 0 .159 2.874-.069 6.36-.345 3.685-.292 8.054-.638 10.43-.638 1.217-.327 1.46-.655 1.46-1.965.176-2.12.602-5.256.919-7.582Zm20.982 8.237c.487 0 .73-.327.974-.655.157-.424-.602-1.806-1.157-2.82-.304-.552-.547-.994-.547-1.11-.73-1.31-3.406-4.913-4.623-5.568-2.596-.932-5.437-1.035-8.174-1.135-1.113-.04-2.208-.08-3.263-.175-.487 0-.974.327-.974.655-.316 1.065-.427 2.268-.666 4.87-.129 1.4-.295 3.205-.55 5.61-.184 1.487-.09 1.474.6 1.38.22-.03.504-.07.86-.07 3.846 0 8.037-.309 12.099-.608a362.13 362.13 0 0 1 5.421-.374Zm-30.41 4.154-6.62 10.595c0 .177 0 .354.162.53h5.813v.177l-1.938 7.24v.177h.161l6.62-10.596v-.177c0-.176-.16-.176-.16-.176h-5.652v-.177l1.776-7.417v-.176h-.162Z"
      clipRule="evenodd"
    />
  </svg>
);

const ForwardRef = forwardRef(VehicleElectricIcon);
export default ForwardRef;
