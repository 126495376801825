import { SVGProps, Ref, forwardRef } from "react";

const AppBehanceIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="App Behance Icon"
    {...props}
  >
    <path d="M10.5 11.4c2 0 1.8 2.5 1.8 2.5 0 3-3.3 3.1-3.3 3.1H4V7h5c1.6 0 3 .7 3 2.5s-1.5 1.9-1.5 1.9ZM6.3 8.6v2.2H9c.5 0 .9-.2.9-1.2s-.7-1-.7-1H6.3ZM9 15c.4 0 1.2-.1 1.2-1.2 0-1.4-1-1.4-1-1.4H6.3V15H9Zm7.5-5.6c4 0 3.5 4.3 3.5 4.4h-5.1c0 1.8 1.7 1.7 1.7 1.7 1.7 0 1.6-1.1 1.6-1.1h1.7c0 2.8-3.4 2.6-3.4 2.6-4.1 0-3.8-3.8-3.8-3.8s0-3.8 3.8-3.8Zm1.6 3s0-1.6-1.6-1.5c-1.5 0-1.7 1.5-1.7 1.5h3.3ZM14 7h5v1h-5V7Z" />
  </svg>
);

const ForwardRef = forwardRef(AppBehanceIcon);
export default ForwardRef;
