import { SVGProps, Ref, forwardRef } from "react";

const NavigationIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Navigation Icon"
    {...props}
  >
    <path d="M12 2.5c-5.2 0-9.5 4.3-9.5 9.5s4.3 9.5 9.5 9.5 9.5-4.3 9.5-9.5-4.3-9.5-9.5-9.5zM13.4 18 11 13.4l-5.2-.7 9.8-5.9L13.4 18z" />
  </svg>
);

const ForwardRef = forwardRef(NavigationIcon);
export default ForwardRef;
