import { SVGProps, Ref, forwardRef } from "react";

const FormatAlignRightIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Format Align Right Icon"
    {...props}
  >
    <path d="M3 21h18v-2H3v2Zm6-4h12v-2H9v2Zm-6-4h18v-2H3v2Zm6-4h12V7H9v2ZM3 3v2h18V3H3Z" />
  </svg>
);

const ForwardRef = forwardRef(FormatAlignRightIcon);
export default ForwardRef;
