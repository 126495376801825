import { SVGProps, Ref, forwardRef } from "react";

const BrandsInfinitiIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 100 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Infiniti Icon"
    {...props}
  >
    <path
      d="M66.1 39.3C64.5 37.2 50 17.7 50 17.7S35.4 37.4 34 39.3c-5.4-.5-14.8-4-14.8-10.3 0-7.9 13.6-15.2 30.7-15.2 18 0 30.7 7.3 30.7 15.2.1 6.2-9.2 9.7-14.5 10.3M50 11.5c-22.1 0-37.5 8.6-37.5 19.8 0 5.2 3.4 11.1 10.6 14.7 6.6 3.3 16 4.4 17.3 4.6L50 22.1l9.7 28.5c1.4-.2 10.6-1.3 17.3-4.6 7.2-3.6 10.6-9.5 10.6-14.7-.1-11.2-15.5-19.8-37.6-19.8"
      style={{
        fill: "#78819b",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsInfinitiIcon);
export default ForwardRef;
