import { SVGProps, Ref, forwardRef } from "react";

const ComponentAirConditioningIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Component Air Conditioning Icon"
    {...props}
  >
    <title id="Component Air Conditioning Icon">
      Component Air Conditioning Icon
    </title>
    <path
      fillRule="evenodd"
      d="M12 4a.667.667 0 0 0-.667.667v.39l-.586-.586a.667.667 0 1 0-.943.943l1.463 1.463a.667.667 0 0 0 .066.245v3.269L9.321 8.379a.664.664 0 0 0-.15-.31v-2.07a.667.667 0 0 0-1.333 0v1.775a.672.672 0 0 0-.064.063H6A.667.667 0 1 0 6 9.17h2.068a.664.664 0 0 0 .31.151l2.012 2.012H7.123a.666.666 0 0 0-.247-.067L5.414 9.805a.667.667 0 1 0-.943.943l.586.585h-.39A.667.667 0 0 0 4 12h16a.667.667 0 0 0-.667-.667h-.618l.585-.585a.667.667 0 1 0-.942-.943l-1.462 1.461a.665.665 0 0 0-.248.067H13.94l2.012-2.011a.664.664 0 0 0 .311-.152h2.067a.667.667 0 1 0 0-1.333h-1.774a.65.65 0 0 0-.063-.062V6a.667.667 0 0 0-1.333 0v2.067a.664.664 0 0 0-.151.312l-2.343 2.342V7.264a.664.664 0 0 0 .16-.387l1.463-1.463a.667.667 0 0 0-.943-.943l-.68.68v-.484A.667.667 0 0 0 12 4Zm-.666 14.849v.484a.667.667 0 1 0 1.333 0v-.39l.586.585a.667.667 0 1 0 .943-.942l-1.463-1.463a.667.667 0 0 0-.066-.245v-3.269l2.012 2.012a.664.664 0 0 0 .15.31v2.07a.667.667 0 0 0 1.333 0v-1.775a.647.647 0 0 0 .064-.063H18a.667.667 0 1 0 0-1.334h-2.068a.664.664 0 0 0-.31-.15l-2.012-2.012h3.267c.078.038.162.06.247.067l1.462 1.461a.667.667 0 1 0 .943-.943l-.586-.585h.39A.667.667 0 0 0 20 12H4c0 .368.298.667.667.667h.618l-.586.585a.667.667 0 1 0 .943.943l1.462-1.461a.666.666 0 0 0 .248-.067h2.708l-2.012 2.011a.665.665 0 0 0-.311.151H5.67a.667.667 0 0 0 0 1.334h1.774a.637.637 0 0 0 .063.062V18a.667.667 0 1 0 1.333 0v-2.067a.665.665 0 0 0 .151-.312l2.343-2.342v3.457a.664.664 0 0 0-.16.387L9.71 18.586a.667.667 0 1 0 .943.943l.68-.68Z"
      clipRule="evenodd"
    />
  </svg>
);

const ForwardRef = forwardRef(ComponentAirConditioningIcon);
export default ForwardRef;
