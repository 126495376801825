import PreviewModeBanner from "@common/components/PreviewModeBanner";
import { useRouter } from "next/router";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const CoreLayout = ({ children }: Props) => {
  const router = useRouter();
  return (
    <main>
      {router.isPreview && <PreviewModeBanner />}
      {children}
    </main>
  );
};

export default CoreLayout;
