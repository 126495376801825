import { SVGProps, Ref, forwardRef } from "react";

const BrandsMiniIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 100 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Mini Icon"
    {...props}
  >
    <path
      d="M72.6 163.5c-7.5 0-14.5 3.8-18.5 10.1H22.6l3.1 3.1h26.7c-.5 1.2-.9 2.4-1.2 3.7H29.4l3.1 3.1h18.3c-.1.6-.1 1.3-.1 1.9 0 .6 0 1.2.1 1.8H36.1l3.1 3.1h12c.3 1.2.7 2.5 1.2 3.7h-9.5l3.1 3.1h8c4 6.4 11 10.3 18.5 10.3S87 203.5 91 197.1h8l3.1-3.1h-9.5c.5-1.2.9-2.4 1.2-3.7h12l3.1-3.1H94.4c.1-.6.1-1.2.1-1.8 0-.6 0-1.3-.1-1.9h18.3l3.1-3.1H93.9c-.3-1.2-.7-2.5-1.2-3.7h26.8l3.1-3.1H91.1c-4.1-6.3-11-10.1-18.5-10.1zm0 3c10.5 0 18.9 8.5 18.9 18.9 0 10.5-8.5 19-18.9 19s-19-8.5-19-19c0-10.4 8.5-18.9 19-18.9zm-15.1 14v9.8h2v-7.7l2.2 7.7h2.1l2.2-7.7v7.7h2v-9.8h-3.3l-2 6.7-2-6.7h-3.2zm12.7 0v9.8h2.2v-9.8h-2.2zm4.3 0v9.8h2v-6.4l4.4 6.4h2.2v-9.8h-2v6.6l-4.5-6.6h-2.1zm10.9 0v9.8h2.2v-9.8h-2.2z"
      style={{
        fill: "#78819b",
      }}
      transform="translate(-22.58 -154.432)"
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsMiniIcon);
export default ForwardRef;
