import { SVGProps, Ref, forwardRef } from "react";

const VehicleWagonIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 61.2 18.8"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    {...props}
  >
    <path
      d="M59.5 15.5c.6-.1 1.1-.2 1.3-.4.7-.6.2-1.6.2-2.6 0-.2-.6-.2-.6-.5-.1-1.5-.7-4.2-.9-5.7-.1-.5-.3-1.3-1.3-1.9-1.1-.7-4.5-2.6-5.7-3.1-.8-.3-.6-.4-1.5-.5C46.6.2 31.2-.5 26.8.8c-3.6 1.1-6.7 2.7-9.9 4.7C11.2 6.5 6.6 7.3 3.8 8c-1.3.3-1.9 1-2.3 1.7-.4.8-.7 1.3-.9 1.8-.5 0-.6.5-.6 1.1 0 .9-.1 1.6.2 2.6.2.7.8 1.1 1.6 1.1h1.4c.7-.1.9-.1.8-1v-.9c0-3.1 2.6-5.7 5.8-5.7 3.2 0 5.8 2.6 5.8 5.7v1.3c-.1 1.4-.5 1.2.8 1.3h21.1c1-.1 1.3 0 1.1-1.3 0-.4-.1-.8-.1-1.2 0-3.1 2.6-5.7 5.8-5.7 3.2 0 5.8 2.6 5.8 5.7v1.2c0 .2.2.4.4.4 2.9.3 9-.6 9-.6zM35.4 5.3c0-.3.5-2.9.6-3.6 0-.7.3-.7.5-.7 2.5 0 6.5.1 7.6.1.2 0 .4.1.5.3.5.8 2.4 3.4 2.5 3.6.6.7 0 .9-.3.9-3.1.1-7.4.4-10.6.4-.9 0-.9-.2-.8-1zm12.7-1.2c-.5-.7-1.5-2-1.9-2.7-.1-.1 0-.2.1-.2.7 0 3 .1 4.6.5.7.2 6.1 3.2 6 3.4-.1.2-3.8.3-6.9.3-1 .1-1.3-.6-1.9-1.3zM22.9 6.9c0-.1.2-.5.2-1 0-.8-1.6-.8-2.5-.8 1.7-1.2 4.5-2.6 6.8-3.3 1.8-.6 5.2-.7 7.2-.8.3 0 .3.2.3.5-.1.9-.5 2.9-.7 4.1-.1.6-.2.8-.9.8-2.8.2-10.5.6-10.4.5zm21.5 3.2c2.5 0 4.5 1.9 4.5 4.4 0 2.4-2 4.4-4.5 4.4s-4.5-1.9-4.5-4.4c.1-2.5 2-4.4 4.5-4.4zm-34.6 0c2.5 0 4.5 1.9 4.5 4.4 0 2.4-2 4.4-4.5 4.4S5.3 17 5.3 14.5c0-2.5 2-4.4 4.5-4.4z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(VehicleWagonIcon);
export default ForwardRef;
