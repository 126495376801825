import { SVGProps, Ref, forwardRef } from "react";

const SignatureIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 34 34"
    xmlSpace="preserve"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    {...props}
  >
    <path
      d="M13.8,31.6c2.7-1.1,2.1-4,0.7-5.8c-1.3-1.9-3.2-3.2-5.1-4.4c-1.3-0.8-2.6-1.9-3.5-3.1c-0.4-0.5-1.3-1.4-0.4-1.6
	c0.9-0.2,2.4,0.7,3.2,1c1.4,0.6,2.7,1.2,4,2l1.5-2.6c-2.4-1.6-5.4-2.9-8.2-3.3c-1.6-0.2-3.3,0.1-3.8,1.8c-0.5,1.5,0.3,3,1.2,4.2
	c2.1,2.8,5.3,4.1,7.7,6.5c0.5,0.5,1.1,1.1,1.4,1.8c0.3,0.7,0.2,0.7-0.5,0.7c-1.9,0-4.2-1.5-5.7-2.4l-1.5,2.6
	C7.1,30.3,11,32.6,13.8,31.6z M27.7,11.2l-8.6,8.6h-3.5v-3.5l8.6-8.6L27.7,11.2z M32.9,10c0,0.5-0.5,0.9-1,1.4l-3.8,3.8l-1.3-1.3
	l3.9-3.9l-0.9-0.9l-1,1l-3.5-3.5l3.2-3.2c0.4-0.4,1-0.4,1.3,0l2.2,2.2c0.4,0.3,0.4,0.9,0,1.3c-0.3,0.3-0.6,0.6-0.6,0.9
	c0,0.3,0.3,0.6,0.6,0.9C32.5,9.1,32.9,9.6,32.9,10z"
    />
  </svg>
);

const ForwardRef = forwardRef(SignatureIcon);
export default ForwardRef;
