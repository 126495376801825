import { SVGProps, Ref, forwardRef } from "react";

const BrandsNissanIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 100 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Nissan Icon"
    {...props}
  >
    <path
      d="M106.9 63.8H91.7v3.4h15.8c3-.3 4.4-2.8 4.4-5s-1.4-4.6-4.2-4.9c-.5-.1-1-.1-1.2-.1H97c-.4 0-.9 0-1.1-.1-.8-.2-1-.9-1-1.4 0-.5.3-1.2 1.1-1.4.2-.1.5-.1 1-.1h13.9v-3.3H96.7c-.6 0-1 0-1.4.1-2.4.3-4.1 2.3-4.1 4.7 0 2 1.3 4.4 4 4.8.5.1 1.2.1 1.5.1h10.1c1.1.1 1.4.9 1.4 1.6 0 .7-.4 1.4-1.3 1.6zm-27.4 0H64.3v3.4h15.8c3-.3 4.4-2.8 4.4-5s-1.4-4.6-4.2-4.9c-.5-.1-1-.1-1.2-.1h-9.6c-.4 0-.9 0-1.1-.1-.8-.2-1-.9-1-1.4 0-.5.3-1.2 1.1-1.4.2-.1.5-.1 1-.1h13.9v-3.3h-14c-.6 0-1 0-1.4.1-2.4.3-4.1 2.3-4.1 4.7 0 2 1.3 4.4 4 4.8.5.1 1.2.1 1.5.1h10.1c1.1.1 1.4.9 1.4 1.6-.1.7-.5 1.4-1.4 1.6zm-26.4-13h3.6v16.4h-3.6zm-8 16.5V50.8h-3.6v12.3L29.3 50.8h-4.6v16.5h3.6V54.9l12.2 12.4zm117.1-16.5v12.3l-12.1-12.3h-4.7v16.5h3.6V54.9l12.2 12.4h4.6V50.8zm-35.7 0-10.2 16.4h4.4l1.8-2.9h12l1.8 2.9h4.4l-10.2-16.4h-4zm6 10.4h-8.2l4.1-6.6 4.1 6.6zM44.9 41.5C52.2 20.1 72.5 5.7 95.3 5.7s43.1 14.4 50.4 35.8l.1.2h16v-1.9l-6.7-.8c-4.1-.5-5-2.3-6.1-4.6l-.3-.6C139 13.3 118.1 0 95.3 0S51.5 13.3 41.9 33.8l-.3.6c-1.1 2.3-2 4.1-6.1 4.6l-6.7.8v1.9h16l.1-.2zm100.8 34.9-.1.2c-7.3 21.4-27.6 35.8-50.4 35.8S52.2 98 44.9 76.6l-.1-.2h-16v1.9l6.7.8c4.1.5 5 2.3 6.1 4.6l.3.6c9.6 20.6 30.6 33.8 53.4 33.8s43.8-13.3 53.4-33.8l.3-.6c1.1-2.3 2-4.1 6.1-4.6l6.7-.8v-1.9h-16.1z"
      style={{
        fill: "#78819b",
      }}
      transform="scale(.52497)"
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsNissanIcon);
export default ForwardRef;
