import { SVGProps, Ref, forwardRef } from "react";

const HeatedWheelIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Heated Wheel Icon"
    {...props}
  >
    <path d="M12 2.5c-5.2 0-9.5 4.2-9.5 9.5s4.2 9.5 9.5 9.5 9.5-4.2 9.5-9.5-4.3-9.5-9.5-9.5zm0 2.3c1.9 0 3.7.7 5.1 2.1.2.2.3.4.5.6L13.1 10l-1.1.7-1.1-.7-4.5-2.5c.2-.2.3-.4.5-.6 1.4-1.3 3.2-2.1 5.1-2.1zM4.8 12c0-.9.2-1.7.5-2.5L9.7 12l1.1.7v6.4c-1.5-.2-2.9-.9-3.9-2-1.3-1.4-2.1-3.2-2.1-5.1zm12.3 5.1c-1.1 1.1-2.5 1.8-3.9 2v-6.4l1.1-.7 4.4-2.6c.3.8.5 1.7.5 2.5 0 2-.8 3.8-2.1 5.2z" />
  </svg>
);

const ForwardRef = forwardRef(HeatedWheelIcon);
export default ForwardRef;
