import { SVGProps, Ref, forwardRef } from "react";

const ChevronLeftIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Chevron Left Icon"
    {...props}
  >
    <path d="m15.41 16.09-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6 1.41-1.41Z" />
  </svg>
);

const ForwardRef = forwardRef(ChevronLeftIcon);
export default ForwardRef;
