import { SVGProps, Ref, forwardRef } from "react";

const CarMediaSpinIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 19 12"
    fill="currentColor"
    width="19"
    height="12"
    ref={ref}
    aria-label="Car Media Spin Icon"
    {...props}
  >
    <path
      d="M9.48994 7.22646e-05C4.07568 7.22646e-05 0 2.05727 0 4.75977C0 7.11946 3.07667 8.97493 7.4521 9.41863L6.65302 10.2253C6.25348 10.6286 6.25348 11.2942 6.65302 11.6975C6.85286 11.8993 7.11249 12 7.3922 12C7.67189 12 7.93166 11.8991 8.13138 11.6975L10.5688 9.23716C10.7686 9.03542 10.8684 8.77332 10.8684 8.49097C10.8684 8.20862 10.7685 7.94639 10.5688 7.74478L8.11144 5.26398C7.71189 4.86065 7.05256 4.86065 6.65302 5.26398C6.25348 5.66731 6.25348 6.33289 6.65302 6.73622L7.19248 7.2808C4.03583 6.8572 2.09788 5.66731 2.09788 4.77991C2.09788 3.69089 4.99478 2.09753 9.51002 2.09753C14.0252 2.09753 16.9222 3.69075 16.9222 4.77991C16.9222 5.42536 15.7634 6.3934 13.5457 6.97821C12.9863 7.11945 12.6666 7.68417 12.8065 8.24884C12.9464 8.81355 13.5059 9.13628 14.0652 8.99503C17.202 8.18837 19 6.63535 19 4.77987C18.9798 2.05709 14.9043 0 9.48972 0L9.48994 7.22646e-05Z"
      fill="#293EDD"
    />
  </svg>
);

const ForwardRef = forwardRef(CarMediaSpinIcon);
export default ForwardRef;
