import { SVGProps, Ref, forwardRef } from "react";

const FrontCarIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Front Car Icon"
    {...props}
  >
    <path d="m21 10.2-1.4-4.1c-.2-.6-.6-1.1-1.1-1.5-.5-.4-1.1-.6-1.8-.6H7.6c-.6 0-1.2.2-1.7.6-.5.3-.9.9-1.1 1.5l-1.4 4.1c-.4.2-.7.4-.9.7-.2.3-.3.7-.3 1.1v5c0 .7.4 1.4 1 1.7V21c0 .3.1.5.3.7.2.2.4.3.7.3h1c.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7v-2h12v2c0 .3.1.5.3.7s.4.3.7.3h1c.3 0 .5-.1.7-.3s.3-.4.3-.7v-2.3c.3-.2.6-.4.7-.7.2-.3.3-.6.3-1v-5c0-.4-.1-.8-.3-1.1-.3-.3-.6-.6-.9-.7zM7.6 6h9.1c.4 0 .8.3.9.7l1.1 3.3H5.6l1.1-3.3c0-.2.2-.4.3-.5.2-.1.4-.2.6-.2zM5.7 16c-.2 0-.4 0-.6-.1-.2-.1-.3-.2-.5-.3-.1-.1-.2-.3-.3-.5-.1-.2-.1-.4-.1-.6 0-.2 0-.4.1-.6s.2-.3.3-.5c.1-.1.3-.2.5-.3.2-.1.4-.1.6-.1.4 0 .8.2 1.1.4.3.3.4.7.4 1.1 0 .4-.2.8-.4 1.1-.3.2-.7.4-1.1.4zm13 0c-.2 0-.4 0-.6-.1-.2-.1-.3-.2-.5-.3-.1-.1-.2-.3-.3-.5-.1-.2-.1-.4-.1-.6 0-.2 0-.4.1-.6.1-.2.2-.3.3-.5.1-.1.3-.2.5-.3.2-.1.4-.1.6-.1.4 0 .8.2 1.1.4.3.3.4.7.4 1.1 0 .4-.2.8-.4 1.1-.3.2-.7.4-1.1.4z" />
  </svg>
);

const ForwardRef = forwardRef(FrontCarIcon);
export default ForwardRef;
