import { SVGProps, Ref, forwardRef } from "react";

const AppInstagramIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="App Instagram Icon"
    {...props}
  >
    <path d="M16.145 8.852a.96.96 0 1 1 0-1.92.96.96 0 0 1 0 1.92ZM12 16c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4Zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2Zm4 10H8c-2.056 0-4-1.944-4-4V8c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4ZM8 6c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2V8c0-.935-1.065-2-2-2H8Z" />
  </svg>
);

const ForwardRef = forwardRef(AppInstagramIcon);
export default ForwardRef;
