import { SVGProps, Ref, forwardRef } from "react";

const AppShopifyIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="App Shopify Icon"
    {...props}
  >
    <path d="M17.3 7.1c0-.1-.1-.1-.2-.1s-1.3-.1-1.3-.1-.8-.9-.9-1c-.1-.1-.3-.1-.3 0 0 0-.2.1-.5.1-.3-.8-.6-1.5-1.8-1.5-.2-.4-.5-.5-.8-.5-2.1 0-3 2.6-3.4 3.9-.8.2-1.3.4-1.4.4-.4.2-.5.2-.5.6 0 .3-1.2 9.4-1.2 9.4l9.1 1.7 4.9-1.1S17.3 7.2 17.3 7.1Zm-5-.5c-.5.2-1 .3-1.6.5.3-1.2.9-1.8 1.4-2 .2.9.2 1.5.2 1.5Zm-.9-2c.1 0 .2 0 .3.1-.6.3-1.4 1-1.7 2.6-.4.1-.8.3-1.2.4.3-1.2 1.2-3.1 2.6-3.1Zm.4 6.9s-.5-.3-1.2-.3c-1 0-1 .6-1 .8 0 .8 2.2 1.1 2.2 3.1 0 1.5-1 2.5-2.3 2.5-1.6 0-2.4-1-2.4-1l.4-1.4s.8.7 1.5.7c.5 0 .6-.4.6-.6 0-1.1-1.8-1.1-1.8-2.9 0-1.5 1.1-2.9 3.2-2.9.8 0 1.2.2 1.2.2l-.4 1.8Zm1-5.1v-.2c0-.5-.1-.9-.2-1.2.5.1.8.6 1 1.2-.2.1-.5.2-.8.2Z" />
  </svg>
);

const ForwardRef = forwardRef(AppShopifyIcon);
export default ForwardRef;
