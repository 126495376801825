import { Ref, SVGProps, forwardRef } from "react";

const TradeIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    viewBox="0 0 100 100"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Trade Icon Icon"
    {...props}
  >
    <title id="Trade Icon">Trade Icon</title>
    <path
      d="m30.08 9.644-2.24-6.377c-.32-.934-.96-1.711-1.76-2.334C25.28.311 24.32 0 23.2 0H8.64c-.96 0-1.92.311-2.72.933-.8.467-1.44 1.4-1.76 2.334L1.92 9.644c-.64.312-1.12.623-1.44 1.089C.16 11.2 0 11.822 0 12.444v7.778c0 1.089.64 2.178 1.6 2.645v3.577c0 .467.16.778.48 1.089.32.311.64.467 1.12.467h1.6c.48 0 .8-.156 1.12-.467.32-.311.48-.622.48-1.089v-3.111h19.2v3.111c0 .467.16.778.48 1.089.32.311.64.467 1.12.467h1.6c.48 0 .8-.156 1.12-.467.32-.311.48-.622.48-1.089v-3.577c.48-.311.96-.623 1.12-1.089.32-.467.48-.934.48-1.556v-7.778c0-.622-.16-1.244-.48-1.711-.48-.466-.96-.933-1.44-1.089ZM8.64 3.111H23.2c.64 0 1.28.467 1.44 1.089l1.76 5.133H5.44L7.2 4.2c0-.311.32-.622.48-.778.32-.155.64-.311.96-.311ZM5.6 18.667c-.32 0-.64 0-.96-.156-.32-.155-.48-.311-.8-.467-.16-.155-.32-.466-.48-.777-.16-.311-.16-.623-.16-.934 0-.311 0-.622.16-.933.16-.311.32-.467.48-.778.16-.155.48-.311.8-.466C4.96 14 5.28 14 5.6 14c.64 0 1.28.311 1.76.622.48.467.64 1.089.64 1.711 0 .623-.32 1.245-.64 1.711-.48.312-1.12.623-1.76.623Zm20.8 0c-.32 0-.64 0-.96-.156-.32-.155-.48-.311-.8-.467-.16-.155-.32-.466-.48-.777-.16-.311-.16-.623-.16-.934 0-.311 0-.622.16-.933.16-.311.32-.467.48-.778.16-.155.48-.311.8-.466.32-.156.64-.156.96-.156.64 0 1.28.311 1.76.622.48.467.64 1.089.64 1.711 0 .623-.32 1.245-.64 1.711-.48.312-1.12.623-1.76.623Z"
      style={{
        fillRule: "nonzero",
      }}
      transform="translate(27.795 29.929) scale(1.43366)"
    />
    <path d="m18.825 23.234 8.456-14.578L35.678 23.2l-16.853.034ZM80.568 77.32l-8.455 14.578-8.397-14.544 16.852-.034Z" />
    <path d="M20.81 30.215A35.687 35.687 0 0 0 14.858 50c0 19.8 16.075 35.875 35.876 35.875a35.693 35.693 0 0 0 20.331-6.321l4.508 4.508a41.956 41.956 0 0 1-24.839 8.1C27.464 92.162 8.572 73.27 8.572 50A41.948 41.948 0 0 1 13.5 30.215h7.31Zm-4.146-5.044.007-.01.011.01h-.018Zm63.631 45.167-.007-.007A35.693 35.693 0 0 0 86.609 50c0-19.8-16.075-35.875-35.875-35.875a35.694 35.694 0 0 0-20.332 6.321l-4.508-4.508a41.957 41.957 0 0 1 24.84-8.1C74.003 7.838 92.895 26.73 92.895 50a41.934 41.934 0 0 1-5.228 20.338h-7.372Z" />
  </svg>
);

const ForwardRef = forwardRef(TradeIcon);
export default ForwardRef;
