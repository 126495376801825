import { SVGProps, Ref, forwardRef } from "react";

const TrendingUpIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Trending Up Icon"
    {...props}
  >
    <path d="m16 6 2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6h-6Z" />
  </svg>
);

const ForwardRef = forwardRef(TrendingUpIcon);
export default ForwardRef;
