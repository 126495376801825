import { SVGProps, Ref, forwardRef } from "react";

const PlayIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Play Icon"
    {...props}
  >
    <path d="M8 5v14l11-7L8 5Z" />
  </svg>
);

const ForwardRef = forwardRef(PlayIcon);
export default ForwardRef;
