import { SVGProps, Ref, forwardRef } from "react";

const LoadingIconCircleIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 48 48"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Loading Icon Circle Icon"
    {...props}
  >
    <g clipPath="url(#clip0_6246_126)">
      <path
        d="M24 48.0523C37.2548 48.0523 48 37.3071 48 24.0523C48 10.7974 37.2548 0.0522766 24 0.0522766C10.7452 0.0522766 0 10.7974 0 24.0523C0 37.3071 10.7452 48.0523 24 48.0523Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6246_126">
        <rect width="48" height="48" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(LoadingIconCircleIcon);
export default ForwardRef;
