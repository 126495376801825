import { SVGProps, Ref, forwardRef } from "react";

const ComponentExtraValueIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Component Extra Value Icon"
    {...props}
  >
    <title id="Component Extra Value Icon">Component Extra Value Icon</title>
    <path
      fillRule="evenodd"
      d="m4 9.717 8 8.616 8-8.616L15.636 5H8.39L4 9.717Zm8.466-2.436c.788.097 1.41.476 1.74.933l-.622.603c-.281-.399-.875-.74-1.545-.74-.749 0-1.216.331-1.216.837 0 .565.676.768 1.431.996.96.288 2.05.616 2.05 1.775 0 .972-.71 1.672-1.838 1.808v.836h-.748v-.816c-1.002-.069-1.663-.506-2.052-1.05l.632-.593c.418.544 1.021.826 1.73.826.856 0 1.342-.37 1.342-.953 0-.649-.727-.87-1.516-1.112-.938-.287-1.964-.6-1.964-1.688 0-.904.73-1.565 1.828-1.672v-.827h.748v.837Z"
      clipRule="evenodd"
    />
  </svg>
);

const ForwardRef = forwardRef(ComponentExtraValueIcon);
export default ForwardRef;
