import { SVGProps, Ref, forwardRef } from "react";

const BrandsAudiIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 100 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Audi Icon"
    {...props}
  >
    <path
      d="M82.64 48.35c-4.1 0-7.89-1.44-10.87-3.84-2.83 2.28-6.4 3.7-10.3 3.83-4.32.15-8.33-1.31-11.46-3.83-2.83 2.28-6.4 3.7-10.3 3.83-4.32.15-8.33-1.31-11.46-3.83-2.83 2.28-6.4 3.7-10.3 3.83C8.37 48.66.33 41.14 0 31.57c-.31-9.56 7.21-17.6 16.78-17.92h1.16c3.89.13 7.46 1.55 10.3 3.84 2.83-2.29 6.4-3.71 10.31-3.84h1.16c3.89.13 7.46 1.55 10.3 3.84 2.83-2.29 6.4-3.71 10.31-3.84h1.16c3.89.13 7.46 1.55 10.3 3.84 2.83-2.29 6.4-3.71 10.31-3.84h1.16c9.1.3 16.47 7.67 16.77 16.77.32 9.57-7.2 17.61-16.77 17.93h-.59Zm-30.05-6.42c2.43 1.84 5.48 2.89 8.75 2.79 2.95-.1 5.65-1.12 7.83-2.79a17.28 17.28 0 0 1-3.88-10.36 17.28 17.28 0 0 1 3.88-11.51 13.659 13.659 0 0 0-7.8-2.79h-.97c-2.94.1-5.63 1.13-7.81 2.79a17.28 17.28 0 0 1 .01 21.86Zm-21.76 0c2.43 1.84 5.48 2.89 8.75 2.79 2.95-.1 5.65-1.12 7.83-2.79a17.316 17.316 0 0 1 0-21.87 13.659 13.659 0 0 0-7.8-2.79h-.97c-2.94.1-5.63 1.13-7.81 2.79a17.28 17.28 0 0 1 .01 21.86ZM16.87 17.27c-7.55.27-13.49 6.62-13.24 14.18.25 7.57 6.62 13.51 14.18 13.27 2.95-.1 5.65-1.12 7.83-2.79a17.316 17.316 0 0 1 0-21.87 13.659 13.659 0 0 0-7.8-2.79h-.97Zm57.48 24.66c2.43 1.84 5.48 2.9 8.75 2.79 7.57-.25 13.52-6.61 13.27-14.18-.24-7.19-6.05-13.01-13.24-13.26h-.97c-2.94.1-5.63 1.13-7.81 2.79a17.28 17.28 0 0 1 .01 21.86ZM28.23 22.64a13.646 13.646 0 0 0-2.84 8.82c.1 2.98 1.15 5.71 2.84 7.91 1.88-2.44 2.95-5.52 2.84-8.82-.1-2.97-1.15-5.7-2.85-7.9Zm21.76 0a13.646 13.646 0 0 0-2.84 8.82c.1 2.98 1.15 5.71 2.84 7.91 1.88-2.44 2.95-5.52 2.84-8.82-.1-2.97-1.15-5.7-2.85-7.9Zm21.76 0a13.702 13.702 0 0 0-2.84 8.82c.1 2.98 1.15 5.71 2.84 7.91 1.88-2.44 2.95-5.52 2.84-8.82-.1-2.97-1.15-5.7-2.85-7.9Z"
      style={{
        fill: "#78819b",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsAudiIcon);
export default ForwardRef;
