import { SVGProps, Ref, forwardRef } from "react";

const MyLocationIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="My Location Icon"
    {...props}
  >
    <g>
      <path
        d="M20.3,11.3c-0.2-2-1-3.8-2.4-5.2c-1.4-1.4-3.2-2.3-5.2-2.4V1.6h-1.4v2.1c-2,0.2-3.8,1-5.2,2.4c-1.4,1.4-2.3,3.2-2.4,5.2
		H1.6v1.4h2.1c0.2,2,1,3.8,2.4,5.2c1.4,1.4,3.2,2.3,5.2,2.4v2.1h1.4v-2.1c2-0.2,3.8-1,5.2-2.4c1.4-1.4,2.3-3.2,2.4-5.2h2.1v-1.4
		H20.3z M12,19c-3.8,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7S15.8,19,12,19z"
      />
      <circle cx="12" cy="12" r="3.8" />
    </g>
  </svg>
);

const ForwardRef = forwardRef(MyLocationIcon);
export default ForwardRef;
