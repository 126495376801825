import { SVGProps, Ref, forwardRef } from "react";

const ComponentDriveAxleIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Component Drive Axle Icon"
    {...props}
  >
    <title id="Component Drive Axle Icon">Component Drive Axle Icon</title>
    <path
      fillRule="evenodd"
      d="M16 18h-1.333v1.333H9.334V18H8v1.333a.667.667 0 0 1-.667.667H4.667A.667.667 0 0 1 4 19.333v-4.666c0-.368.298-.667.667-.667h2.666c.369 0 .667.299.667.667V16h1.334v-1.536l1.332-1.565V4h2.667v8.898l1.334 1.566V16H16v-1.333c0-.368.299-.667.667-.667h2.666c.369 0 .667.299.667.667v4.666a.667.667 0 0 1-.667.667h-2.666a.667.667 0 0 1-.667-.667V18Zm-5.334 0h2.667v-2.987L12 13.333l-1.334 1.68V18Z"
      clipRule="evenodd"
    />
  </svg>
);

const ForwardRef = forwardRef(ComponentDriveAxleIcon);
export default ForwardRef;
