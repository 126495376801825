import { SVGProps, Ref, forwardRef } from "react";

const BrandsSaturnIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="a"
    x={0}
    y={0}
    aria-hidden="true"
    viewBox="0 0 100 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Saturn Icon"
    {...props}
  >
    <style>{"\r\n    .st0{fill:#78819b}\r\n  "}</style>
    <path
      d="M73.2 12.2h-.1c-.9.4-1.8.7-2.8 1.1-.9.4-1.8.7-2.6 1.1-1.6.7-3.2 1.4-4.7 2.2-1.1.5-2.2 1.1-3.3 1.7-1.2.7-2.5 1.4-3.7 2.1-1.3.8-2.6 1.6-3.8 2.5-1.6 1.1-3.1 2.3-4.6 3.5-1 .8-1.9 1.6-2.9 2.5 0 0-.1.1 0 .1.1.5.1 1 .2 1.4.2 1.3.5 2.5.9 3.7.4 1.4 1 2.7 1.6 4 .6 1.2 1.3 2.4 2.1 3.5.9 1.3 1.9 2.5 2.9 3.6.8.8 1.6 1.6 2.5 2.3.8.7 1.7 1.3 2.6 1.9.8.5 1.6 1 2.5 1.4.8.4 1.6.8 2.4 1.1 1 .4 2 .7 3 1 .7.2 1.5.4 2.2.5.4.1.8.2 1.2.2h4.3l.1-41.4zM27.9 48.6v-1.3c0-.4.1-.9.1-1.3.1-.9.4-1.8.6-2.7.3-1.1.8-2.1 1.3-3.1.7-1.5 1.6-2.8 2.5-4.2.9-1.3 1.9-2.6 3-3.8.8-.9 1.6-1.7 2.4-2.6.6-.6 1.3-1.3 2-1.9.7-.7 1.5-1.3 2.2-1.9.1 0 .1-.1.1-.2 0-1.3 0-2.6.2-3.9.1-.9.2-1.8.4-2.6.2-1.1.5-2.1.8-3.2.3-.8.5-1.5.8-2.3.4-.9.8-1.8 1.3-2.8.3-.6.7-1.2 1-1.8.1-.2.3-.5.4-.7H26.8v40.1h1.1zm14.7-17.7c-.1 0-.9 1-1.2 1.4-.9 1.2-1.8 2.4-2.6 3.6-.7 1.1-1.2 2.3-1.7 3.6-.3.9-.5 1.9-.6 2.9-.1.6 0 1.1 0 1.7.1.6.2 1.3.4 1.9.6 1.8 1.6 3.2 3.1 4.4 1.3 1 2.7 1.7 4.2 2.2 1.4.5 2.9.8 4.4.9 1.1.1 2.1.2 3.2.2h8.8c-4.7-2.1-8.6-5.2-11.8-9.2s-5.2-8.6-6.2-13.6zm1.8-6.9c.1 0 .1 0 .2-.1.5-.4 1.1-.8 1.6-1.2 1-.7 1.9-1.3 2.9-2 1.1-.7 2.2-1.4 3.4-2.1.7-.5 1.5-.9 2.3-1.3 1.1-.6 2.1-1.1 3.2-1.7.8-.4 1.6-.8 2.5-1.2l2.7-1.2c1.4-.6 2.8-1.1 4.2-1.7 1-.4 2-.7 2.9-1.1 1-.3 1.9-.6 2.9-.9l.1-.1V8.3H50c-.1 0-.2 0-.2.1-.7 1-1.3 2-1.9 3-.4.8-.8 1.7-1.2 2.6-.5 1-.8 2.1-1.1 3.1-.3 1.1-.6 2.2-.7 3.3-.1.8-.2 1.7-.3 2.5-.2.4-.2.7-.2 1.1z"
      className="st0"
    />
    <path
      d="M73.2 12.2v41.5h-4.3c-.4 0-.8-.1-1.2-.2-.7-.2-1.5-.3-2.2-.5-1-.3-2-.6-3-1-.8-.3-1.6-.7-2.4-1.1-.8-.4-1.7-.9-2.5-1.4-.9-.6-1.8-1.2-2.6-1.9-.9-.7-1.7-1.5-2.5-2.3-1.1-1.1-2.1-2.3-2.9-3.6-.8-1.1-1.5-2.3-2.1-3.5-.6-1.3-1.2-2.6-1.6-4-.4-1.2-.7-2.5-.9-3.7-.1-.5-.2-.9-.2-1.4V29c1-.8 1.9-1.7 2.9-2.5 1.5-1.2 3.1-2.3 4.6-3.5 1.2-.9 2.5-1.7 3.8-2.5 1.2-.7 2.4-1.5 3.7-2.1 1.1-.6 2.2-1.2 3.3-1.7 1.6-.7 3.1-1.5 4.7-2.2.9-.4 1.8-.7 2.6-1.1.9-.4 1.8-.7 2.8-1.1-.1-.1-.1-.1 0-.1zM27.9 48.6h-1.1V8.4H47c-.1.2-.3.5-.4.7-.3.6-.7 1.2-1 1.8-.4.9-.9 1.8-1.3 2.8-.3.7-.5 1.5-.8 2.3-.3 1-.6 2.1-.8 3.2-.2.9-.3 1.8-.4 2.6-.2 1.3-.2 2.6-.2 3.9 0 .1-.1.1-.1.2-.7.6-1.5 1.3-2.2 1.9-.7.6-1.3 1.2-2 1.9-.8.8-1.6 1.7-2.4 2.6-1.1 1.2-2.1 2.4-3 3.8-.9 1.3-1.8 2.7-2.5 4.2-.5 1-.9 2-1.3 3.1-.3.9-.5 1.8-.6 2.7-.1.4-.1.9-.1 1.3v1.2c0-.1 0-.1 0 0z"
      className="st0"
    />
    <path
      d="M42.6 31.4c1 5 3 9 6.2 13.1 3.2 4 7.1 7.1 11.8 9.2h-8.8c-1.1 0-2.2-.1-3.2-.2-1.5-.2-2.9-.5-4.4-.9-1.5-.5-3-1.2-4.2-2.2-1.4-1.2-2.5-2.6-3.1-4.4-.2-.6-.3-1.2-.4-1.9-.1-.6-.1-1.1 0-1.7.1-1 .3-1.9.6-2.9.4-1.3 1-2.4 1.7-3.6.8-1.3 1.6-2.5 2.6-3.6.5-.7 1.2-.9 1.2-.9zm1.8-7.4c0-.3 0-.6.1-.9.1-.8.2-1.7.3-2.5.2-1.1.4-2.2.7-3.3.3-1.1.7-2.1 1.1-3.1.4-.9.8-1.7 1.2-2.6.5-1.1 1.2-2.1 1.9-3 .1-.1.2-.1.2-.1h23.3v1.1l-.1.1c-1 .3-1.9.6-2.9.9-1 .3-2 .7-2.9 1.1-1.4.5-2.8 1.1-4.2 1.7l-2.7 1.2c-.8.4-1.7.8-2.5 1.2-1.1.5-2.1 1.1-3.2 1.7-.8.4-1.5.8-2.3 1.3-1.1.7-2.3 1.4-3.4 2.1-1 .6-1.9 1.3-2.9 2-.6.4-1.1.8-1.6 1.2 0-.2 0-.2-.1-.1.1 0 0 0 0 0z"
      className="st0"
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsSaturnIcon);
export default ForwardRef;
