import { SVGProps, Ref, forwardRef } from "react";

const CursorCircleIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Cursor Circle Icon"
    {...props}
  >
    <path d="M8.5 2c-1.8 0-3.6.7-5 2-2.7 2.6-2.7 6.8 0 9.4 1.5 1.4 3.4 2 5.4 1.9l-.5-1.2c-1.4 0-2.9-.6-4-1.6-2.3-2.1-2.3-5.6 0-7.7C5.5 3.7 7 3.2 8.5 3.2s2.9.5 4.1 1.6c1.1 1 1.7 2.4 1.7 3.8l1.3.5c.1-1.8-.6-3.7-2-5.1-1.5-1.3-3.3-2-5.1-2z" />
    <path d="m8.8 8.9 10.1 3.6-2.1 2.1-.7.7.7.7 4 4-.9 1-4-4-.7-.7-.7.7-2.1 2.1L8.8 8.9M7.1 7.3l4.8 13.6 3.3-3.2 4.7 4.7 2.3-2.3-4.7-4.7 3.3-3.2L7.1 7.3z" />
  </svg>
);

const ForwardRef = forwardRef(CursorCircleIcon);
export default ForwardRef;
