import { SVGProps, Ref, forwardRef } from "react";

const EllipseIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 30 30"
    fill="currentColor"
    ref={ref}
    aria-label="Ellipse Icon"
    {...props}
  >
    <circle cx={15} cy={15} r={15} fillOpacity={0.38} />
  </svg>
);

const ForwardRef = forwardRef(EllipseIcon);
export default ForwardRef;
