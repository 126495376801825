import { SVGProps, Ref, forwardRef } from "react";

const CarFaxIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    ref={ref}
    {...props}
  >
    <path
      d="M20.9,1.9H3.1c-0.6,0-1.2,0.5-1.2,1.2v17.8c0,0.6,0.5,1.2,1.2,1.2h17.8c0.6,0,1.2-0.5,1.2-1.2V3.1
	C22.1,2.4,21.6,1.9,20.9,1.9z M21.5,20.9c0,0.3-0.3,0.6-0.6,0.6H3.1c-0.3,0-0.6-0.3-0.6-0.6V3.1c0-0.3,0.3-0.6,0.6-0.6h17.8
	c0.3,0,0.6,0.3,0.6,0.6V20.9z"
    />
    <path
      style={{ fillRule: "evenodd", clipRule: "evenodd" }}
      d="M3.8,3.8h16.4v16.4H3.8V3.8z M17.2,15.2c-1,1.9-2.8,3.2-4.9,3.2c-3.1,0-5.6-2.9-5.6-6.4s2.5-6.4,5.6-6.4
	c2.2,0,4.1,1.4,5,3.6l-1.5,0.7l-1.4,0.6c-0.4-1.3-1.2-2.2-2.1-2.2C11,8.4,10,10,10,12s1,3.6,2.3,3.6c0.9,0,1.7-0.8,2.1-2L17.2,15.2z
	 M19.7,9.6l0.5-1v6.1c0,0-0.1,0-0.1,0L18.3,15l0.2-0.7c0-0.1,0-0.1,0-0.2l-2-1.6l0.5-0.2l0,0c0.1,0,0.1-0.1,0.1-0.2L16.6,11l1.2,0.2
	c0.1,0,0.1,0,0.2-0.1l0.2-0.5l0.9,1c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.1-0.2l-0.4-2.2l0.7,0.4c0,0,0,0,0,0
	C19.6,9.7,19.7,9.7,19.7,9.6z"
    />
  </svg>
);

const ForwardRef = forwardRef(CarFaxIcon);
export default ForwardRef;
