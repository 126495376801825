import { SVGProps, Ref, forwardRef } from "react";

const AppSlackIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="App Slack Icon"
    {...props}
  >
    <path d="m11.303 13.39-.698-2.086 2.18-.73.7 2.085-2.182.73Zm7.994-3.593c1.7 5.5.4 7.9-5.1 9.5-5.5 1.7-7.9.4-9.5-5.1-1.7-5.5-.4-7.9 5.1-9.5 5.5-1.7 7.9-.4 9.5 5.1Zm-2.8 3.6c.5-.2.7-.6.5-1.1-.1-.4-.6-.6-1-.5l-1 .3-.8-2 1-.3c.4-.1.6-.6.5-1-.1-.4-.6-.6-1-.5l-1 .3-.4-1.1c-.1-.4-.6-.6-1-.5-.4.1-.6.6-.5 1l.4 1-2.1.8-.4-1.1c-.1-.4-.6-.6-1-.5-.5.1-.7.6-.6 1l.4 1.1-1 .3c-.5.2-.7.6-.6 1.1.1.3.6.7 1.1.5l1-.3.8 2-1 .3c-.4.1-.6.6-.5 1 .1.3.4.7 1 .5l1-.3.4 1.1c.2.7.9.5 1 .5.4-.1.6-.6.5-1l-.4-1 2.2-.8.4 1.1c.2.7.9.5 1 .5.4-.1.6-.6.5-1l-.4-1.1 1-.3Z" />
  </svg>
);

const ForwardRef = forwardRef(AppSlackIcon);
export default ForwardRef;
