import { SVGProps, Ref, forwardRef } from "react";

const AppSpotifyIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="App Spotify Icon"
    {...props}
  >
    <path d="M12 4c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8Zm3.7 11.6c-.2.2-.4.3-.7.2-1.9-1.2-4.2-1.4-7-.8-.3.1-.5-.1-.6-.4-.1-.3.1-.5.4-.6 3-.7 5.7-.4 7.8.9.2.1.2.4.1.7Zm.9-2.2c-.2.3-.6.4-.8.2-2.2-1.3-5.4-1.7-8-.9-.3 0-.6-.1-.7-.5-.1-.3.1-.6.4-.7 2.9-.9 6.5-.4 9 1.1.2.1.3.5.1.8Zm.1-2.3c-2.6-1.5-6.8-1.7-9.3-.9-.4.1-.8-.1-.9-.5-.1-.4.1-.8.5-.9 2.8-.8 7.5-.7 10.5 1.1.4.2.5.7.3 1-.2.3-.7.4-1.1.2Z" />
  </svg>
);

const ForwardRef = forwardRef(AppSpotifyIcon);
export default ForwardRef;
