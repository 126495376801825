import { SVGProps, Ref, forwardRef } from "react";

const AppAndroidIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="App Android Icon"
    {...props}
  >
    <path d="M7.833 15.75c0 .39.32.708.709.708h.708v2.48a1.062 1.062 0 1 0 2.125-.001v-2.479h1.417v2.48a1.061 1.061 0 1 0 2.125 0v-2.48h.708a.71.71 0 0 0 .708-.708V8.667h-8.5v7.083Zm-1.77-7.083A1.06 1.06 0 0 0 5 9.729v4.959a1.062 1.062 0 1 0 2.125-.001V9.73c0-.588-.475-1.062-1.063-1.062Zm12.041 0a1.06 1.06 0 0 0-1.062 1.062v4.959a1.061 1.061 0 1 0 2.125 0V9.729c0-.588-.475-1.062-1.063-1.062Zm-3.52-4.137.92-.92a.35.35 0 0 0 0-.504.35.35 0 0 0-.502 0l-1.049 1.049a4.136 4.136 0 0 0-1.87-.447c-.68 0-1.317.163-1.884.447L9.144 3.106a.35.35 0 0 0-.503 0 .35.35 0 0 0 0 .503l.928.928a4.238 4.238 0 0 0-1.736 3.421h8.5c0-1.41-.687-2.656-1.75-3.428Zm-3.917 2.012h-.709v-.709h.709v.709Zm3.541 0H13.5v-.709h.708v.709Z" />
  </svg>
);

const ForwardRef = forwardRef(AppAndroidIcon);
export default ForwardRef;
