import { SVGProps, Ref, forwardRef } from "react";

const PhoneIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Phone Icon"
    {...props}
  >
    <path d="M 9.486 13.691 C 10.663 14.869 11.155 15.011 12.479 15.711 L 14.05 14.108 C 14.617 13.531 15.443 13.289 16.231 13.472 L 19.48 14.219 C 19.95 14.329 20.355 14.612 20.355 15.096 L 20.355 18.034 C 20.355 19.106 19.625 20.06 18.562 20.178 C 14.808 20.591 10.889 19.42 7.324 15.854 C 3.756 12.288 2.586 8.37 2.998 4.617 C 3.118 3.552 4.072 2.822 5.144 2.822 L 5.915 2.822 L 8.081 2.822 C 8.564 2.822 8.849 3.23 8.957 3.698 L 9.706 6.945 C 9.888 7.735 9.646 8.562 9.068 9.128 L 7.466 10.697 C 8.164 12.022 8.307 12.511 9.486 13.691 Z" />
  </svg>
);

const ForwardRef = forwardRef(PhoneIcon);
export default ForwardRef;
