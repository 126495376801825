import { SVGProps, Ref, forwardRef } from "react";

const CursorIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Cursor Icon"
    {...props}
  >
    <polygon points="20,18.6 15.2,13.1 19,9.8 4.4,3.4 8.7,18.7 12.5,15.4 17.3,21" />
  </svg>
);

const ForwardRef = forwardRef(CursorIcon);
export default ForwardRef;
