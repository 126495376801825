import { SVGProps, Ref, forwardRef } from "react";

const GoogleBusinessManagerIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 28 28"
    fill="currentColor"
    width="1em"
    height="1em"
    aria-label="Google Business Manager Icon"
    ref={ref}
    {...props}
  >
    <title id="Google Business Manager Icon">
      Google Business Manager Icon
    </title>
    <path d="M27.333 9.334A3.343 3.343 0 0 1 24 12.667a3.343 3.343 0 0 1-3.333-3.333 3.343 3.343 0 0 1-3.334 3.333A3.343 3.343 0 0 1 14 9.334a3.343 3.343 0 0 1-3.333 3.333 3.343 3.343 0 0 1-3.334-3.333A3.343 3.343 0 0 1 4 12.667 3.343 3.343 0 0 1 .667 9.334L2.52 2.107S2.907.667 4.267.667h19.466c1.36 0 1.747 1.44 1.747 1.44l1.853 7.227ZM26 14.267v10.4c0 1.467-1.2 2.667-2.667 2.667H4.667A2.675 2.675 0 0 1 2 24.667v-10.4a5.28 5.28 0 0 0 5.333-.773 5.337 5.337 0 0 0 3.334 1.173c1.266 0 2.426-.44 3.333-1.173a5.337 5.337 0 0 0 3.333 1.173c1.267 0 2.427-.44 3.334-1.173A5.309 5.309 0 0 0 24 14.667c.707 0 1.387-.147 2-.4Zm-2.667 6.84c0-.267 0-.547-.066-.84l-.04-.213h-3.96v1.56h2.413c-.08.293-.187.586-.413.826-.44.44-1.04.68-1.68.68-.667 0-1.32-.28-1.8-.746-.92-.947-.92-2.48.026-3.44.92-.934 2.44-.934 3.4-.04l.187.173 1.12-1.133-.213-.187a4.065 4.065 0 0 0-2.774-1.08h-.013c-1.08 0-2.093.413-2.853 1.16-.787.773-1.227 1.787-1.227 2.84 0 1.067.413 2.053 1.173 2.787a4.267 4.267 0 0 0 2.96 1.213h.027c1.067 0 2.013-.387 2.707-1.067.626-.64 1.026-1.6 1.026-2.493Z" />
  </svg>
);

const ForwardRef = forwardRef(GoogleBusinessManagerIcon);
export default ForwardRef;
