import { SVGProps, Ref, forwardRef } from "react";

const LeadIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Lead Icon"
    {...props}
  >
    <path
      d="M10.4,8.8c-0.2-0.2-0.5-0.3-0.8-0.3C9.3,8.4,9,8.5,8.8,8.8S8.4,9.3,8.4,9.6c0,0.3,0.1,0.6,0.3,0.8l2.4,2.4
	c0.2,0.2,0.5,0.4,0.8,0.4c0.3,0,0.6-0.1,0.8-0.4l2.4-2.4c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.1-0.6-0.4-0.8c-0.2-0.2-0.5-0.3-0.8-0.4
	c-0.3,0-0.6,0.1-0.8,0.3l-0.4,0.4V3.6c0-0.3-0.1-0.6-0.4-0.8c-0.2-0.2-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.4
	c-0.2,0.2-0.4,0.5-0.4,0.8v5.5L10.4,8.8z"
    />
    <path
      d="M3.6,6c0-0.6,0.3-1.2,0.7-1.7C4.8,3.9,5.4,3.6,6,3.6h1.2C7.5,3.6,7.8,3.7,8,4c0.2,0.2,0.4,0.5,0.4,0.8
	c0,0.3-0.1,0.6-0.4,0.8C7.8,5.9,7.5,6,7.2,6H6v8.4h2.4l1.2,2.4h4.8l1.2-2.4H18V6h-1.2c-0.3,0-0.6-0.1-0.8-0.4
	c-0.2-0.2-0.4-0.5-0.4-0.8c0-0.3,0.1-0.6,0.4-0.8c0.2-0.2,0.5-0.4,0.8-0.4H18c0.6,0,1.2,0.3,1.7,0.7s0.7,1.1,0.7,1.7v12
	c0,0.6-0.3,1.2-0.7,1.7c-0.5,0.4-1.1,0.7-1.7,0.7H6c-0.6,0-1.2-0.3-1.7-0.7c-0.5-0.5-0.7-1.1-0.7-1.7V6z"
    />
  </svg>
);

const ForwardRef = forwardRef(LeadIcon);
export default ForwardRef;
