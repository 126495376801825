import { SVGProps, Ref, forwardRef } from "react";

const LoadingIconSplitIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 48 48"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Loading Icon Split Icon"
    {...props}
  >
    <g clipPath="url(#clip0_6246_128)">
      <path
        d="M24 0.0522766C10.7452 0.0522766 0 10.7974 0 24.0523C0 37.3071 10.7452 48.0523 24 48.0523C37.2548 48.0523 48 37.3071 48 24.0523C48 10.7974 37.2548 0.0522766 24 0.0522766ZM5.79125 24.0523C5.79125 19.1886 7.68526 14.6159 11.1244 11.1767C13.8711 8.43012 17.3409 6.67034 21.1043 6.07244V42.0321C17.3409 41.4342 13.8711 39.6744 11.1244 36.9278C7.68526 33.4886 5.79125 28.916 5.79125 24.0523ZM36.8755 36.9278C34.1289 39.6744 30.6589 41.4342 26.8956 42.0321V6.07242C30.659 6.67031 34.1289 8.4301 36.8755 11.1767C40.3147 14.6159 42.2087 19.1886 42.2087 24.0523C42.2087 28.916 40.3147 33.4886 36.8755 36.9278Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6246_128">
        <rect width="48" height="48" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(LoadingIconSplitIcon);
export default ForwardRef;
