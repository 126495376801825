import { SVGProps, Ref, forwardRef } from "react";

const BrandsRamIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 100 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Ram Icon"
    {...props}
  >
    <path
      d="M64.8 23.66h15.42c.02.08 2.11 4.47 2.22 4.49.06-.11 2.21-4.4 2.3-4.49h15.24c0 .13.02.26.02.39v13.77c0 .12 0 .24-.01.38H89.08v-7.73c0-.09-.05-.12-.15-.09-.02.07-3.86 7.64-3.93 7.79-.1.01-.22.05-.34.05h-4.73c-.08-.07-4.08-7.77-4.19-7.86-.02.07-.05.12-.05.17-.01.2-.02.4-.02.6 0 2.23 0 4.46.01 6.69 0 .12-.02.24-.02.35l-.03.03s-.01 0-.02.01c-.04 0-.08.01-.11.01H64.77s-.03-14.32.02-14.54Zm-11.14 0H37.47c-.07 0-.12.03-.17.09-.07.08-7.59 14.43-7.59 14.43h11.25l1.2-2.82 7.43-.02 1.21 2.87h11.48s-8.3-14.36-8.3-14.55h-.33Zm-7.84 3.53c.03-.1.1-.11.17-.04.14.12 1.91 4.36 1.91 4.36-1.14 0-4.04-.01-4.04-.01s1.93-4.23 1.96-4.31ZM27.21 38.17c-.2.05-11.01.06-11.29.01l-3.15-4.19h-2.04v4.33L0 38.35V23.66h20.78s6.04 0 5.72 4.99c-.15 2.34-1.86 3.7-3.69 4.49.06.07 4.38 4.94 4.39 5.04ZM14.86 28.9c0-1.49-1.39-1.42-1.39-1.42h-2.91v2.85h2.91s1.39.07 1.39-1.42Z"
      style={{
        fill: "#78819b",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsRamIcon);
export default ForwardRef;
