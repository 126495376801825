import { SVGProps, Ref, forwardRef } from "react";

const CoverageTireRimIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Coverage Tire Rim Icon"
    {...props}
  >
    <title id="Coverage Tire Rim Icon">Coverage Tire Rim Icon</title>
    <path
      d="M16.1 6.4C13 4.2 8.6 4.9 6.4 7.9c-2.3 3.1-1.6 7.4 1.5 9.6 3.1 2.2 7.5 1.6 9.8-1.5 2.2-3 1.5-7.3-1.6-9.6zm.5 3-2.5.8c-.3-.3-.7-.6-1.1-.8V6.8c1.6.4 2.9 1.3 3.6 2.6zm-9.2 0c.1-.2.2-.3.3-.5.8-1 1.9-1.8 3.2-2v2.6c-.4.2-.8.4-1.1.7l-2.4-.8zm.6 6.1c-1-1.1-1.5-2.6-1.3-4.1l2.5.8c0 .4.2.8.4 1.2L8 15.5zm3.4-2.6c-.5-.3-.6-1-.2-1.5.4-.5 1-.6 1.5-.2.5.3.6 1 .2 1.5-.4.4-1.1.5-1.5.2zm-1.6 3.9 1.6-2.1c.4.1.9.1 1.3 0l1.6 2.1c-1.5.6-3.1.6-4.5 0zm6.1-1.3-1.5-2.1c.2-.4.4-.8.4-1.2l2.5-.8c.1 1.3-.2 2.6-1 3.6-.1.3-.2.4-.4.5z"
      style={{
        fill: "#00cfeb",
      }}
    />
    <path
      d="M12 13.6c.9 0 1.6-.7 1.6-1.6 0-.9-.7-1.6-1.6-1.6-.9 0-1.6.7-1.6 1.6 0 .9.7 1.6 1.6 1.6z"
      style={{
        fill: "#d5f8ff",
      }}
    />
    <path
      d="M12 2.7c-5.2 0-9.5 4.2-9.5 9.3 0 5.1 4.2 9.3 9.5 9.3 5.2 0 9.5-4.2 9.5-9.3 0-5.1-4.3-9.3-9.5-9.3zm0 15.7c-3.6 0-6.5-2.9-6.5-6.4S8.4 5.6 12 5.6s6.5 2.9 6.5 6.4c0 3.6-2.9 6.4-6.5 6.4z"
      style={{
        fill: "#293edc",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(CoverageTireRimIcon);
export default ForwardRef;
