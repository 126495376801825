import { SVGProps, Ref, forwardRef } from "react";

const UploadPhotoIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 16 16"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Upload Photo Icon"
    {...props}
  >
    <path d="M7.16683 11.3334V4.54171L5.00016 6.70837L3.8335 5.50004L8.00016 1.33337L12.1668 5.50004L11.0002 6.70837L8.8335 4.54171V11.3334H7.16683ZM3.00016 14.6667C2.54183 14.6667 2.14947 14.5035 1.82308 14.1771C1.49669 13.8507 1.3335 13.4584 1.3335 13V10.5H3.00016V13H13.0002V10.5H14.6668V13C14.6668 13.4584 14.5036 13.8507 14.1772 14.1771C13.8509 14.5035 13.4585 14.6667 13.0002 14.6667H3.00016Z" />
  </svg>
);

const ForwardRef = forwardRef(UploadPhotoIcon);
export default ForwardRef;
