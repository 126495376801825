import { SVGProps, Ref, forwardRef } from "react";

const ComponentSuspensionIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Component Suspension Icon"
    {...props}
  >
    <title id="Component Suspension Icon">Component Suspension Icon</title>
    <path
      fillRule="evenodd"
      d="M5.603 6.64A1.333 1.333 0 1 1 6.64 5.603L7.771 4.47c.26-.26.683-.26.943 0l1.414 1.415-4.242 4.242L4.47 8.714a.667.667 0 0 1 0-.943L5.603 6.64ZM6 5.332a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0Zm1.138 6.048a.667.667 0 0 1 0-.943l3.3-3.3a.667.667 0 1 1 .943.943l-3.3 3.3a.667.667 0 0 1-.943 0Zm1.333.39a.667.667 0 0 0 .943.943l3.3-3.3a.667.667 0 1 0-.943-.943l-3.3 3.3Zm1.333 2.276a.667.667 0 0 1 0-.943l3.3-3.3a.667.667 0 0 1 .943.944l-3.3 3.3a.667.667 0 0 1-.943 0Zm1.334.391a.667.667 0 1 0 .943.943l3.3-3.3a.667.667 0 1 0-.943-.943l-3.3 3.3Zm8.519.976c.26.26.26.683 0 .943l-.977.977a1.333 1.333 0 1 1-1.346 1.345l-.977.978a.667.667 0 0 1-.943 0L14 18.242l.98-.98-1.451-1.452a.667.667 0 0 1 0-.943l1.338-1.338c.26-.26.682-.26.943 0l1.451 1.452.981-.981 1.415 1.414Zm-.99 3.92a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334Z"
      clipRule="evenodd"
    />
  </svg>
);

const ForwardRef = forwardRef(ComponentSuspensionIcon);
export default ForwardRef;
