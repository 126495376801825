import { SVGProps, Ref, forwardRef } from "react";

const BrandsMazdaIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 100 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Mazda Icon"
    {...props}
  >
    <path
      d="M40.6 30.7c4.1 1.7 6.9 4.1 9.4 9.6 2.5-5.4 5.2-8.2 9.4-9.9 3.5-1.3 7.1-2.5 10.6-3.8 0 .1 0 .2.1.3.2 1.2.3 2.7.3 4.1 0 15-9.9 18-20.3 17.9-10.6 0-20.4-3-20.4-17.9 0-1.1 0-2.2.2-3.2 0-.2.1-.4.2-.6 3.5 1.1 7 2.3 10.5 3.5zm27.1-10.4c-.1-.1-.2-.2-.2-.4-3.8-5.5-10.5-6.8-17.5-6.8s-13.9 1.4-17.6 7c-.1.2-.3.5-.4.7 6.2 3.5 13.3 3.6 18 12.6 4.3-9.3 11.4-9.4 17.7-13.1zm5-3.2c-.2-.2-.4-.5-.6-.7-5.5-5.9-14.6-7.2-22.1-7.2s-17 1.3-22.4 7.6l-.3.3C24.6 20.3 23 24.8 23 31c0 18.9 15.3 21.9 27 21.9s27-3 27-21.9c0-6.2-1.6-10.7-4.3-13.9z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        fill: "#78819b",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsMazdaIcon);
export default ForwardRef;
