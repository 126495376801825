import { SVGProps, Ref, forwardRef } from "react";

const VehicleSedanIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 61 19"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Vehicle Sedan Icon"
    {...props}
  >
    <path
      d="M48.5 10.2c2.5 0 4.5 1.9 4.5 4.3s-2 4.3-4.5 4.3-4.5-1.9-4.5-4.3 2-4.3 4.5-4.3zm-4.2-7.6L44 6.3c.9 0 1.7-.1 2.5-.1.3 0 .4-.1.6-.3.2-.2.4-.8.5-1.1.1-.2.1-.3-.1-.5-.2-.3-1.9-1.1-3.2-1.7zm-.9 3.8.4-4c-.4-.2-.8-.3-.9-.4-1-.3-2.1-.5-3.1-.5h-3.4c-.2 0-.4.1-.5.2-.3.7-.3 1.6-.7 4.4-.1.8 0 .6.8.5 2.2 0 4.9-.1 7.4-.2zm12.1 9.9c.9-.1 1.9-.1 2.8-.3.6-.1 1.1-.2 1.3-.4.7-.6.6-2.4.6-3.3 0-.2-.6-.2-.6-.5-.1-1.4-.2-3-.4-4.5-.2-1.8-4-1.8-6.4-2.5-1.7-.5-3-1.6-5.2-2.5-2.4-1-4.4-1.9-6.9-1.9-4 0-10-.5-13.7.7-3.6 1.1-6.8 3-10.2 4.6-4.2.4-8.7 1.2-12.9 2.1-1.3.4-1.9 1-2.3 1.7-.4.8-.7 1.6-.9 2.1-.3 0-.3-.1-.4.1-.1.2 0 .4-.1.8-.1.7 0 1.7.1 2.3.2.6.3.9.7 1.1.2.2.5.3.9.3h1.5c.9-.2.7-.3.7-1.4v-.5c0-3.1 2.6-5.5 5.8-5.5 3.2 0 5.8 2.5 5.8 5.5v.9c-.1 1.9-.3 1.5 1.5 1.6h24.5c1.3-.2 1.2 0 1.1-1.6v-.9c0-3.1 2.6-5.5 5.8-5.5s5.8 2.5 5.8 5.5v.8c-.2 1.4.2 1.3 1.1 1.2zM23.1 7.1c0-.1.2-.5.2-1 0-.7-1.4-.8-2.3-.7 1.7-1.2 4.3-2.5 6.5-3.2.8-.3 3-.6 4.5-.6h1.9c.3 0 .4.2.3.4-.3.9-.5 2.8-.7 4-.1.6-.3.8-.9.8-2.6-.1-9.6.4-9.5.3zm-13.2 3c2.5 0 4.5 1.9 4.5 4.3 0 2.3-2 4.3-4.5 4.3s-4.5-1.9-4.5-4.3c0-2.3 2-4.3 4.5-4.3z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(VehicleSedanIcon);
export default ForwardRef;
