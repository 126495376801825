import { SVGProps, Ref, forwardRef } from "react";

const LeatherIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Leather Icon"
    {...props}
  >
    <path d="M19 5.1c-.8.5-1.4.7-2 .7-2.3 0-2.7-3.5-2.7-3.5H9.7s-.4 3.5-2.8 3.5c-.5-.1-1.2-.2-1.9-.7L3.7 8.7s3.2 1 2.9 6c0 0-.2 2.4-2.2 3l1.9 3.7s.9-1.3 2.5-1.3c.9 0 2 .4 3.2 1.7 1.2-1.2 2.3-1.7 3.2-1.7 1.6 0 2.5 1.3 2.5 1.3l1.9-3.7c-2-.7-2.2-3-2.2-3-.3-5 2.9-6 2.9-6L19 5.1z" />
  </svg>
);

const ForwardRef = forwardRef(LeatherIcon);
export default ForwardRef;
