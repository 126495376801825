import { SVGProps, Ref, forwardRef } from "react";

const LoadingIconBurstIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    aria-hidden="true"
    viewBox="0 0 48 48"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Loading Icon Burst Icon"
    {...props}
  >
    <path
      d="M25.7163 35.5364C25.7163 34.5883 24.9478 33.8198 23.9997 33.8198C23.0517 33.8198 22.2832 34.5883 22.2832 35.5364V46.2827C22.2832 47.2307 23.0517 47.9993 23.9997 47.9993C24.9478 47.9993 25.7163 47.2307 25.7163 46.2827V35.5364Z"
      fill="currentColor"
    />
    <path
      d="M25.7163 1.71654C25.7163 0.76852 24.9478 0 23.9997 0C23.0517 0 22.2832 0.76852 22.2832 1.71654V12.4629C22.2832 13.4109 23.0517 14.1795 23.9997 14.1795C24.9478 14.1795 25.7163 13.4109 25.7163 12.4629V1.71654Z"
      fill="currentColor"
    />
    <path
      d="M32.1702 32.3241C31.6129 31.5571 30.5395 31.3871 29.7725 31.9444C29.0055 32.5016 28.8355 33.5751 29.3927 34.342L35.7093 43.036C36.2665 43.803 37.34 43.973 38.107 43.4158C38.8739 42.8585 39.0439 41.7851 38.4867 41.0181L32.1702 32.3241Z"
      fill="currentColor"
    />
    <path
      d="M12.2913 4.96326C11.734 4.1963 10.6606 4.02628 9.89359 4.58351C9.12663 5.14074 8.95661 6.21421 9.51384 6.98118L15.8304 15.6752C16.3876 16.4421 17.4611 16.6122 18.2281 16.0549C18.995 15.4977 19.1651 14.4242 18.6078 13.6573L12.2913 4.96326Z"
      fill="currentColor"
    />
    <path
      d="M35.502 25.9321C34.6004 25.6392 33.632 26.1326 33.339 27.0342C33.0461 27.9358 33.5395 28.9042 34.4411 29.1972L44.6615 32.518C45.5631 32.8109 46.5315 32.3175 46.8245 31.4159C47.1174 30.5143 46.624 29.5459 45.7224 29.2529L35.502 25.9321Z"
      fill="currentColor"
    />
    <path
      d="M3.33793 15.4812C2.43632 15.1883 1.46792 15.6817 1.17497 16.5833C0.882017 17.4849 1.37544 18.4533 2.27706 18.7463L12.4975 22.0671C13.3991 22.3601 14.3675 21.8666 14.6604 20.965C14.9534 20.0634 14.46 19.095 13.5584 18.8021L3.33793 15.4812Z"
      fill="currentColor"
    />
    <path
      d="M44.6625 15.4812L34.4421 18.802C33.5405 19.095 33.0471 20.0634 33.34 20.965C33.633 21.8666 34.6014 22.3601 35.503 22.0671L45.7234 18.7463C46.625 18.4533 47.1184 17.4849 46.8255 16.5833C46.5325 15.6817 45.5641 15.1883 44.6625 15.4812Z"
      fill="currentColor"
    />
    <path
      d="M12.4975 25.9322L2.27706 29.253C1.37544 29.5459 0.882017 30.5143 1.17497 31.4159C1.46792 32.3176 2.43632 32.811 3.33794 32.518L13.5584 29.1972C14.46 28.9043 14.9534 27.9359 14.6604 27.0342C14.3675 26.1326 13.3991 25.6392 12.4975 25.9322Z"
      fill="currentColor"
    />
    <path
      d="M35.7093 4.96326L29.3927 13.6573C28.8355 14.4242 29.0055 15.4977 29.7725 16.0549C30.5395 16.6121 31.6129 16.4421 32.1702 15.6752L38.4867 6.98117C39.0439 6.21421 38.8739 5.14074 38.107 4.58351C37.34 4.02628 36.2665 4.1963 35.7093 4.96326Z"
      fill="currentColor"
    />
    <path
      d="M15.8304 32.3241L9.51384 41.0181C8.95661 41.7851 9.12663 42.8585 9.89359 43.4158C10.6606 43.973 11.734 43.803 12.2913 43.036L18.6078 34.342C19.1651 33.575 18.995 32.5016 18.2281 31.9443C17.4611 31.3871 16.3876 31.5571 15.8304 32.3241Z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = forwardRef(LoadingIconBurstIcon);
export default ForwardRef;
