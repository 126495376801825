import { SVGProps, Ref, forwardRef } from "react";

const HistoryIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 19 18"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="History Icon"
    {...props}
  >
    <title id="History Icon">History Icon</title>
    <path d="M9.7002 5.29883V9.49883V9.79883V10.5988H13.5002V9.49883H10.9002V5.29883H9.7002Z" />
    <path d="M18.9 8.99922C18.9 6.69922 18 4.59922 16.5 3.09922C15 1.59922 12.9 0.699219 10.6 0.699219C8.3 0.699219 6.2 1.59922 4.7 3.09922C3.2 4.59922 2.3 6.59922 2.3 8.89922H0.5L3.4 12.3992L6.3 8.89922H4.4C4.4 7.19922 5.1 5.69922 6.2 4.59922C7.3 3.49922 8.9 2.79922 10.6 2.79922C12.3 2.79922 13.9 3.49922 15 4.59922C16.1 5.69922 16.8 7.29922 16.8 8.99922C16.8 10.6992 16.1 12.2992 15 13.3992C13.9 14.4992 12.3 15.1992 10.6 15.1992V17.2992C12.9 17.2992 15 16.3992 16.5 14.8992C18 13.3992 18.9 11.2992 18.9 8.99922Z" />
  </svg>
);

const ForwardRef = forwardRef(HistoryIcon);
export default ForwardRef;
