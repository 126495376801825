import { SVGProps, Ref, forwardRef } from "react";

const StarHalfIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Star Half Icon"
    {...props}
  >
    <path d="m22 9.24-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24ZM12 15.4V6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4Z" />
  </svg>
);

const ForwardRef = forwardRef(StarHalfIcon);
export default ForwardRef;
