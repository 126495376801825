import { SVGProps, Ref, forwardRef } from "react";

const VehicleConvertibleIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 61.2 17.8"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    {...props}
  >
    <path d="M9.9 9.2c-2.4 0-4.6 2-4.6 4.3 0 2.4 2 4.3 4.6 4.3 2.4 0 4.6-2 4.6-4.3s-2-4.3-4.6-4.3zm39.3 0c-2.6 0-4.6 2-4.6 4.3 0 2.4 2 4.3 4.6 4.3s4.6-2 4.6-4.3-2-4.3-4.6-4.3z" />
    <path d="M61.1 11.4c0-.3-.6-.3-.6-.5-.2-1.5-.2-3.1-.3-4.6-.2-1.8-4.1-1.8-6.4-2.6-.5-.2-1.1-.2-1.8 0 0-.2-.2-.3-.2-.5-.5-.8-1.5-1.1-2.3-.6-.6.3-3.4 1.8-4.6 2.8h-.6c-2.6.2-20.7.9-20.7.8 0-.2.2-.6.2-.9 0-.8-1.4-.8-2.3-.8 1.7-1.2 3.7-2.3 5.8-3.1 0 0 .2 0 .5-.2s.3-.3.5-.9c.2-.6-.6-.3-1.4-.2-3.4 1.1-6.4 2.9-9.8 4.4-4.2.6-8.9 1.4-13.2 2.4-1.4.3-2 .9-2.4 1.7-.5 1-.7 1.7-1 2.2-.3 0-.3 0-.5.2s0 .5 0 .8c0 .8 0 1.7.2 2.3.2.6.3.9.6 1.2.3.2.6.3.9.3h1.5c.9-.2.8-.3.6-1.4v-.5c0-3.1 2.6-5.7 6-5.7s6 2.4 6 5.7v.9c-.2 1.8-.3 1.5 1.5 1.7h25c1.4-.2 1.2 0 1.1-1.5v-.9c0-3.1 2.6-5.7 6-5.7s6 2.4 6 5.7v.8c-.2 1.2.3 1.2 1.2 1.1.9 0 2-.2 2.8-.3.6-.2 1.2-.3 1.4-.5.5-.7.3-2.7.3-3.6z" />
  </svg>
);

const ForwardRef = forwardRef(VehicleConvertibleIcon);
export default ForwardRef;
