import { SVGProps, Ref, forwardRef } from "react";

const BrandsLexusIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 100 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Lexus Icon"
    {...props}
  >
    <path
      d="M51.1 13.8c-3 3.6-11.4 15.4-12.3 16.9-1.9 2.9-2 4.3-2.1 5.3-.1.9-.1 2.6 1.5 3.9 2.5 2.2 6.1 1.9 9.2 1.9h25.8c1.1 0 1.4-.3 2-1.1 2.1-3.2 3-6.6 3-9.6 0-6.6-4.3-14.8-15-18.6L62 14.2c8.5 4.1 12 9.4 12 16.8.1 2-.5 4.1-1.8 6.1H47.1c-1.5 0-1.7-1.7-1.2-2.7 2.2-3.9 4.5-7.4 7.2-11 2.5-3.4 5-6.6 7.7-9.9.7-.9.2-1.8-1.2-2.1-2.9-.6-6-1-9.7-1-20.6 0-28.1 11.8-28.1 20.6 0 9.5 7.6 20.5 28.2 20.5 11.4 0 18.9-3.7 23.3-8.4h-4.4c-3.8 3.5-10.3 6-18.9 6-17.5 0-23.9-9.8-23.9-18.3.1-7.5 7-17.9 24.3-18.3.7.1 1.2.7.7 1.3z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        fill: "#78819b",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsLexusIcon);
export default ForwardRef;
