import { SVGProps, Ref, forwardRef } from "react";

const BadgeIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Badge Icon"
    {...props}
  >
    <g>
      <polygon points="13.4,8.6 12,5.7 10.6,8.6 7.4,9 9.7,11.2 9.2,14.4 12,12.9 14.8,14.4 14.3,11.2 16.6,9 	" />
      <path
        d="M19.9,10.4c0-2.2-0.9-4.2-2.3-5.6c-1.4-1.4-3.4-2.3-5.6-2.3c-2.2,0-4.2,0.9-5.6,2.3C5,6.2,4.1,8.2,4.1,10.4
		c0,2.2,0.9,4.2,2.3,5.6c0.6,0.6,1.3,1.1,2.1,1.5v4.6l3.6-2.2l3.5,2.2l0-4.7c0.8-0.4,1.4-0.9,2-1.5C19,14.6,19.9,12.6,19.9,10.4z
		 M12,16.2c-1.6,0-3.1-0.7-4.1-1.7c-1.1-1.1-1.7-2.5-1.7-4.1c0-1.6,0.7-3.1,1.7-4.1c1.1-1.1,2.5-1.7,4.1-1.7c1.6,0,3.1,0.7,4.1,1.7
		c1.1,1.1,1.7,2.5,1.7,4.1c0,1.6-0.7,3.1-1.7,4.1C15.1,15.6,13.6,16.2,12,16.2z"
      />
    </g>
  </svg>
);
const ForwardRef = forwardRef(BadgeIcon);
export default ForwardRef;
