import { SVGProps, Ref, forwardRef } from "react";

const LoadingIconFlowerIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 48 48"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Loading Icon Flower Icon"
    {...props}
  >
    <g clipPath="url(#clip0_6246_129)">
      <path
        d="M46.8254 16.6359C46.0733 14.3212 44.1227 12.5657 41.3668 11.4347C41.1427 8.46407 40.0759 6.06643 38.1069 4.63588C36.1379 3.20531 33.528 2.93162 30.6336 3.63656C28.7063 1.36495 26.4339 0.0522766 24.0001 0.0522766C21.5663 0.0522766 19.2939 1.36495 17.3666 3.63653C14.4722 2.9316 11.8622 3.20531 9.89323 4.63586C7.92422 6.06643 6.85744 8.46404 6.63342 11.4346C3.87744 12.5656 1.92682 14.3211 1.1747 16.6359C0.422632 18.9505 0.968831 21.5173 2.5337 24.0523C0.968854 26.5872 0.422632 29.154 1.1747 31.4687C1.92682 33.7833 3.87744 35.5389 6.63342 36.6699C6.85744 39.6405 7.92422 42.0381 9.89323 43.4687C11.8622 44.8992 14.4722 45.1729 17.3666 44.468C19.2939 46.7396 21.5663 48.0522 24.0001 48.0522C26.4339 48.0522 28.7063 46.7396 30.6336 44.468C33.528 45.1729 36.1379 44.8992 38.1069 43.4687C40.076 42.0381 41.1427 39.6405 41.3668 36.6699C44.1227 35.5389 46.0733 33.7834 46.8254 31.4687C47.5775 29.154 47.0313 26.5872 45.4665 24.0523C47.0313 21.5173 47.5775 18.9506 46.8254 16.6359Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6246_129">
        <rect width="48" height="48" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(LoadingIconFlowerIcon);
export default ForwardRef;
