import { SVGProps, Ref, forwardRef } from "react";

const ComponentAdditionalVehicleIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Component Additional Vehicle Icon"
    {...props}
  >
    <title id="Component Additional Vehicle Icon">
      Component Additional Vehicle Icon
    </title>
    <path d="m19.04 10.052-1.12-3.34c-.16-.49-.48-.897-.88-1.223-.4-.326-.88-.489-1.44-.489H8.32c-.48 0-.96.163-1.36.489-.4.244-.72.733-.88 1.222l-1.12 3.34c-.32.164-.56.327-.72.571-.16.245-.24.57-.24.896v4.075c0 .57.32 1.14.8 1.385v1.874c0 .244.08.407.24.57a.72.72 0 0 0 .56.245h.8c.24 0 .4-.082.56-.245a.746.746 0 0 0 .24-.57v-1.63h9.6v1.63c0 .244.08.407.24.57a.72.72 0 0 0 .56.245h.8c.24 0 .4-.082.56-.245a.746.746 0 0 0 .24-.57v-1.874c.24-.163.48-.326.56-.57.16-.245.24-.49.24-.815v-4.075c0-.325-.08-.651-.24-.896-.24-.244-.48-.489-.72-.57ZM8.32 6.63h7.28c.32 0 .64.244.72.57l.88 2.689H6.72L7.6 7.2c0-.163.16-.326.24-.407.16-.082.32-.163.48-.163ZM6.8 14.778c-.16 0-.32 0-.48-.082-.16-.081-.24-.163-.4-.244-.08-.082-.16-.245-.24-.408-.08-.162-.08-.325-.08-.488 0-.163 0-.326.08-.49.08-.162.16-.244.24-.407.08-.081.24-.163.4-.244.16-.082.32-.082.48-.082.32 0 .64.163.88.326.24.245.32.57.32.897 0 .326-.16.651-.32.896-.24.163-.56.326-.88.326Zm10.4 0c-.16 0-.32 0-.48-.082-.16-.081-.24-.163-.4-.244-.08-.082-.16-.245-.24-.408-.08-.162-.08-.325-.08-.488 0-.163 0-.326.08-.49.08-.162.16-.244.24-.407.08-.081.24-.163.4-.244.16-.082.32-.082.48-.082.32 0 .64.163.88.326.24.245.32.57.32.897 0 .326-.16.651-.32.896-.24.163-.56.326-.88.326Z" />
  </svg>
);

const ForwardRef = forwardRef(ComponentAdditionalVehicleIcon);
export default ForwardRef;
