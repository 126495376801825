import { SVGProps, Ref, forwardRef } from "react";

const ComponentTransmissionIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Component Transmission Icon"
    {...props}
  >
    <title id="Component Transmission Icon">Component Transmission Icon</title>
    <path
      fillRule="evenodd"
      d="M6 6.488a1.333 1.333 0 1 0-1.333 0v11.024a1.333 1.333 0 1 0 1.333 0v-4.845h5.333v4.845a1.333 1.333 0 1 0 1.334 0v-4.845h6.666V6.488a1.333 1.333 0 1 0-1.333 0v4.845h-5.333V6.488a1.333 1.333 0 1 0-1.334 0v4.845H6V6.488Z"
      clipRule="evenodd"
    />
  </svg>
);

const ForwardRef = forwardRef(ComponentTransmissionIcon);
export default ForwardRef;
