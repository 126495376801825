import { SVGProps, Ref, forwardRef } from "react";

const FeatureIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    width="71"
    height="71"
    fill="none"
    ref={ref}
    aria-label="Feature Icon"
    {...props}
  >
    <path
      d="M65.076 35.5 71 44.375H57.443c-3.527 8.675-12.003 14.8-21.943 14.8-9.94 0-18.438-6.124-21.943-14.8H0L5.924 35.5 0 26.625h13.557C17.084 17.95 25.56 11.826 35.5 11.826c9.94 0 18.438 6.124 21.943 14.8H71L65.076 35.5Z"
      fill="#1976D2"
    />
    <path
      opacity=".2"
      d="M35.5 59.174c13.075 0 23.674-10.599 23.674-23.674S48.574 11.826 35.5 11.826c-13.075 0-23.674 10.6-23.674 23.674 0 13.075 10.6 23.674 23.674 23.674Z"
      fill="#fff"
    />
    <path
      d="m53.25 35.5-5.88 5.88v5.99h-5.99l-5.88 5.88-5.88-5.88h-5.924v-5.924l-5.924-5.924 5.924-5.946v-5.88h5.88L35.5 17.75l5.946 5.946h5.924v5.924l5.88 5.88Z"
      fill="#FFCA28"
    />
    <path
      opacity=".2"
      d="M35.5 17.75v35.5l-5.88-5.88h-5.924v-5.924l-5.924-5.924 5.924-5.946v-5.88h5.88L35.5 17.75Z"
      fill="#fff"
    />
  </svg>
);

const ForwardRef = forwardRef(FeatureIcon);
export default ForwardRef;
