import { SVGProps, Ref, forwardRef } from "react";

const BrandsJeepIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 100 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Jeep Icon"
    {...props}
  >
    <path
      d="M31.3 32.6c0 6.9-3.2 9.5-9.5 9.5s-9.3-3.5-9.3-9v-3.4h7v2.8c0 1.9 0 3.7 2.1 3.7s1.9-2.4 1.9-3.5v-16h7.8v15.9zm7-2.1c.3-1.9 1.4-3 3.2-3 1.6 0 2.8 1.4 2.8 3h-6zm12.1 3.6C50.4 27 47.5 23 41 23c-5.3 0-8.7 4.3-8.7 9.5 0 6.1 4 9.5 9.2 9.5 3.8 0 7.3-1.8 8.5-5.7h-5.9c-.5.8-1.6 1.3-2.6 1.3-2 0-3.1-1.5-3.3-3.5h12.2zm6.5-3.6c.3-1.9 1.4-3 3.2-3 1.6 0 2.8 1.4 2.8 3h-6zM69 34.1C69 27 66.1 23 59.5 23c-5.3 0-8.7 4.3-8.7 9.5 0 6.1 4 9.5 9.2 9.5 3.7 0 7.3-1.8 8.5-5.7h-5.9c-.5.8-1.6 1.3-2.6 1.3-2 0-3.1-1.5-3.3-3.5H69zm6.7-1.5c0-2.2.5-4.4 2.7-4.4 2.3 0 2.8 2.3 2.8 4.4s-.6 4.4-2.8 4.4c-2.3 0-2.7-2.4-2.7-4.4zm-6.1 14.8h6.3v-7.8h.1c.9 1.5 2.6 2.4 4.5 2.4 5.5 0 7-5.2 7-9.4 0-4.5-2.2-9.6-6.9-9.6-3.1 0-4.1 1.3-4.9 2.4v-2h-6.1v24zm15-3.2c0-.8.5-1.4 1.3-1.4s1.3.5 1.3 1.4c0 .7-.5 1.3-1.3 1.3s-1.3-.5-1.3-1.3zm-.3 0c0 .8.7 1.5 1.6 1.5.8 0 1.5-.7 1.5-1.5 0-.9-.7-1.5-1.5-1.5-.9 0-1.6.6-1.6 1.5zm1 .9h.3v-.8h.4l.5.8h.4l-.5-.8c.3 0 .5-.2.5-.5s-.2-.5-.6-.5h-.7l-.3 1.8zm.2-1.6h.5c.2 0 .4.1.4.3 0 .3-.3.4-.5.4h-.4v-.7z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        fill: "#78819b",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsJeepIcon);
export default ForwardRef;
