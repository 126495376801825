import { SVGProps, Ref, forwardRef } from "react";

const VehicleMinivanIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 61 25"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Vehicle Minivan Icon"
    {...props}
  >
    <path
      d="M48.5 15.1c2.5 0 4.5 2 4.5 4.5s-2 4.5-4.5 4.5-4.5-2-4.5-4.5 2-4.5 4.5-4.5zm6.4 6.5c1-.1 2.3-.2 3.3-.4.6-.1 1.1-.3 1.3-.5.7-.7.6-2.8.6-3.9 0-.3-.6-.3-.6-.5-.1-1.7-.2-3.4-.6-5.1-.3-1.3-1-2.2-1.5-3.2-.4-.9-1.5-3.8-3-5.5-.2-.2-.2-.6-.2-.9 0-.7-.1-.6-.8-.7-.8-.1-2.1 0-3.8 0L29 1c-1 0-1.9.1-2.7.2-4.6.3-10.1 4.2-14.1 6.6-2.2.8-5.6 2-8.5 3.1-1.2.4-1.6 1-1.9 1.8-.2.5-.4 1.1-.6 2-.1.5-.3 1-.5 1.3-.3 0-.3-.1-.4.2-.1.2 0 .5-.1.9-.1.8 0 2 .1 2.7.2.7.3 1.1.7 1.3.2.2.5.4.9.4l1.4.1c1-.1 1 .1.8-1.1 0-.3-.1-.5-.1-.8 0-3.2 2.6-5.8 5.8-5.8 3.2 0 5.8 2.6 5.8 5.8 0 .6-.1 1.1-.2 1.6-.3 1.1-.2.9.7 1h25.6c.9-.1 1.3.2 1-1.1-.1-.5-.2-1-.2-1.5 0-3.2 2.6-5.8 5.8-5.8 3.2 0 5.8 2.6 5.8 5.8 0 .4 0 .7-.1 1 .1.6.3.9.9.9zM31.1 8.4c.4-3.3.4-4.8.7-5.6 0-.1.3-.2.5-.3l8-.1c.5 0 .8 0 .8.7l.1 4.7c0 .6.1.9-.6.9-3.1.2-5.4.3-8.6.3-.9 0-1 .3-.9-.6zM18.2 9.5c0-.1.2-.6.2-1.1 0-.9-1.4-.9-2.3-.9 2.7-2.3 7.5-4.9 11-4.9h2.8c.3 0 .4.2.3.5-.2 1.1-.5 3.6-.7 5.1-.1.7-.3.9-.9.9-2.6 0-10.5.5-10.4.4zm24.5-1.4c-.1-1.5-.1-3-.2-4.6 0-.2.1-.4.1-.6 0-.1.3-.2.4-.3h2.8c.5 0 3.5.6 4.5 1.5 1.3 1.2 2.7 2.7 3 3.3.3.5.1.9-.5.9-3.2.1-6.1.4-9.3.5-.8-.1-.8.2-.8-.7zm-32.8 7c2.5 0 4.5 2 4.5 4.5s-2 4.5-4.5 4.5-4.5-2-4.5-4.5 2-4.5 4.5-4.5z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(VehicleMinivanIcon);
export default ForwardRef;
