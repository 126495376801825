import { SVGProps, Ref, forwardRef } from "react";

const FavouriteThreeIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 120 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-label="Favourite Three Icon"
    {...props}
  >
    <path
      d="M28.801 14.4528C26.3135 12.0712 22.2806 12.0712 19.7931 14.4528L15.9999 18.0845L12.2066 14.4528C9.71916 12.0712 5.68622 12.0712 3.19886 14.4528C0.711384 16.8343 0.711384 20.6956 3.19886 23.0771L15.9999 35.3333L28.801 23.0771C31.2885 20.6956 31.2885 16.8343 28.801 14.4528Z"
      fill="#FB637E"
      stroke="#FB637E"
      strokeWidth="2"
    />
    <path
      d="M76.0014 8.06611C72.8921 5.08913 67.8509 5.08913 64.7416 8.06611L60.0001 12.6058L55.2585 8.06611C52.1491 5.08913 47.108 5.08913 43.9988 8.06611C40.8894 11.043 40.8894 15.8695 43.9988 18.8465L60.0001 34.1667L76.0014 18.8465C79.1107 15.8695 79.1107 11.043 76.0014 8.06611Z"
      fill="#FB637E"
      stroke="#FB637E"
      strokeWidth="2"
    />
    <path
      d="M116.801 14.4528C114.314 12.0712 110.281 12.0712 107.793 14.4528L104 18.0845L100.207 14.4528C97.7192 12.0712 93.6862 12.0712 91.1989 14.4528C88.7114 16.8343 88.7114 20.6956 91.1989 23.0771L104 35.3333L116.801 23.0771C119.288 20.6956 119.288 16.8343 116.801 14.4528Z"
      fill="#FB637E"
      stroke="#FB637E"
      strokeWidth="2"
    />
  </svg>
);

const ForwardRef = forwardRef(FavouriteThreeIcon);
export default ForwardRef;
