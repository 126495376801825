import { SVGProps, Ref, forwardRef } from "react";

const MapPinIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Map Pin Icon"
    {...props}
  >
    <path
      d="M12,22c0.2,0,7.9-6.2,7.9-12.1C19.9,5.5,16.3,2,12,2S4.1,5.5,4.1,9.9C4.1,16.7,11.8,22,12,22L12,22z M12,5.9
	c2.2,0,3.9,1.8,3.9,3.9s-1.8,3.9-3.9,3.9s-3.9-1.8-3.9-3.9S9.8,5.9,12,5.9z"
    />
  </svg>
);

const ForwardRef = forwardRef(MapPinIcon);
export default ForwardRef;
