import { SVGProps, Ref, forwardRef } from "react";

const AppGoogleIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="App Google Icon"
    {...props}
  >
    <path d="M19.679 10.545h-7.636v3.273h4.328c-.692 2.182-2.401 2.91-4.363 2.91a4.727 4.727 0 1 1 3.035-8.347l2.378-2.265A8 8 0 1 0 12.008 20c4.41 0 8.4-2.909 7.67-9.455Z" />
  </svg>
);

const ForwardRef = forwardRef(AppGoogleIcon);
export default ForwardRef;
