import { SVGProps, Ref, forwardRef } from "react";

const ArrowDiagonalUpRightIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 20 20"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Arrow Diagonal Up Right Icon"
    {...props}
  >
    <path d="M6.8,21.1l-3.9-3.9l8.9-8.9H4.9V3.4h15.7v15.7h-4.8v-6.9L6.8,21.1z" />{" "}
  </svg>
);

const ForwardRef = forwardRef(ArrowDiagonalUpRightIcon);
export default ForwardRef;
