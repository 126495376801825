import { SVGProps, Ref, forwardRef } from "react";

const CircleQuarterIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Circle Quarter Icon"
    {...props}
  >
    <title id="Circle Quarter Icon">Circle Quarter Icon</title>
    <path d="M12.91 2H12v10h10v-.91A9.09 9.09 0 0 0 12.91 2Z" />
  </svg>
);

const ForwardRef = forwardRef(CircleQuarterIcon);
export default ForwardRef;
