import { SVGProps, Ref, forwardRef } from "react";

const VehicleTruckIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 61 23"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Vehicle Truck Icon"
    {...props}
  >
    <path
      d="M60 17c.2-.7.1-1.9.1-2.6 0-.9-.4-.6-.7-.9-.1-1.3 0-3.4-.2-4.8-.1-1.5-.6-1.7-2.4-1.7H35.7c-.5 0-.7-.1-.8-.2-.4-.2-.6-3.2-1-4.9-.2-.9-.2-1.4-1.3-1.4h-9.4c-1.4 0-2.8.6-4 1.6l-5.4 4.2c-4 .3-8.2 1-11.5 1.8-.9.4-1.3.9-1.3 2v3.6c-.2 0-.3 0-.5.1-.3.1-.3.2-.4.5v.7c0 .6.1 2.3.5 2.6.2.2.6.3.9.3h1c.7-.1.9-.4 1-1.2.5-2.8 3-4.8 5.9-4.8 3.1 0 5.7 2.3 6 5.2.1.8.6.8 1.2.8h24.3c.5 0 .6-.3.7-.7.2-3 2.8-5.3 6-5.3s5.7 2.3 6 5.2c.1.8.1.9.8.9h2c.7 0 3.4-.1 3.6-1zM29.4 6.6c.3-2.2.4-3.8.5-4.4 0-.3.1-.4.4-.4h1.4c.1 0 .2.3.2.6l.2 3.9c.1 1 0 .8-.6.8H30c-.7.1-.7.1-.6-.5zm-10.8.6c-.3 0 .2-.2.2-.6 0-.7-1.2-.7-2-.7L20 3.1c.5-.4.8-.6 1.3-.8.8-.4 1.5-.6 2.2-.6h4.7c.3 0 .3.1.3.4-.1.9-.4 3.3-.6 4.4-.1.5-.2.7-.8.7h-8.5zm-9.1 5.9c2.7 0 4.8 2.1 4.8 4.6 0 2.6-2.2 4.6-4.8 4.6-2.7 0-4.8-2.1-4.8-4.6-.1-2.6 2.1-4.6 4.8-4.6zm38.1 0c2.7 0 4.8 2.1 4.8 4.6 0 2.6-2.2 4.6-4.8 4.6-2.7 0-4.8-2.1-4.8-4.6-.1-2.6 2.1-4.6 4.8-4.6z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(VehicleTruckIcon);
export default ForwardRef;
