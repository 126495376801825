import { SVGProps, Ref, forwardRef } from "react";

const ComponentTransferCaseIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Component Transfer Case Icon"
    {...props}
  >
    <title id="Component Transfer Case Icon">
      Component Transfer Case Icon
    </title>
    <path
      fillRule="evenodd"
      d="M13.333 16v-5.167l1.333-1.5V4H9.333v5.333l1.333 1.5V16H8v-1.333A.667.667 0 0 0 7.333 14H4.667a.667.667 0 0 0-.667.667v4.666c0 .369.298.667.667.667h2.666A.667.667 0 0 0 8 19.333V18h8v1.333c0 .369.299.667.667.667h2.666a.667.667 0 0 0 .667-.667v-4.666a.667.667 0 0 0-.667-.667h-2.666a.667.667 0 0 0-.667.667V16h-2.667Zm0-10.667h-2.666V8.32L12 10l1.334-1.68V5.333Z"
      clipRule="evenodd"
    />
  </svg>
);

const ForwardRef = forwardRef(ComponentTransferCaseIcon);
export default ForwardRef;
