import { SVGProps, Ref, forwardRef } from "react";

const ContractIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 75 73"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Contract Icon"
    {...props}
  >
    <title id="Contract Icon">Contract Icon</title>
    <path d="M9 9h36v4H9zm0 8h36v4H9zm0 8h36v4H9zm17 28h19v4H26z" />
    <path
      fillRule="evenodd"
      d="M0 4v65h54V0H0v4Zm50 61V4H4v61h46Z"
      clipRule="evenodd"
    />
    <path d="m65.227 35.213-15.12 15.12H44v-6.106L59.12 29.08l6.107 6.133Zm9.066-2.08c-.026.8-.853 1.627-1.706 2.454l-6.72 6.746-2.32-2.32 6.933-6.906-1.573-1.574-1.787 1.787-6.107-6.107 5.734-5.733c.64-.64 1.68-.64 2.293 0l3.813 3.813c.64.587.64 1.654 0 2.294-.56.56-1.093 1.093-1.093 1.626-.053.534.48 1.12 1.013 1.574.774.8 1.547 1.546 1.52 2.346Z" />
    <path d="M58 9v36h-4V9zm0 36v24h-4V45zM8 69h36v4H8zm36 0h14v4H44z" />
  </svg>
);

const ForwardRef = forwardRef(ContractIcon);
export default ForwardRef;
