import { SVGProps, Ref, forwardRef } from "react";

const SunroofIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Sunroof Icon"
    {...props}
  >
    <path
      d="m3.4 20.9 8.6-8.6 8.6 8.6z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
    />
    <path d="M11.3 2.2h1.4v5.7h-1.4zM5.832 4.246l1.126-.833 3.39 4.583-1.126.832zM2.692 9.23 3.1 7.89l5.45 1.667-.408 1.34zm-.072 4.678 5.374-1.901.467 1.32-5.373 1.901zm12.896-.522.467-1.32 5.373 1.902-.467 1.32zM13.73 8.053l3.39-4.582 1.126.832-3.39 4.583zm1.788 1.504 5.45-1.667.41 1.34-5.451 1.666z" />
  </svg>
);

const ForwardRef = forwardRef(SunroofIcon);
export default ForwardRef;
