import { SVGProps, Ref, forwardRef } from "react";

const BrandsScionIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="a"
    x={0}
    y={0}
    aria-hidden="true"
    viewBox="0 0 100 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Scion Icon"
    {...props}
  >
    <style>{"\r\n    .st0{fill:#78819b}\r\n  "}</style>
    <path
      d="M61.2 29.4h-7c-.3 0-.5.2-.5.4v2.4c0 .2.2.4.5.4h7c.3 0 .5-.2.5-.4v-2.4c-.1-.2-.3-.4-.5-.4z"
      className="st0"
    />
    <path
      d="M50 8.9c-17.7 0-32 9.9-32 22.1s14.4 22.1 32 22.1c8.5 0 16.6-2.3 22.6-6.5C78.7 42.5 82 36.9 82 31 82 18.8 67.7 8.9 50 8.9zm.6 3.4c12.2.2 22.2 6.5 24.7 14.8H53c-4-3.2-2.9-12-2.4-14.8zm12.6 17.5v2.4c0 .7-.6 1.3-1.4 1.3h-8.4c-.8 0-1.4-.6-1.4-1.3v-2.4c0-.7.6-1.3 1.4-1.3h8.4c.8 0 1.4.6 1.4 1.3zM50 12.3c-1.9 2.1-6.4 8-7.5 14.8H24.7c2.5-8.5 12.9-14.8 25.3-14.8zM38.8 32.6h9.1v.9h-9.8c-.8 0-1.4-.6-1.4-1.3v-2.4c0-.7.6-1.3 1.4-1.3h9.8v.9h-9.1c-.3 0-.5.2-.5.4v2.4c.1.2.3.4.5.4zm-14.5 0h9.2c.3 0 .5-.2.5-.4v-.8h-9.6v-1.7c0-.7.6-1.3 1.4-1.3h9.8v.9h-9.2c-.3 0-.4.2-.4.4v.8h9.6v1.7c0 .7-.6 1.3-1.4 1.3h-8.9c-.6 0-1-.4-1-.9zm25.1 17.1c-12.2-.2-22.3-6.4-24.7-14.7H47c4 3.1 2.9 11.9 2.4 14.7zm-.2-16.2v-5.1h1.6v5.1h-1.6zm.8 16.2c1.9-2.1 6.4-8 7.5-14.8h17.8C72.8 43.4 62.4 49.7 50 49.7zm25.7-17.1c0 .5-.4.9-1 .9h-.6v-3.7c0-.2-.2-.4-.5-.4H66v4.2h-1.6v-5.1h9.9c.8 0 1.3.6 1.3 1.3v2.8h.1z"
      className="st0"
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsScionIcon);
export default ForwardRef;
