import { SVGProps, Ref, forwardRef } from "react";

const ComponentEngineIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Component Engine Icon"
    {...props}
  >
    <title id="Component Engine Icon">Component Engine Icon</title>
    <path
      fillRule="evenodd"
      d="M15.333 7.333V6h-8v1.333h2v1.334h4V7.333h2ZM8.667 8.667A.667.667 0 0 0 8 9.333h-.666a.667.667 0 0 0-.667.667v6.667c0 .368.298.666.667.666H8c0 .369.298.667.667.667h6.666a.667.667 0 0 0 .667-.667v-.666h.667a.667.667 0 0 0 .666-.667v-.667h1.333v-4h-1.333v-.666a.667.667 0 0 0-.666-.667H16v-.667a.667.667 0 0 0-.667-.666H8.667ZM5.333 10H4v6.667h1.333v-2h1.333V12H5.333v-2ZM20 10h-1.333v6.667H20V10Z"
      clipRule="evenodd"
    />
  </svg>
);

const ForwardRef = forwardRef(ComponentEngineIcon);
export default ForwardRef;
