import { SVGProps, Ref, forwardRef } from "react";

const ComponentElectricalIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Component Electrical Icon"
    {...props}
  >
    <title id="Component Electrical Icon">Component Electrical Icon</title>
    <path d="M7 13.057 12.711 4h.14v.15l-1.533 6.34v.152h4.876s.14 0 .14.15v.151L10.621 20h-.14v-.15l1.672-6.19v-.15H7.14C7 13.357 7 13.206 7 13.056Z" />
  </svg>
);

const ForwardRef = forwardRef(ComponentElectricalIcon);
export default ForwardRef;
