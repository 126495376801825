import { SVGProps, Ref, forwardRef } from "react";

const ArrowUpIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Arrow Up Icon"
    {...props}
  >
    <path d="m4 12 1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8Z" />
  </svg>
);

const ForwardRef = forwardRef(ArrowUpIcon);
export default ForwardRef;
