import { SVGProps, Ref, forwardRef } from "react";

const BrandsBuickIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="a"
    aria-hidden="true"
    viewBox="0 0 100 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Buick Icon"
    {...props}
  >
    <defs>
      <style>{"\r\n      .b{fill:#78819b}\r\n    "}</style>
    </defs>
    <path
      d="M50 .12c-17.06 0-30.89 13.82-30.89 30.87s13.83 30.89 30.88 30.89 30.89-13.83 30.89-30.89S67.06.12 50 .12Zm0 58.82c-15.43 0-27.94-12.51-27.94-27.94S34.56 3.06 50 3.06 77.94 15.57 77.94 31 65.42 58.94 50 58.94Z"
      className="b"
    />
    <path
      d="M55.3 16.82H43.14v4.42H30.97v15.72c0 6.57 4.19 13.07 6.97 17.71 2.35-3.94 5.75-9.17 6.74-14.67 1.47 3.84 3.71 7.39 5.42 10.25 2.35-3.94 5.75-9.18 6.74-14.68 1.47 3.83 3.71 7.39 5.42 10.26 2.78-4.66 7.03-11.15 7.03-17.72V12.4H55.3v4.42ZM42.94 37.76c0 4.72-3 9.53-5 12.87-1.99-3.34-4.92-8.16-4.92-12.87V23.27h9.92v14.49Zm12.16-4.43c0 4.72-3 9.55-5 12.88-2.01-3.34-4.93-8.17-4.93-12.88V18.85h9.93v14.48Zm2.23-18.91h9.94v14.49c0 4.71-3.01 9.54-5.01 12.89-2-3.35-4.93-8.18-4.93-12.89v-14.5Z"
      className="b"
    />
    <path
      d="M42.33 36.18V23.86h-8.52l8.47 14.92c.16-.86.06-1.83.06-2.6ZM41.14 43l-7.53-13.12v7.1c0 4.12 1.66 8.02 4.33 12.37 1.35-2.2 2.45-4.29 3.19-6.35Zm13.35-11.24V19.43h-8.51l8.46 14.93c.15-.85.05-1.83.05-2.6Zm-1.19 6.81-7.53-13.12v7.1c0 4.13 1.66 8.03 4.33 12.37 1.35-2.2 2.45-4.29 3.2-6.35Zm13.35-11.24V15h-8.52l8.46 14.95c.15-.87.06-1.84.06-2.62Zm-8.72-6.29v7.1c0 4.12 1.67 8.03 4.33 12.38 1.35-2.21 2.46-4.3 3.2-6.35l-7.53-13.12Z"
      className="b"
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsBuickIcon);
export default ForwardRef;
