import { SVGProps, Ref, forwardRef } from "react";

const SettingsIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Settings Icon"
    {...props}
  >
    <path d="M19.301 12.431c.038-.304.067-.608.067-.931 0-.323-.029-.627-.067-.931l2.005-1.568a.48.48 0 0 0 .114-.607l-1.9-3.288a.477.477 0 0 0-.58-.208l-2.365.95a6.94 6.94 0 0 0-1.606-.931l-.36-2.518A.463.463 0 0 0 14.142 2h-3.8a.463.463 0 0 0-.466.399l-.36 2.518c-.58.237-1.112.56-1.606.93l-2.366-.95a.463.463 0 0 0-.58.21l-1.9 3.286a.468.468 0 0 0 .115.608l2.004 1.568a7.534 7.534 0 0 0-.066.931c0 .313.028.627.066.931L3.18 13.999a.48.48 0 0 0-.114.607l1.9 3.287c.114.21.37.285.58.21l2.365-.95a6.94 6.94 0 0 0 1.605.93l.361 2.518a.463.463 0 0 0 .466.399h3.8a.463.463 0 0 0 .465-.399l.361-2.517a7.299 7.299 0 0 0 1.606-.931l2.365.95c.219.085.466 0 .58-.21l1.9-3.287a.48.48 0 0 0-.114-.607L19.3 12.43Zm-7.058 2.394A3.329 3.329 0 0 1 8.918 11.5a3.329 3.329 0 0 1 3.325-3.325 3.329 3.329 0 0 1 3.325 3.325 3.329 3.329 0 0 1-3.325 3.325Z" />
  </svg>
);

const ForwardRef = forwardRef(SettingsIcon);
export default ForwardRef;
