import { SVGProps, Ref, forwardRef } from "react";

const BrandsFordIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="a"
    x={0}
    y={0}
    aria-hidden="true"
    viewBox="0 0 100 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Ford Icon"
    {...props}
  >
    <style>
      {"\r\n    .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#78819b}\r\n  "}
    </style>
    <path
      d="M50 16.4C28.7 16.4 11.5 23 11.5 31S28.7 45.6 50 45.6 88.5 39.1 88.5 31 71.3 16.4 50 16.4zm0 27.7c-20.4 0-37-5.8-37-13.1s16.6-13.1 37-13.1S87 23.8 87 31 70.5 44.1 50 44.1z"
      className="st0"
    />
    <path
      d="M46.9 27.5c.1-.1.4-.6.3-.9 0-.1-.1-.3-.3-.3-.4.1-.6.2-.7.6-.1.4-.1.7 0 1 .1 0 .3 0 .7-.4z"
      className="st0"
    />
    <path
      d="M50 19c-19.8 0-35.8 5.4-35.8 12s16 12 35.8 12 35.8-5.4 35.8-12-16-12-35.8-12zM32.5 38.4c-2.2 0-4.1-1.4-4.1-3.9 0-1.5 1.2-3.4 3.5-4 .3 0 .4.1.4.2s-.1.2-.3.3c-.7.2-1.8 1.6-1.8 2.8 0 .9.6 1.9 1.5 2.2.3.1.5 0 .8 0 2.4 0 5.1-2.7 8-6.8-.5 0-2.5.2-3 .8-.2.2-.4.1-.4-.1 0 0 .1-.2.1-.3.8-.9 3.2-1.1 3.7-1.2 2.1-3.3 4.9-5.4 5.6-5.5h-.1c-1.9-.4-3.6-.8-5-.8-4.8 0-6.9 2.1-6.8 3.9 0 .8.7 1.3 1.6 1.1 1-.2 2.4-1.1 2.7-1.9.1-.2.3-.2.4-.2.1.1.1.3.1.3-.1 1-1.6 3.6-4.2 3.6-1.4 0-2.4-1.3-2.4-2.7 0-2.5 2.5-4.9 8.7-4.9 1.2 0 2.5.1 3.9.3 1.7.3 5.8 1 9.2 1 1.2 0 2.8 0 4.1-.1 1.4-.2 1.1-.3 1.6-.3s.5.2.5.4c0 .8-2.8 2.3-5.1 2.3-3.5 0-7.1-1.6-9-2.1.1.1.1.2.1.3l-.4.4c-.5.4-2.2 1.5-4 4.6 1.5-.1 3.2-.4 3.2-.4s-.1-.3-.1-.6c.1-1.1.7-1.7 1.5-1.6.4.1.7.4.7.8s-.3 1-.6 1.3c-.2.2-.5.3-.8.5 0 .3.4.5.7.5-.1.3 0 .4-.2.4-.7 0-1-.7-1-.7-1 .2-2 .3-3.9.5-4.9 8.3-6.4 9.6-9.4 9.6zm34-1.3c1.1 0 3-2.1 3.5-2.6.1-.1.3-.3.4-.3.1 0 .3.1.3.2s-.2.5-.4.7c-1 1.2-2.6 3.2-4.5 3.2-.9 0-1.3-.4-1.4-1.1-.4.4-1.9 1-3.3 1-1.2 0-1.7-.7-2-1.3-.2-.5-.3-1.2-.1-1.9-.7.3-1.3.4-2.2.4-1 0-1.7-.5-2-1.7-1.2 2.2-1.2 2.3-2.5 4.5h-2.1l2.7-4.6c.3-.6.5-1.1.6-1.6.1-.3-.1-.6-.5-.6-.3 0-.9.3-1.5.9 0 0-1.4 1.5-2.4 3-1 1.4-2.6 3.1-4.4 3.1-1.3 0-2.5-.7-2.8-2.1 0 0-.1.3-1.3 1-.1 0-.3.2-.4.1-.2-.2-.3-.4-.4-.6.1 0 .2-.1.7-.5.9-.7 1.1-1.2 1.1-1.2.2-.5.6-1.2.9-1.7.7-1.5 2-3 4.1-3.1.3 0 .4.1.5.3.2.1.1.4.1.4-1.5.2-2.5 1.1-3.1 1.9-.4.5-.8 1.4-.8 2.1-.1 1.1.4 1.9 1.1 2 1.4.2 2.6-.8 3.6-2.4.2-.4.4-1.5.4-2.1 0-.6-.3-1.2-.6-1.5 0-.4.3-.7.6-.7.1 0 .2 0 .3.1.5.5.8 1.4.8 2.2v.7c1-1.3 2.4-2.9 3.4-3.2.2-.1.4-.1.6-.1 1.9 0 1.6 1.6 1.6 1.6 1-1.1 1.7-1.6 2.6-1.5 1 0 1.3.7 1.3 1.3 0 .6-.5 1.4-1.3 1.4-.7 0-1.1-.3-1.4-1-.3.2-.5.6-.6 1.2-.1.8.5 1.5 1.3 1.5 1.4 0 2-.6 2.5-1 1.7-1.6 2.6-3.2 5.1-3.2 1.5 0 1.8 1.2 2.1 1.7l3.2-5.4h2L66.6 36c-.1.2-.3.6-.3.9-.2.3.4.2.2.2z"
      className="st0"
    />
    <path
      d="M46.6 22.9s.1.1.2.1c-.1-.1-.1-.1-.2-.1zm18 8.5c-.9-.1-2 .6-2.9 1.7-.4.5-.9 1.6-1 2.2-.1.6.1 1.2.3 1.4.2.2 1.2.7 2.8-.9.9-.9 1.7-2.3 1.7-2.3l-.3-.1c.5-.9.4-1.9-.6-2z"
      className="st0"
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsFordIcon);
export default ForwardRef;
