import { SVGProps, Ref, forwardRef } from "react";

const BookmarkOnIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Bookmark On Icon"
    {...props}
  >
    <path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2Z" />
  </svg>
);

const ForwardRef = forwardRef(BookmarkOnIcon);
export default ForwardRef;
