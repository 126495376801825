import { SVGProps, Ref, forwardRef } from "react";

const RearCameraIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Rear Camera Icon"
    {...props}
  >
    <path d="M12 2.5c-5.2 0-9.5 4.2-9.5 9.5s4.2 9.5 9.5 9.5 9.5-4.2 9.5-9.5-4.3-9.5-9.5-9.5zm.1 13.7c-2.3 0-4.1-1.8-4.1-4.1S9.8 8 12.1 8s4.1 1.8 4.1 4.1-1.8 4.1-4.1 4.1zm4.7-8.3c-.4 0-.8-.3-.8-.8s.3-.8.8-.8c.4 0 .8.3.8.8s-.4.8-.8.8z" />
  </svg>
);

const ForwardRef = forwardRef(RearCameraIcon);
export default ForwardRef;
