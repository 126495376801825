import { SVGProps, Ref, forwardRef } from "react";

const ArrowDiagonalDownRightIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 20 20"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Arrow Diagonal Down Right Icon"
    {...props}
  >
    <path d="M2.9,7.4l3.9-3.9l8.9,8.9V5.4h4.8v15.7H4.9v-4.8h6.9L2.9,7.4z" />
  </svg>
);

const ForwardRef = forwardRef(ArrowDiagonalDownRightIcon);
export default ForwardRef;
