import { SVGProps, Ref, forwardRef } from "react";

const KeylessIgnitionIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Keyless Ignition Icon"
    {...props}
  >
    <path d="M18.7 5.3A9.476 9.476 0 1 0 5.3 18.702 9.476 9.476 0 0 0 18.7 5.3zM12 19.2c-4 0-7.2-3.2-7.2-7.2S8 4.8 12 4.8 19.2 8 19.2 12 16 19.2 12 19.2z" />
    <path d="m14.9 8.6-.8 1c.8.6 1.3 1.5 1.3 2.6 0 1.8-1.5 3.2-3.4 3.2S8.6 14 8.6 12.2c0-1 .5-2 1.3-2.6l-.8-1c-1.2.9-1.9 2.2-1.9 3.6 0 2.5 2.1 4.5 4.8 4.5s4.8-2 4.8-4.5c0-1.4-.7-2.7-1.9-3.6z" />
    <path d="M11.3 7.2h1.4v4.6h-1.4z" />
  </svg>
);

const ForwardRef = forwardRef(KeylessIgnitionIcon);
export default ForwardRef;
