import { SVGProps, Ref, forwardRef } from "react";

const OpenNewIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Open New Icon"
    {...props}
  >
    <path
      d="M18.6,18.6H5.4V5.4H12V3.5H5.4c-1,0-1.9,0.9-1.9,1.9v13.2c0,1,0.8,1.9,1.9,1.9h13.2c1,0,1.9-0.9,1.9-1.9V12h-1.9V18.6z
	 M13.9,3.5v1.9h3.4L8,14.7L9.3,16l9.3-9.3v3.4h1.9V3.5H13.9z"
    />
  </svg>
);
const ForwardRef = forwardRef(OpenNewIcon);
export default ForwardRef;
