import { SVGProps, Ref, forwardRef } from "react";

const PriceTagIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 34 34"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Price Tag Icon"
    {...props}
  >
    <title id="Price Tag Icon">Price Tag Icon</title>
    <path
      d="M23.7011 9.11168L13.9091 8.24602C13.3299 8.19453 12.7589 8.40209 12.348 8.80918L2.47987 18.6259C1.54968 19.5511 1.54968 21.0524 2.47987 21.9792L10.7658 30.2207C11.696 31.1459 13.2053 31.1459 14.1372 30.2207L24.0069 20.4039C24.4178 19.9952 24.6248 19.4272 24.5731 18.8512L23.7027 9.11168H23.7011Z"
      fill="#293EDC"
    />
    <path
      d="M20.3226 14.037C21.2321 14.037 21.9695 13.3036 21.9695 12.399C21.9695 11.4943 21.2321 10.761 20.3226 10.761C19.4131 10.761 18.6758 11.4943 18.6758 12.399C18.6758 13.3036 19.4131 14.037 20.3226 14.037Z"
      fill="white"
    />
    <path
      d="M25.2973 2.63525C21.6024 2.63525 18.5967 5.62485 18.5967 9.29989C18.5967 10.5083 18.9251 11.6909 19.5463 12.7223C19.776 13.1037 20.2726 13.2276 20.656 12.9991C21.0394 12.7706 21.164 12.2766 20.9343 11.8953C20.4635 11.1149 20.2144 10.217 20.2144 9.3015C20.2144 6.51465 22.4938 4.2459 25.2973 4.2459C28.1008 4.2459 30.3801 6.51304 30.3801 9.3015C30.3801 12.09 28.1008 14.3571 25.2973 14.3571C25.0368 14.3571 24.7764 14.3378 24.5224 14.2992C24.3994 14.2799 24.2797 14.2927 24.1681 14.3265L24.3088 15.8937C24.634 15.942 24.9673 15.9661 25.2989 15.9661C28.9938 15.9661 31.9995 12.9765 31.9995 9.3015C31.9995 5.62646 28.9905 2.63525 25.2973 2.63525Z"
      fill="#00CFEB"
    />
    <path
      d="M16.0071 19.7168C16.1268 19.0394 15.9165 18.1351 15.2565 17.477C14.6563 16.8801 14.0465 16.79 13.6663 17.1681C12.5954 18.2333 16.4585 21.099 14.3571 23.1891C13.367 24.1738 11.9159 24.2044 10.5959 23.3082L9.68509 24.2141L8.64489 23.1795L9.52493 22.3041C8.5543 21.1601 8.37474 20.0257 8.56401 19.1006L10.2853 19.1601C10.1558 20.0853 10.4454 20.9123 11.1152 21.5785C11.8156 22.2752 12.4854 22.3846 12.9464 21.926C14.1063 20.7723 10.2157 17.9356 12.2864 15.876C13.2376 14.9299 14.6871 14.9605 15.9683 15.8953L16.8581 15.0104L17.8982 16.045L16.9972 16.9412C17.6572 17.8568 17.8772 18.8721 17.7171 19.7281L16.0055 19.7184L16.0071 19.7168Z"
      fill="#D5F8FF"
    />
  </svg>
);

const ForwardRef = forwardRef(PriceTagIcon);
export default ForwardRef;
