import { SVGProps, Ref, forwardRef } from "react";

const ComponentFuelSystemIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Component Fuel System Icon"
    {...props}
  >
    <title id="Component Fuel System Icon">Component Fuel System Icon</title>
    <path
      fillRule="evenodd"
      d="M6 4h10v6.667h1.333c.369 0 .667.298.667.666V16h.667V9.333h-1.333V6h.577l-.577-1 .577-.333.77 1.333h1.32v3.333h-.667V16a.667.667 0 0 1-.667.667H18a.667.667 0 0 1-.667-.667v-4.667H16V18h2v2H4v-2h2V4Zm12.667 4h-.444v.666h.444V8ZM7.429 5.333h7.143v5.334H7.429V5.333Z"
      clipRule="evenodd"
    />
  </svg>
);

const ForwardRef = forwardRef(ComponentFuelSystemIcon);
export default ForwardRef;
