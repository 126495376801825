import { SVGProps, Ref, forwardRef } from "react";

const RemoteStartIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Remote Start Icon"
    {...props}
  >
    <path
      d="M17,12c0.3,0,0.5,0.1,0.7,0.3S18,12.7,18,13v9h-2v-8H8v8H6v-9c0-0.3,0.1-0.5,0.3-0.7C6.5,12.1,6.7,12,7,12H17z M12,16v2h-2
	v-2H12z M12,6c1.1,0,2.2,0.3,3.2,0.9c0.9,0.6,1.7,1.4,2.2,2.4l-1.8,0.9c-0.3-0.7-0.8-1.2-1.5-1.6S12.7,8,12,8s-1.5,0.2-2.1,0.6
	C9.3,9,8.8,9.5,8.4,10.2L6.6,9.3c0.5-1,1.3-1.8,2.2-2.4C9.8,6.3,10.9,6,12,6z M12,2c1.9,0,3.7,0.5,5.3,1.5c1.6,1,2.9,2.4,3.7,4
	l-1.8,0.9c-0.7-1.3-1.7-2.4-3-3.2C14.9,4.4,13.5,4,12,4c-1.5,0-2.9,0.4-4.2,1.2s-2.3,1.9-3,3.2L3.1,7.5c0.8-1.7,2.1-3.1,3.7-4
	C8.3,2.5,10.1,2,12,2z"
    />
  </svg>
);

const ForwardRef = forwardRef(RemoteStartIcon);
export default ForwardRef;
