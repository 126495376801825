import { SVGProps, Ref, forwardRef } from "react";

const WheelIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 34 34"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Wheel Icon"
    {...props}
  >
    <title id="Wheel Icon">Wheel Icon</title>
    <path
      fillRule="evenodd"
      d="M17 31.167c7.825 0 14.167-6.343 14.167-14.167S24.825 2.833 17.001 2.833C9.176 2.833 2.834 9.176 2.834 17s6.342 14.167 14.167 14.167Zm8.436-13.105h-4.32a4.25 4.25 0 0 1-1.137 1.97l2.159 3.74a8.501 8.501 0 0 0 3.297-5.71Zm-5.137 6.775-2.16-3.74a4.25 4.25 0 0 1-2.276 0l-2.16 3.74A8.5 8.5 0 0 0 17 25.5a8.494 8.494 0 0 0 3.298-.663Zm-8.435-1.064 2.159-3.74a4.251 4.251 0 0 1-1.14-1.97H8.567a8.5 8.5 0 0 0 3.298 5.71Zm13.572-7.837h-4.32a4.25 4.25 0 0 0-1.137-1.97l2.159-3.74a8.501 8.501 0 0 1 3.297 5.71Zm-5.137-6.774A8.5 8.5 0 0 0 17 8.5a8.493 8.493 0 0 0-3.298.663l2.16 3.74a4.251 4.251 0 0 1 2.275 0l2.16-3.742Zm-6.276 4.806-2.16-3.74a8.5 8.5 0 0 0-3.297 5.71h4.32a4.256 4.256 0 0 1 1.137-1.97Z"
      clipRule="evenodd"
    />
  </svg>
);

const ForwardRef = forwardRef(WheelIcon);
export default ForwardRef;
