import { SVGProps, Ref, forwardRef } from "react";

const KeyIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Key Icon"
    {...props}
  >
    <path
      d="M18.8,2.3h-6.5c-1.6,0-2.9,1.3-2.9,2.9v6.5c0,0,0,0.1,0,0.1L7,14.2V16H5.1c-0.4,0-0.7,0.3-0.7,0.7v1.5H3
	c-0.4,0-0.7,0.3-0.7,0.7V21c0,0.4,0.3,0.7,0.7,0.7h2.3c0.4,0,0.7-0.3,0.7-0.7v-1.5h1.5c0.4,0,0.7-0.3,0.7-0.7V17h1.8l2.4-2.4
	c0,0,0.1,0,0.1,0h6.5c1.6,0,2.9-1.3,2.9-2.9V5.2C21.7,3.6,20.4,2.3,18.8,2.3L18.8,2.3z M18.3,7C17.6,7,17,6.4,17,5.7
	s0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3C19.6,6.4,19,7,18.3,7z"
    />
  </svg>
);

const ForwardRef = forwardRef(KeyIcon);
export default ForwardRef;
