import { SVGProps, Ref, forwardRef } from "react";

const VolumeOnIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Volume On Icon"
    {...props}
  >
    <path d="M3 9v6h4l5 5V4L7 9H3Zm13.5 3A4.5 4.5 0 0 0 14 7.97v8.05c1.48-.73 2.5-2.25 2.5-4.02ZM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77 0-4.28-2.99-7.86-7-8.77Z" />
  </svg>
);

const ForwardRef = forwardRef(VolumeOnIcon);
export default ForwardRef;
