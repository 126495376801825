import { SVGProps, Ref, forwardRef } from "react";

const AppFacebookIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="App Facebook Icon"
    {...props}
  >
    <path d="M10.023 20 10 13H7v-3h3V8c0-2.7 1.671-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V10H17l-1 3h-2.72v7h-3.257Z" />
  </svg>
);

const ForwardRef = forwardRef(AppFacebookIcon);
export default ForwardRef;
