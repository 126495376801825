import { SVGProps, Ref, forwardRef } from "react";

const FormatAlignLeftIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Format Align Left Icon"
    {...props}
  >
    <path d="M15 15H3v2h12v-2Zm0-8H3v2h12V7ZM3 13h18v-2H3v2Zm0 8h18v-2H3v2ZM3 3v2h18V3H3Z" />
  </svg>
);

const ForwardRef = forwardRef(FormatAlignLeftIcon);
export default ForwardRef;
