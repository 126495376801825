import { SVGProps, Ref, forwardRef } from "react";

const VehicleGiftIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 110 66"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Vehicle Gift Icon"
    {...props}
  >
    <title id="Vehicle Gift Icon">Vehicle Gift Icon</title>
    <path d="M92.027 48.523c-4.867 0-8.652 3.772-8.652 8.623 0 4.581 4.056 8.354 8.652 8.354 4.867 0 8.652-3.773 8.652-8.623 0-4.582-3.785-8.355-8.652-8.355ZM20.11 65.5c4.778 0 8.651-3.86 8.651-8.623s-3.873-8.624-8.652-8.624c-4.778 0-8.652 3.86-8.652 8.624 0 4.762 3.874 8.623 8.652 8.623Z" />
    <path
      fillRule="evenodd"
      d="M63.91 28.798c-3.921.158-7.452.578-10.005 1.4-7.03 2.155-13.25 5.389-19.468 9.162-11.626 1.886-20.819 3.503-26.227 5.12-2.703.54-3.785 1.887-4.596 3.234-.811 1.617-1.352 2.695-1.622 3.503C.91 51.217.91 52.295.91 53.373c0 1.887 0 3.234.27 4.851.27 1.348 1.623 2.156 2.975 2.156h2.704l.126-.025c1.242-.246 1.496-.296 1.496-1.861v-1.887c0-6.198 5.137-11.049 11.356-11.049 6.218 0 11.356 4.85 11.356 11.05v2.425c-.147 1.457-.451 2.048-.272 2.325.152.236.653.246 1.894.37H63.91V41.785c-2.691.018-7.361.292-11.32.524-3.874.227-7.067.415-7.067.284 0-.27.27-1.078.54-1.887 0-1.617-3.244-1.617-4.866-1.617 3.244-2.425 8.922-4.85 13.248-6.467 2.85-.812 6.313-1.012 9.465-1.177v-2.648Zm8 32.93h6.6c.206-.03.397-.053.572-.074 1.428-.173 1.83-.221 1.59-2.622v-2.425c0-6.198 5.137-11.049 11.356-11.049s11.356 4.85 11.356 11.05v1.616c0 .348-.014.654-.026.92-.064 1.382-.078 1.688 2.189 1.236 1.352-.27 2.163-.54 2.704-.809.916-.684.862-3.494.824-5.485-.007-.36-.013-.693-.013-.982 0-.316-.371-.447-.679-.555-.217-.077-.403-.142-.403-.254-.135-1.482-.473-3.503-.811-5.524-.338-2.022-.676-4.043-.811-5.525 0-1.078-.541-2.425-2.433-3.773-2.163-1.347-8.652-5.12-11.086-6.198-.668-.222-.969-.398-1.224-.547-.365-.214-.636-.372-1.75-.53-4.3-.537-11.136-1.205-17.955-1.412v2.516c1.038.059 2.11.088 3.195.117 3.041.082 6.198.168 9.082.934 1.352.539 4.326 3.503 5.137 4.581 0 .096.27.46.607.915.618.833 1.46 1.97 1.286 2.32-.27.269-.54.538-1.082.538-1.945.084-3.97.195-6.024.307-4.08.223-8.276.453-12.2.495v20.218Z"
      clipRule="evenodd"
    />
    <path d="M64.91 27.864h6v34.459h-6z" />
    <rect width={10} height={5.067} x={62.91} y={22.797} rx={1.5} />
    <path d="M68.76 19.53c-3.021 2.639-.777 7.596 3.167 6.996l14.41-2.19c2.629-.399 4.158-3.227 3.059-5.656l-3.373-7.452c-1.1-2.429-4.212-3.099-6.226-1.34L68.76 19.53Z" />
    <path d="M67.119 19.53c3.021 2.639.777 7.596-3.167 6.996l-14.41-2.19c-2.63-.399-4.158-3.227-3.059-5.656l3.373-7.451c1.1-2.43 4.212-3.1 6.226-1.34l11.037 9.64Z" />
  </svg>
);

const ForwardRef = forwardRef(VehicleGiftIcon);
export default ForwardRef;
