import { SVGProps, Ref, forwardRef } from "react";

const AimIconIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Aim Icon"
    {...props}
  >
    <path d="M20.3 11.3c-.2-2-1-3.8-2.4-5.2-1.4-1.4-3.2-2.3-5.2-2.4V1.6h-1.4v2.1c-2 .2-3.8 1-5.2 2.4-1.4 1.4-2.3 3.2-2.4 5.2H1.6v1.4h2.1c.2 2 1 3.8 2.4 5.2 1.4 1.4 3.2 2.3 5.2 2.4v2.1h1.4v-2.1c2-.2 3.8-1 5.2-2.4 1.4-1.4 2.3-3.2 2.4-5.2h2.1v-1.4h-2.1zM12 19c-3.8 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.2 7-7 7z" />
    <circle cx={12} cy={12} r={3.8} />
  </svg>
);

const ForwardRef = forwardRef(AimIconIcon);
export default ForwardRef;
