import { SVGProps, Ref, forwardRef } from "react";

const LoadingIconNutIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 48 48"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Loading Icon Nut Icon"
    {...props}
  >
    <g clipPath="url(#clip0_6246_131)">
      <path
        d="M32.2907 0H15.7093C14.6525 0 13.6391 0.419789 12.8919 1.16702L1.16702 12.8919C0.419789 13.6391 0 14.6526 0 15.7093V32.2907C0 33.3474 0.419789 34.3609 1.16702 35.1081L12.8919 46.833C13.6391 47.5802 14.6526 48 15.7093 48H32.2907C33.3475 48 34.3609 47.5802 35.1081 46.833L46.833 35.1081C47.5802 34.3609 48 33.3475 48 32.2907V15.7093C48 14.6526 47.5802 13.6391 46.833 12.8919L35.1081 1.16702C34.3609 0.419789 33.3475 0 32.2907 0ZM24.2677 38.0991C16.2677 38.2474 9.75263 31.7323 9.90096 23.7323C10.0407 16.1928 16.1929 10.0407 23.7324 9.90091C31.7324 9.75259 38.2475 16.2677 38.0991 24.2677C37.9593 31.8072 31.8072 37.9593 24.2677 38.0991Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6246_131">
        <rect width="48" height="48" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(LoadingIconNutIcon);
export default ForwardRef;
