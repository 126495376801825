import { SVGProps, Ref, forwardRef } from "react";

const CheckmarkDoubleIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Checkmark Double Icon"
    {...props}
  >
    <path d="m18 7-1.41-1.41-6.34 6.34 1.41 1.41L18 7Zm4.24-1.41L11.66 16.17 7.48 12l-1.41 1.41L11.66 19l12-12-1.42-1.41ZM.41 13.41 6 19l1.41-1.41L1.83 12 .41 13.41Z" />
  </svg>
);

const ForwardRef = forwardRef(CheckmarkDoubleIcon);
export default ForwardRef;
