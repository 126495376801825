import { SVGProps, Ref, forwardRef } from "react";

const BrandsHyundaiIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 100 62"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Brands Hyundai Icon"
    {...props}
  >
    <path
      d="M50 11C27.6 11 9.5 20 9.5 31S27.6 51 50 51s40.5-9 40.5-20S72.4 11 50 11zm-3.8 4.4c4.6-1.6 18.4 0 23.7 2.6 1.7 1.6-7.6 6.9-18.7 9.2-15.9 3.2-8.4-9.1-5-11.8zM18.9 39.2c-3.5-2.5-10.3-9.6 1-17 6-3.5 9.3-4.3 13.5-5.1 2-.4 3.4.1 1.6 1.6-3.8 3.9-11.6 16.9-12.3 19.7-.6 1.8-1.7 2.3-3.8.8zm32.6 8.2c-6.7.7-21.6-1.5-21.8-3.4.3-4.6 23.6-12.3 27.8-8 1.8 3.3-1.7 11.2-6 11.4zm34-13.4c-1.7 3.9-7.5 8.3-19.6 11.3-1.9.4-2.5-.7-1.3-1.8 2-1.2 11.6-17.3 12.8-20.5.7-1.6 2.1-1 2.9-.4 3.9 2.5 6.9 6.6 5.2 11.4z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        fill: "#78819b",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(BrandsHyundaiIcon);
export default ForwardRef;
