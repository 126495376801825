import { SVGProps, Ref, forwardRef } from "react";

const LoadingIconStarIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 48 48"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Loading Icon Star Icon"
    {...props}
  >
    <g clipPath="url(#clip0_6246_133)">
      <path
        d="M47.9995 21.412L44.9397 11.9948L36.9615 14.5871L41.8923 7.80038L33.8815 1.98015L28.9506 8.767V0.378204H19.0488V8.76679L14.118 1.98015L6.10725 7.80038L11.0381 14.5871L3.05984 11.9948L0 21.412L7.97827 24.0043L0 26.5966L3.05984 36.0138L11.0381 33.4215L6.10725 40.2082L14.118 46.0285L19.0488 39.2418V47.6304H28.9506V39.2416L33.8815 46.0285L41.8923 40.2082L36.9615 33.4215L44.9397 36.0138L47.9995 26.5966L40.0213 24.0043L47.9995 21.412Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6246_133">
        <rect width="48" height="48" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(LoadingIconStarIcon);
export default ForwardRef;
